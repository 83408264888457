import { Platform } from 'react-native';
import colors from '../theme/Colors';
import Config from '../libraries/ReactNativeConfig';
import MIPS_CUSTOM_INJECTED_JAVASCRIPT from '../injected/MIPS';
import { isWebIOS } from '../utils/BooleanUtility';
import { PermissionsAndroid } from '../libraries/NativeLibraries';
import AppConfig from './AppConfig';
import { getScreenWidth } from '../utils/LayoutUtility';
import SIZE_CHART_CUSTOM_INJECTED_JAVASCRIPT from '../injected/SizeChart';

export const API_DOMAIN = Config.API_DOMAIN;
export const HOST_NAME = Config.URI_HOST;
export const LISTS_API_VERSION = 'v7';
export const WEB_URL = Config.WEB_URL;
export const WWW_WEB_URL = Config.WWW_WEB_URL;
export const HTTP_WWW_WEB_URL = Config.HTTP_WWW_WEB_URL;
export const SHORT_WEB_URL = Config.SHORT_WEB_URL;
export const HTTP_WEB_URL = Config.HTTP_WEB_URL;
export const ROUTINES_DISCOVERY_URL = `${API_DOMAIN}/routines/upcoming_routines`;
export const TYPE_FORM_URL = 'foxyapp.typeform.com';
export const APP_INTENT_SCHEME = Config.APP_INTENT_SCHEME;
export const LINK_DOMAIN_SHORT = Config.DYNAMIC_LINK_URL;
export const LINK_DOMAIN = `https://${Config.DYNAMIC_LINK_URL}`;
export const googleMapsApiKey = 'AIzaSyC7kQJGNf_JAwWxq_DEzfst0rVUUwP8zpY';
export const issuerServer = 'https://accounts.google.com';
export const IMAGES_S3_BUCKET = 'ekanek-foxy-assets';
export const CLOUDFRONT_URL = 'https://d1xkxb24r5qztq.cloudfront.net';
//TODO: need to move common cdn from foxy to ekanek
export const CDN_3 = 'https://cdn3.foxy.in';
export const EMAIL_ID = Config.CONTACT_MAIL;
export const SUBJECT = '';
export const googleClientId =
  '467636570447-dp6mn1b4qa3tanithpcsj520k31ltgtv.apps.googleusercontent.com';
export const WHATS_APP_NUMBER = Config.CONTACT_US_NUMBER;
export const BASE_API = 'v1';
export const BASE_API_V2 = 'v2';
export const PRIVACY_POLICY_URL = `${Config.WEB_URL}/privacy-policy`;
export const TERM_OF_USAGE_URL = `${Config.WEB_URL}/terms-of-use`;
export const VIDEO_DISTRIBUTION_URL = 'https://d1zep23pc3t5l4.cloudfront.net';
export const VIDEO_CDN_URL = 'https://cdn1.foxy.in';
export const SELFIE_ANALYSIS_DELAYED_TIME = 7000;
export const BUILD_NO = `${Platform.OS}1085`;
export const GEOLOCATION_KEY = 'AIzaSyAp6po3qx-bFG8im7wnl6dyIt0bVUOWKyo';
export const CLOUDFRONT_IDENTIFIER = 'cloudfront.net';
export const VIDEO_UPLOAD_FORM_URL = `https://docs.google.com/forms/d/e/1FAIpQLScNpwjjpfB5cuorBW7a51SRd3KSqX4p9mAeTurYQH3UrCA-Tg/viewform`;
export const FOXY_LIVE_PACKAGE_NAME = 'in.foxy.live';
export const DEFAULT_IMAGE_FORMAT = 'webp';
export const APP_ASSET_URL = `${CDN_3}/media/app_asset/image`;
export const FABLESTREET_APP_ASSET_URL =
  'https://fablestreet.cdn.ekanek.app/media/app_asset/image';
export const GENERIC_APP_ASSET_URL = `${CDN_3}/generic_files/app_asset/generic_file`;
export const EMPTY_LIST_URL_FOR_SHARE = `${WEB_URL}/lists/`;
export const SALON_TYPE_FORM = `https://foxyapp.typeform.com/to/Nemi38GA#user_id=`;
export const INVITE_CENTRE_DEEPLINK = `${WWW_WEB_URL}/invite-center?group_deal_id=`;
export const INVITE_CENTRE_LINK = `${WWW_WEB_URL}/invite-center`;
export const REFERRAL_DEEPLINK = `${WWW_WEB_URL}/invite-center?utm_source=referral&referral_id=`;
export const SALON_QR_SCAN_SHORT = 'foxy.in/salons';
export const ONE_LINK_REGISTRATION = `${WWW_WEB_URL}/pro/sign-up`;
export const CART_ITEMS_URL = `${WWW_WEB_URL}/cart_items`;
export const ADDRESS_DEEPLINK = `${WWW_WEB_URL}/address`;
export const PAYMENT_METHODS_URL = `${WWW_WEB_URL}/payment_methods?notification_action=replace`;
export const ORDER_PAGE_URL = `${WWW_WEB_URL}/orders`;
export const CLUB_FOXY_LANDING_INACTIVE_SLUG = `/api/${LISTS_API_VERSION}/lists/loyalty_plan_members_page`;
export const CLUB_FOXY_LANDING_ACTIVE_SLUG = `/api/${LISTS_API_VERSION}/lists/loyalty_plan_members_page`;
export const CLUB_FOXY_LANDING_ELIGIBLE_SLUG = `/api/${LISTS_API_VERSION}/lists/loyalty_plan_members_page`;

export const FOXY_EDGE_URL = `${Config.WEB_URL}/foxy_edge`;
export const LOYALITY_PLAN_URL = `${Config.WEB_URL}/loyalty_plans`;
export const HOME_PAGE_DEEPLINK = `${Config.WEB_URL}/feed`;
export const CART_NOTIFICATION_ADD_ADDRESS_URL = `${Config.WEB_URL}/address/?utm_source=notification&utm_medium=local&utm_campaign=cart_abandon`;
export const CART_NOTIFICATION_MAKE_PAYMENT_URL = `${Config.WEB_URL}/payment_methods/?utm_source=notification&utm_medium=local&utm_campaign=cart_abandon`;
export const CART_NOTIFICATION_PAYMENT_DONE_URL = `${Config.WEB_URL}/feed/?utm_source=notification&utm_medium=local&utm_campaign=cart_abandon`;
export const PLATFORM = Platform.OS;
export const PLATFORM_VERSION = Platform.Version;

export const URL = {
  SEND_OTP: `${API_DOMAIN}/api/v2/users/send_otp`,
  SEND_OTP_ON_CALL: `${API_DOMAIN}/api/v2/users/make_call`,
  SEND_OTP_ON_WHATSAPP: `${API_DOMAIN}/api/v2/users/send_otp`,
  VERIFY_OTP: `${API_DOMAIN}/api/v2/users/verify.json`,
  VERIFY_HANDLE: `${API_DOMAIN}/api/v2/users/check_handle_availability?handle=`,
  ADD_PROFILE_DETAILS: `${API_DOMAIN}/api/v2/users/profile`,
  GET_LOCATION: `${API_DOMAIN}/api/v2/users/get_location?`,
  AUTOCOMPLETE_LOCALITY: `${API_DOMAIN}/api/v1/locations?q=`,
  GET_INTERESTS: `${API_DOMAIN}/api/v1/interests.json`,
  POST_SELECTED_INTERESTS: `${API_DOMAIN}/api/v2/users/interests.json`,
  POST_VIDEO_VIEW: `${API_DOMAIN}/api/v2/video_views`,
  SEARCH_PRODUCT: `${API_DOMAIN}/api/v1/products/search.json?q=`,
  AUTO_SUGGEST: `${API_DOMAIN}/api/v4/autosuggest?q=`,
  SEARCH_HASHTAG: `${API_DOMAIN}/api/v1/hash_tags?q=`,
  SEARCH_ARTIST: `${API_DOMAIN}/api/v1/artists/search_by_handle?q=`,
  MEDIA_CATEGORY: `${API_DOMAIN}/api/v1/media_categories/for_posts`,
  PUBLISH_POST: `${API_DOMAIN}/api/v1/media`,
  MEDIA_UPLOAD_SUCCESSFUL: `${API_DOMAIN}/api/v1/media/`,
  GET_INSTAGRAM_PROFILE: `${API_DOMAIN}/api/v1/instagram_profiles`,
  PROFILE_PIC_UPLOAD_URL: `${API_DOMAIN}/api/v2/users/profile_image_upload_url`,
  PROFILE_IMAGE_UPLOAD_SUCCESSFUL: `${API_DOMAIN}/api/v2/users/profile_image_upload_successful`,
  ANALYSE_FACE: `${API_DOMAIN}/api/v2/users/analyze_face`,
  GET_FACE_LANDMARKS_BY_ID: `${API_DOMAIN}/api/v2/users/face_analysis_data`,
  ARTIST_TYPE: `${API_DOMAIN}/api/v1/artist_types`,
  BRANDS: `${API_DOMAIN}/api/v1/brands?page=`,
  GET_SERVICES: `${API_DOMAIN}/api/v1/services`,
  GOOGLE_GEOCODE_API:
    'https://maps.googleapis.com/maps/api/geocode/json?latlng=',
  SEARCH_PLACE_BY_TEXT:
    'https://maps.googleapis.com/maps/api/place/autocomplete/json?components=country:ind&input=',
  GET_SALON_NEARBY: `${API_DOMAIN}/api/v1/salons`,
  youtube:
    'https://www.googleapis.com/youtube/v3/channels?part=snippet,contentDetails,statistics,brandingSettings&mine=true',
  googleProfile: 'https://www.googleapis.com/plus/v1/people/me?access_token=',
  NEAR_BY_ACADEMIES: `${API_DOMAIN}/api/v1/academies/search?q=`,
  NEAR_BY_STORE: `${API_DOMAIN}/api/v1/beauty_stores/search?q=`,
  SAVE_YOUTUBE_DATA: `${API_DOMAIN}/api/v1/google_profiles`,
  INSTAGRAM_PUBLIC_API: 'https://www.instagram.com/',
  UPLOAD_PHONE_BOOK: `${API_DOMAIN}/api/v2/contacts`,
  UPLOAD_PHONE_BOOK_V3: `${API_DOMAIN}/api/v3/contacts`,
  INSTAGRAM_PROFILE_CHECK: `${API_DOMAIN}/api/v1/instagram_profiles/check?handle=`,
  CAMPAIGN_URL: `${API_DOMAIN}/api/v2/campaigns?page=`,
  MY_CAMPAINGS_ONLY: `${API_DOMAIN}/api/v2/campaigns/mine`,
  MY_PROFILE: `${API_DOMAIN}/api/v2/users/my_profile`,
  GET_LATEST_MEDIA: `${API_DOMAIN}/api/v1/media/latest?page=`,
  GET_POPULAR_MEDIA: `${API_DOMAIN}/api/v1/media/popular?page=`,
  GET_SIMILAR_PRODUCT: `${API_DOMAIN}/api/v2/products/`,
  DELETE_MEDIA: `${API_DOMAIN}/api/v1/media/`,
  MY_REFERRALS: `${API_DOMAIN}/api/v2/users/my_referrals`,
  GET_USER_SUBMISSION_FOR_CAMPAIGN: `${API_DOMAIN}/api/v1/media/my_submission?campaign_id=`,
  SUBMIT_ARTIST_RESPONSE: `${API_DOMAIN}/api/v1/artists/`,
  SAVE_INSTAGRAM_FOLLOWERS_COUNT: `${API_DOMAIN}/api/v1/instagram_profiles/`,
  SAVE_YOUTUBE_SUBSCRIBERS_COUNT: `${API_DOMAIN}/api/v1/youtube_channels/`,
  YOUTUBE_ANALYZE: `${API_DOMAIN}/api/v1/youtube_channels/analyze`,
  UPDATE_POST: `${API_DOMAIN}/api/v1/media/`,
  REGISTER_CAMPAIGN: `${API_DOMAIN}/api/v1/campaign_registrations`,
  CANCEL_CAMPAIGN: `${API_DOMAIN}/api/v1/campaign_registrations/cancel_registration`,
  INSTAGRAM_VERIFICATION_POST: `${API_DOMAIN}/api/v2/users/insta_verification_post`,
  ARTIST_CONTENT: `${API_DOMAIN}/api/${LISTS_API_VERSION}/lists/`,
  BULK_CART: `${API_DOMAIN}/api/v3/cart_items/bulk_create`,
  CART_COUPON: `${API_DOMAIN}/api/v1/cart_coupons`,
  ADD_FREE_ITEM: `${API_DOMAIN}/api/v3/cart_items/add_free_item`,
  SAVE_ARTIST_TYPE: `${API_DOMAIN}/api/v1/artists`,
  YOUTUBE_ANALYTICS:
    'https://content-youtubeanalytics.googleapis.com/v2/reports?dimensions=day&sort=day&metrics=views,subscribersGained,subscribersLost&ids=channel%3D%3DMINE&',

  GET_ARTIST_GIFT: `${API_DOMAIN}/api/v1/artists/my_gift`,
  SAVE_ARTIST_OFFERINGS: `${API_DOMAIN}/api/v1/offerings/`,
  SAVE_ARTIST_TRAININGS: `${API_DOMAIN}/api/v1/trainings/`,
  REQUEST_BOOST: `${API_DOMAIN}/api/v1/youtube_channels/request_boost`,
  REQUEST_EXPERT_REVIEW: `${API_DOMAIN}/api/v1/youtube_channels/request_expert_review`,
  YOUTUBE_RECOMMENDATIONS: `${API_DOMAIN}/api/v1/youtube_recommendations`,
  SAVE_SALON: `${API_DOMAIN}/api/v1/artists`,
  SAVE_FREELANCER_PROFILE: `${API_DOMAIN}/api/v1/freelancer_profiles`,
  SAVE_BEAUTY_ADVISORY_PROFILE: `${API_DOMAIN}/api/v1/beauty_advisor_profiles`,
  SAVE_TRAINER_PROFILE: `${API_DOMAIN}/api/v1/trainer_profiles`,
  SAVE_STUDENT_PROFILE: `${API_DOMAIN}/api/v1/student_profiles`,
  VIDEOS_IN_REVIEW: `${API_DOMAIN}/api/v2/media/in_review`,
  INSTA_HANDLE_HINT: `${API_DOMAIN}/api/v1/instagram_profiles/probable`,
  COLLECT_CASH: `${API_DOMAIN}/api/v1/campaign_registrations/redeem_cash`,
  INSTAGRAM_PROFILE: `${API_DOMAIN}/api/v1/artists/instagram_profile`,
  STATUS_MESSAGE: `${API_DOMAIN}/api/v1/campaigns/status_messages`,
  UNLINK_GOOGLE_PROFILE: `${API_DOMAIN}/api/v1/google_profiles/my`,
  INVITE_CODE: `${API_DOMAIN}/api/v2/users/my_referrer`,
  PRODUCT: `${API_DOMAIN}/api/v1/products/`,
  PRODUCT_V2: `${API_DOMAIN}/api/v2/products/`,
  USER_ATTRIBUTES: `${API_DOMAIN}/api/v1/user_attributes`,
  REQUEST_SKU: `${API_DOMAIN}/api/v1/skus/`,
  PIN_CODE: `${API_DOMAIN}/api/v1/my_pincode`,
  DELIVERY_TIME: `${API_DOMAIN}/api/v2/tats/`,
  CARD_DETAILS: `${API_DOMAIN}/api/v1/orders/card_details`,
  SHIPMENTS: `${API_DOMAIN}/api/v1/shipments`,
  UPDATE_USER_ATTRIBUTES: `${API_DOMAIN}/api/v1/user_attribute_values`,
  CART_PARAMS: `${API_DOMAIN}/api/v1/orders/card_details`,
  ARTIST_CTA: `${API_DOMAIN}/api/v1/call_to_action_options`,
  MY_COLLECTION: `${API_DOMAIN}/api/v2/my/kit`,
  ALL_ARTIST_VIDEOS: `${API_DOMAIN}/api/v1/media?artist_id=`,
  CREATE_BOOKING: `${API_DOMAIN}/api/${BASE_API}/bookings`,
  GET_EMOJI: `${API_DOMAIN}/api/${BASE_API}/emojis`,
  MY_ATTRIBUTE_VALUES: `${API_DOMAIN}/api/v2/users/my_attribute_values`,
  PRODUCT_SERVICEABILITY: `${API_DOMAIN}/api/${BASE_API}/pincodes/`,
  OFFERS: `${API_DOMAIN}/api/v3/offers`,
  FREE_OFFER_ITEM: `${API_DOMAIN}/api/v3/cart_items/free_item`,
  DEVICES: `${API_DOMAIN}/api/v2/devices`,
  PINCODE_INFO: `${API_DOMAIN}/api/v1/pincodes/`,
  UPI_DATA: `${API_DOMAIN}/api/v1/upi_accounts`,
  NETBANKING_DETAILS: `${API_DOMAIN}/api/v1/netbanking_details`,
  CANCEL_ORDER: `${API_DOMAIN}/api/v1/orders/cancel_order`,
  UPLOAD_BASE_64_IMAGE: `${API_DOMAIN}/api/v2/users/upload_selfie_image`, //TODO:change name
  UPLOAD_PICTURE: `${API_DOMAIN}/api/v1/pictures`,
  OFFER_PROMPT: `${API_DOMAIN}/api/v1/`,
  TRUECALLER: `${API_DOMAIN}/api/v2/users/verify_truecaller`,
  GEOLOCATION_URL: `https://www.googleapis.com/geolocation/v1/geolocate?key=`,
  REVERSE_GEOLOCATION_URL: `https://maps.googleapis.com/maps/api/geocode/json?latlng=`,
  UPLOAD_SCANNED_PRODUCT_IMAGE: `${API_DOMAIN}/api/v1/product_pictures`,
  USER_ATTRIBUTE_LATEST_VIDEOS: `${API_DOMAIN}/api/v1/user_attribute_allowed_values/`,
  ATTRIBUTE_VIDEO_WATCHED: `${API_DOMAIN}/api/v1/media/`,
  STORY_URL: `${WEB_URL}/stories/player?stories=`,
  TUS_VIDEO_UPLOAD: `${API_DOMAIN}/files`,
  WEBVIEW_ORDER_LIST: `${WWW_WEB_URL}/orders`,
  RECYCLE_WITH_FOXY: `${WWW_WEB_URL}/recycle_with_foxy`,
  VALIDATE_UPI: `${API_DOMAIN}/api/v1/upi_accounts/validate_upi`,
  DELETE_CARD: `${API_DOMAIN}/api/v1/card_details`,
  GET_REWARDS: `${API_DOMAIN}/api/v1/offers/`,
  STORY_RECOMMENDATIONS: `${API_DOMAIN}/api/v1/followings/recommend`,
  FOLLOW_RECOMMENDATIONS: `${API_DOMAIN}/api/v1/followings/`,
  ARTIST_STORY: `${API_DOMAIN}/api/v1/stories?story_rail[id]=`,
  STORY_VIEW: `${API_DOMAIN}/api/v1/combined_story_views`,
  SET_DEFAULT_ADDRESS: `${API_DOMAIN}/api/v1/addresses/`,
  SET_DEFAULT_SHOPIFY_ADDRESS: `${API_DOMAIN}/api/v1/shopify_addresses/`,
  OPT_FOR_PLASTIC_FREE_PACKAGING: `${API_DOMAIN}/api/v1/carts/set_plastic_free_packaging_charges`,
  UPDATE_USER_DATA: `${API_DOMAIN}/api/v2/users/update_infomation`,
  FETCH_TAG_DATA: `${API_DOMAIN}/api/v2/tags`,
  SEND_PAYTM_OTP: `${API_DOMAIN}/api/v1/paytm_accounts/send_otp`,
  VERIFY_PAYTM_OTP: `${API_DOMAIN}/api/v1/paytm_accounts/validate_otp`,
  CHECK_IF_PAYTM_ACCOUNT_EXIST: `${API_DOMAIN}/api/v1/paytm_accounts/account_exist`,
  ADD_PAYTM_MONEY: `${API_DOMAIN}/api/v1/paytm_accounts/add_money_hash?channel=WAP`,
  CHECK_BALANCE: `${API_DOMAIN}/api/v1/paytm_accounts/balance_check`,
  CHECK_UPI_TRANSACTION_STATUS: `${API_DOMAIN}/api/v1/orders/check_upi_transaction_status`,
  UNLINK_PAYTM: `${API_DOMAIN}/api/v1/paytm_accounts/revoke_access_token`,
  PRODUCT_PROMPTS: `${API_DOMAIN}/api/v1/products/`,
  OFFER_PROMPTS: `${API_DOMAIN}/api/v1/offers/`,
  CART_PROMPTS: `${API_DOMAIN}/api/v1/carts/prompts`,
  OFFERS_PROMPTS: `${API_DOMAIN}/api/v1/offers/all_prompts`,
  GET_CART_PRICING: `${API_DOMAIN}/api/v1/carts/pricing`,
  OFFER_DETAIL: `${API_DOMAIN}`,
  LIKE: `${API_DOMAIN}/api/v1/likes`,
  UNLIKE: `${API_DOMAIN}/api/v1/likes/mine`,
  RATE: `${API_DOMAIN}/api/v1/ratings`,
  GET_PAYMENT_STATUS: `${API_DOMAIN}/api/v2/payments`,
  PRICE_ALERT: `${API_DOMAIN}/api/v1/price_alerts`,
  REVIEWS: Config.REVIEWS_TAB_SLUG
    ? `${API_DOMAIN}${Config.REVIEWS_TAB_SLUG}?page=`
    : `${API_DOMAIN}/api/${LISTS_API_VERSION}/lists/reviews?page=`,
  PAYOFF: `${API_DOMAIN}`,
  ALT_BRANDS: `${API_DOMAIN}`,
  FOXY_INSTAGRAM_PROFILE: 'https://www.instagram.com/foxy.famous/',
  FOLLOW_BRAND: `${API_DOMAIN}/api/v2/brands/id/follow.json`,
  UNFOLLOW_BRAND: `${API_DOMAIN}/api/v2/brands/id/unfollow.json`,
  GET_WARHOL_IMAGES: `${API_DOMAIN}/api/v2/users/my_andy_warhol_images`,
  DELETE_USER: `${API_DOMAIN}/api/v2/users/`,
  DELETE_SELFIE: `${API_DOMAIN}/api/v2/users/destroy_selfie`,
  UNLINK_DEVICE: `${API_DOMAIN}/api/v2/devices/unlink`,
  TODAY_DEALS: `${API_DOMAIN}/api/v1/flash-deals`,
  MY_LISTS: `${API_DOMAIN}/api/v2/my`,
  REGISTER_GUEST: `${API_DOMAIN}/api/v2/users/register_guest`,
  PRODUCT_FOR_REVIEW_URL: `${API_DOMAIN}/api/v1/skus/for_review`,
  GROUP_DEALS: `${API_DOMAIN}/api/v1/group_deals/`,
  GROUP_DEALS_V2: `${API_DOMAIN}/api/v2/group_deals/`,
  REQUEST_INVITE_V1: `${API_DOMAIN}/api/v1/invitation_requests`,
  GET_INVITATIONS_V1: `${API_DOMAIN}/api/v1/invitations?purpose=LoyaltyPlan`,
  INVITATIONS: `${API_DOMAIN}/api/v1/invitations?purpose=`,
  INVITATIONS_BULK_CREATE: `${API_DOMAIN}/api/v1/invitations/bulk_create`,
  INVITE_V1: `${API_DOMAIN}/api/v1/invitations`,
  ACCEPT_GROUP_DEALS: `${API_DOMAIN}/api/v1/group_deal_invitations/`,
  BUILD_LIST: `${API_DOMAIN}/api/${LISTS_API_VERSION}/lists/build`,
  APPLY_FLASH_DEAL: `${API_DOMAIN}/api/v1/flash-deals`,
  GOOGLE_PLACES_AUTOCOMPLETE: `${API_DOMAIN}/api/v1/places/autocomplete/?input=`,
  GOOGLE_PLACES_DETAILS: `${API_DOMAIN}/api/v1/places/details/?place_id=`,
  SETU_PAYMENTS: `${API_DOMAIN}/api/v1/setu_payments`,
  INFLUENCER_DETAILS: `${API_DOMAIN}/api/v3/profiles/handle/bio_page`,
  FOLLOW_INFLUENCER_SLUG: `/api/v1/artists/id/follow.json`,
  UNFOLLOW_INFLUENCER_SLUG: `/api/v1/artists/id/unfollow.json`,
  BOOSTED_OFFERS: `${API_DOMAIN}/api/v7/offers/boosted_sku_offers`,
  UPDATE_APP_RATING: `${API_DOMAIN}/api/v1/app_ratings`,
  ADD_DELIVERY_INSTRUCTION: `${API_DOMAIN}/api/v1/delivery_instructions`,
  HELD_ORDER: `${API_DOMAIN}/api/v1/orders/hold`,
  ROUTINE_URL: `${API_DOMAIN}/api/v2/routines`,
  POINTS: `${API_DOMAIN}/api/v1/point_logs?page=`,
  POINT_USAGE: `${API_DOMAIN}/api/v1/point_usages/`,
  ROUTINE_LIST: `${API_DOMAIN}/api/v1/routines`,
  SEARCH_RESULT_CLICKS: `${API_DOMAIN}/api/v1/search_result_clicks`,
  REMOVE_ALL_USNS: `${API_DOMAIN}/api/v3/cart_items/remove_usns`,
  ADD_CARRY_BAG_CHARGES: `${API_DOMAIN}/api/v1/carts/set_carry_bag_amount`,
  RETURN_PRIME_URL: `https://admin.returnprime.com/external/fetch-order`,
  EDGE_LISTS: `${API_DOMAIN}/api/${LISTS_API_VERSION}/lists`,
  LOYALTY_PLANS: `${API_DOMAIN}/api/v1/loyalty_plans`,
  TESTIMONIALS: `${API_DOMAIN}/api/v1/ratings/edge_ratings`,
  INVITE_CODE_VALIDATION: `${API_DOMAIN}/api/v1/invite_codes/validate`,
  INVITE_CODE_CLAIM: `${API_DOMAIN}/api/v1/invite_codes/claim`,
  POLL_LIST: `${API_DOMAIN}/api/v1/polls`,
  CREATE_ROUTINE_PARTICIPATION: `${API_DOMAIN}/api/v1/routine_participations`,
  RETURN_REQUEST: `${API_DOMAIN}/api/v1/return_requests`,
  RECENTLY_VIEWED: `${API_DOMAIN}/api/v1/dynamic_lists/recently_viewed?`,
  USER_ATTRIBUTE_VALUES: `${API_DOMAIN}/api/v2/user_attribute_values`,
  SEND_VERIFICATION_EMAIL: `${API_DOMAIN}/api/v2/profiles/send_verification_email`,
  VERIFY_EMAIL: `${API_DOMAIN}/api/v2/profiles/verify_user?`,
  CHECK_SHOPIFY_ADDRESS_BY_PHONE: `${API_DOMAIN}/api/v2/addresses/exists?phone=`,
  LOCATION: `${API_DOMAIN}/api/v1/ip_location`,
  REMOTE_ORDERS_SYNC: `${API_DOMAIN}/api/v1/${Config.ORDER_PROVIDER}_orders/sync_user_orders`,
  ORDER_RETURN_REQUEST: `${API_DOMAIN}/api/v2/return_requests`,
  CHECK_HANDLE_AVAILABILITY: `${API_DOMAIN}/api/v2/users/check_handle_availability`,
  UPDATE_INFLUENCER: `${API_DOMAIN}/api/v2/users/update_influencer`,
  ADD_PROLINK: `${API_DOMAIN}/api/v1/pro_links`,
  CREATE_INTRO_VIDEO: `${API_DOMAIN}/api/v1/foxy_videos/create_intro_video`,
  INSTA_PRO_VERIFICATION_STATUS: `${API_DOMAIN}/api/v1/pro_accounts/instagram_pro_account_verified_status`,
  INFLUENCER_TAGS: `${API_DOMAIN}/api/v1/influencer_tags`,
  PRO_ACCOUNT_LINKS: `${API_DOMAIN}/api/v1/pro_accounts/links`,
  VERIFY_ONELINK: `${API_DOMAIN}/api/v1/pro_accounts/verify_onelink`,
  TOP_BRANDS: `${API_DOMAIN}/api/v1/brands/top-brands`,
  GET_DERMAT_PROFILE: `${API_DOMAIN}/api/v2/dermat_profiles/my_profile`,
  DERMAT_PROFILE: `${API_DOMAIN}/api/v2/dermat_profiles`,
  DERMAT_TESTIMONIAL: `${API_DOMAIN}/api/v2/dermat_testimonials`,
  GEOCODE_ADDRESS: `${API_DOMAIN}/api/v1/maps/geocode/address`,
  SHOPIFY_DETAILS_PAGE_LISTS: `${API_DOMAIN}/api/v7/lists/product-lower-collection-lists.json`,
  SHOPIFY_PRODUCT_REVIEWS: `${API_DOMAIN}/api/v2/products/$HANDLE/reviews?page=0`,
};

export const PAYMENT_METHODS = {
  cod: 'Cash On Delivery',
};

export const PAYMENT_TYPES = {
  Cards: 'dc',
  NetBanking: 'nb',
  Cash: 'cod',
  GPay: 'gpay',
  UPI: 'upi',
};

export const REQUEST_INVITE_MODAL = {
  screens: {
    REQEUEST_INVITE_VIEW: 'REQEUEST_INVITE_VIEW',
    INVITE_CODE_INFO_VIEW: 'INVITE_CODE_INFO_VIEW',
    ENTER_COUPON_CODE_VIEW: 'ENTER_COUPON_CODE_VIEW',
  },
};

export const ONBOARDING_STATUS = {
  OnProfileDetails: 'OnProfileDetails',
  OnInterestPage: 'OnInterestPage',
  Completed: 'Completed',
  OnLogout: 'OnLogout',
};

export const HOME_PAGE_SLUG =
  Config.HOME_TAB_SLUG || `/api/${LISTS_API_VERSION}/lists/home-page.json`;
export const HOME_PAGE_URL = `${API_DOMAIN}${HOME_PAGE_SLUG}`;
export const STORE_PAGE_SLUG =
  Config.STORE_TAB_SLUG || `/api/${LISTS_API_VERSION}/lists/ingredients.json`;
export const STORE_URL = `${API_DOMAIN}${STORE_PAGE_SLUG}`;
export const CATEGORY_BROWSING_TAB_SLUG =
  Config.CATEGORY_BROWSING_TAB_SLUG ||
  `/api/${LISTS_API_VERSION}/lists/categories-page`;
export const ROUTINES_TAB_SLUG =
  Config.ROUTINES_TAB_SLUG || `/api/${LISTS_API_VERSION}/lists/routines`;
export const CATEGORY_BROWSING_TAB_URL = `${API_DOMAIN}${CATEGORY_BROWSING_TAB_SLUG}`;
export const SEARCH_URL = `${API_DOMAIN}/api/${LISTS_API_VERSION}/search.json`;
export const SEARCH_AUTOCOMPLETE = `${API_DOMAIN}/api/v0/autocomplete`;
export const SEARCH_AUTOSUGGEST = `${API_DOMAIN}/api/v8/autosuggest`;
export const EMPTY_SEARCH_URL = `${API_DOMAIN}/api/${LISTS_API_VERSION}/empty-search`;
export const PRODUCT_DETAIL_URL = `${API_DOMAIN}`;
export const ARTIST_STORY = `${API_DOMAIN}/api/${LISTS_API_VERSION}/lists/story-rail?`;
export const CART_URL = `${API_DOMAIN}/api/v3/cart_items`;
export const CART_URL_NEW = `${API_DOMAIN}/api/v4/cart_items/increment`;
export const ADDRESS_URL = `${API_DOMAIN}/api/v1/addresses`;
export const SHOPIFY_ADDRESS_URL = `${API_DOMAIN}/api/v1/shopify_addresses`;
export const V2_ADDRESS_URL = `${API_DOMAIN}/api/v2/addresses`;
export const ORDER_URL = `${API_DOMAIN}/api/v1/orders`;
export const ORDER_ITEM_URL = `${API_DOMAIN}/api/v1/order_items`;
export const SHIPMENT_URL = `${API_DOMAIN}/api/v1/shipments`;
export const CART_PRICING_URL = `${API_DOMAIN}/api/v3/cart_items/pricing`;
export const PRODUCT_NAME_LIMIT = 50;
export const CURRENCY_SYMBOL = Config.CURRENCY_SYMBOL || '\u20B9';
export const COUNTRY_CODE = Config.COUNTRY_CODE || '+91';
export const VERIFIED_PURCHASE = 'VERIFIED PURCHASE';
export const INSTAGRAM_KEY = '64fe0a1670db46c6bc892036b454117a';
export const INSTAGRAM_REDIRECT_URL = 'https://api.foxy.in/instagram_redirect';
export const REMOTE_CONFIG_DATA_REFRESH_AFTER = 1;
export const EMPTY_CART_SLUG = `/api/${LISTS_API_VERSION}/lists/empty-cart-products.json`;
export const WISHLIST_DEALS_SLUG = `/api/${LISTS_API_VERSION}/lists/wishlist-deals`;
export const MY_LIKED_PRODUCTS = '/api/v2/my/liked-products';
export const EMPTY_CART_LIST_SLUG = `/api/${LISTS_API_VERSION}/lists/empty-cart-products.json`;
export const SEARCH_TAG_SUPPORT = '/api/v4/tags';
export const OFFERS_URL = '/api/v3/offers';
export const FAQ_SECTION_URL = `${API_DOMAIN}/api/v1/faq_sections`;
export const FAQ_LIKE_AND_DISLIKE_URL = `${API_DOMAIN}/api/v1/faqs`;
export const POST_ORDER_REQUEST_DETAIL = `${API_DOMAIN}/api/v1/callback_requests`;
export const CONTACT_US_REASON = `${API_DOMAIN}/api/v1/contact_us_questions`;
export const RETURN_REPLACE_REASON = `${API_DOMAIN}/api/v1/return_reasons`;
export const CANCEL_REASON = `${API_DOMAIN}/api/v1/cancellation_reasons`;
export const SHIPMENT_TRACKING_DETAILS = `${API_DOMAIN}/api/v1/shipments`;
export const GET_ACTIVE_CALLBACK_DETAILS = `${API_DOMAIN}/api/v1/callback_requests`;
export const SHOPIFY_ORDER_URL = `${API_DOMAIN}/api/v1/${Config.ORDER_PROVIDER}_orders`;
export const SHOPIFY_ORDER_DETAILS_URL = `${API_DOMAIN}/api/v1/${Config.ORDER_PROVIDER}_orders/`;
export const COLLECTIONS_SLUG = '/collections/';
export const SHOPIFY_COLLECTIONS_SLUG = '/shopify_collections/';
export const FEATURE_FLAGS = {
  // TODO: Use this flag to skip onboarding
  SKIP_ONBOARD: false,
};

export const LAYOUT = {
  RAIL: 'rail',
  GRIDRAIL: 'gridRail',
  LIST: 'list',
  GRID: 'grid',
  CARD: 'card',
  SCREEN: 'screen',
  CART: 'cart',
  PAGER: 'pager',
  VERTICALRAIL: 'verticalRail',
  PERSONALISEDRAIL: 'personalisedRail',
  STORYRAIL: 'storyRail',
  REVIEWGRID: 'reviewGrid',
  VERTICAL: 'vertical',
  HORIZONTAL_PLAYLIST: 'horizontalPlaylist',
  VERTICAL_PLAYLIST: 'verticalPlaylist',
  NAVIGATION_HEADER: 'navigationHeader',
  BANNER_WITH_RAIL: 'bannerWithRail',
  TIMER_BANNER_WITH_RAIL: 'timerBannerWithRail',
  MATCHED_PRODUCT: 'matchedProduct',
  PERSONALIZED_HORIZONTAL: 'personalizedHorizontal',
  HORIZONTALRAIL: 'horizontalRail',
  LARGERAIL: 'largerail',
  LARGEGRID: 'largegrid',
  FANCY_RAIL: 'fancy_rail',
  SLIMRAIL: 'slimRail',
  SLIM_GRID: 'slimGrid',
  FEATURED_RAIL: 'featuredRail',
  DYNAMIC_CARD: 'dynamicCard',
};

export const CONTENT = {
  AD: 'ad',
};
export const ORIENTATION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
  LANDSCAPE: 'LANDSCAPE',
  PORTRAITUPSIDEDOWN: 'PORTRAITUPSIDEDOWN',
  PORTRAIT: 'PORTRAIT',
};

export const OFFER_APPLIED_BY = {
  USER_APPLIED: 'user_applied',
};

export const STORY_RAIL = {
  id: 'list-story-rail',
  slug: `/api/${LISTS_API_VERSION}/lists/story-rail.json`,
};

export const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
  GREY: 'grey',
};

export const SIZE = {
  small: 'small',
  large: 'large',
  larger: 'larger',
  DYNAMIC: 'dynamic',
};

export const MAX_LINES = {
  single: 1,
  two: 2,
  three: 3,
  six: 6,
};

export const BANNER_TIMEOUT = 6;

export const SCREEN = {
  LOGIN_INFO: 'LoginInfo',
  OTP_VERIFICATION: 'OTPVerification',
  SELFIE_PROMPT: 'SelfiePrompt',
  TAKE_SELFIE: 'TakeSelfie',
  IMAGE_REVIEW: 'ImageReview',
  PROFILE_DETAILS: 'ProfileDetails',
  ATTRIBUTE_SELECTOR: 'AttributeSelector',
  SELECT_YOUR_INTEREST: 'SelectYourInterest',
  RETAIL_STORE: 'retailStore',
};

export const MORE = 'MorePage';

export const ContentPlatforms = {
  youtube: 'youtube',
  foxy: 'foxy',
};

export const ANDROID = {
  BUILD: {
    VERSIONS: {
      PIE: 28,
      OREO: 27,
      OREO_PRE: 26,
      NOUGAT: 25,
      MARSHMALLOW: 23,
      LOLLIPOP: 22,
      KITKAT: 19,
    },
  },
};

export const MORE_ITEMS = 'MoreItems';

export const DEFAULT_ANIMATION_TIME = 300;

export const MEDIA_DETAIL_INITIAL_OFFSET_X = 10.0;
export const MEDIA_DETAIL_HEADER_BUTTON_WIDTH = 160.0;

export const FOCUS_MODE = {
  SELFIE: {
    height: 250,
    width: 250,
    tapLocationX: 0.1,
    tapLocationY: 0.1,
  },
  PRODUCT_BURST: {
    height: 100,
    width: 100,
    tapLocationX: 0.1,
    tapLocationY: 0.1,
  },
  VIDEO: {
    height: 100,
    width: 100,
    tapLocationX: 0.1,
    tapLocationY: 0.1,
  },
};

export const APP_CONSTANTS = {
  IOS: {
    APP_STORE_ID: '1476031752',
    BUNDLE_ID: 'in.foxy',
  },
  ANDROID: {
    PACKAGE_NAME: 'in.foxy',
  },
};

export const PERMISSION_STORE = {
  camera: {
    android: PermissionsAndroid.PERMISSIONS.CAMERA,
    ios: 'camera',
  },
  accounts: {
    android: PermissionsAndroid.PERMISSIONS.GET_ACCOUNTS,
    ios: null,
  },
  phone_state: {
    android: PermissionsAndroid.PERMISSIONS.READ_PHONE_STATE,
    ios: null,
  },
  location: {
    android: PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
    ios: 'location',
  },
  read_contact: {
    android: PermissionsAndroid.PERMISSIONS.READ_CONTACTS,
    ios: 'contacts',
  },
  read_storage: {
    android: PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
    ios: null,
  },
  write_storage: {
    android: PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
    ios: null,
  },
  record_audio: {
    android: PermissionsAndroid.PERMISSIONS.RECORD_AUDIO,
    ios: 'microphone',
  },
  photo: {
    android: PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
    ios: 'photo',
  },

  notification: {
    android: 'android.permission.POST_NOTIFICATIONS',
    ios: 'notification',
  },
};

export const CART_CONSTANT = {
  PAYMENT_OPTION: {
    'CREDIT/DEBIT': 'Credit Card/Debit Card',
    NETBANKING: 'Netbanking',
    SAVED_CARD: 'Saved Card',
    UPI: 'UPI',
  },
};

export const TABBAR_CONSTANT = {
  feed: 'Feed',
  store: 'Store',
  myCollection: 'My Kit',
  myProfile: 'Famous',
  category: 'Category',
  tag: 'Tag',
  scan: 'Scan',
  foxyClub: 'Foxy Club',
  genericTab: 'genericTab',
  profile: 'Profile',
  routines: 'Routines',
  listTab: 'ListTab',
};

export const GOOGLE_AUTH_CONFIG = {
  issuer: 'https://accounts.google.com',
  clientId: googleClientId,
  redirectUrl: 'in.foxy:/redirect',
  scopes: [
    'openid',
    'profile',
    'email',
    'https://www.googleapis.com/auth/youtube.readonly',
    'https://www.googleapis.com/auth/youtube',
    'https://www.googleapis.com/auth/youtube.upload',
    'https://www.googleapis.com/auth/yt-analytics.readonly',
    'https://www.googleapis.com/auth/youtube.force-ssl',
    'https://www.googleapis.com/auth/youtubepartner',
  ],
};

export const ARTIST_CONSTANTS = {
  artistContentSlug: `/api/${LISTS_API_VERSION}/lists/exclusive-for-artists.json`,
  artistList: `/api/${LISTS_API_VERSION}/lists/super-instagrammers.json`,
};

export const AVAILABLE_BUILD_TYPES = {
  foxy: 'foxy',
  artist_only: 'artist_only',
};

export const PREVIOUS_SCREEN_SOURCES = {
  artistOption: 'artistOptin',
  editProfessionalProfile: 'editProfessionalProfile',
  uncompletedOptin: 'uncompletedOptin',
};

export const OPTIN_PROCESS_STAGES = {
  INITIALLY_DISCARDED: 'INITIALLY_DISCARDED',
  COMPLETED: 'COMPLETED',
};

export const DYNAMIC_LINKS_CONFIG = {
  IOS_DOMAIN: LINK_DOMAIN,
  ANDROID_DOMAIN: LINK_DOMAIN,
  DEFAULT_IMAGE: `${Config.WEB_URL}/foxy.png`,
};

export const CURRENT_GUEST_STATE = {
  INITIAL: 'INITIAL',
  SELFIE_CLICKED: 'SELFIE_CLICKED',
  SELFIE_INTRO: 'SELFIE_INTRO',
  SELFIE_SKIPPED: 'SELFIE_SKIPPED',
  SKIPPED: 'SKIPPED',
  FULLY_REGISTERED: 'FULLY_REGISTERED',
};

export const SELFIE_STATE = {
  QUEUED: 'QUEUED',
  STARTING: 'STARTING',
  INITIATED: 'INITIATED',
  SELFIE_UPLOADED: 'SELFIE_UPLOADED',
  STARTED_ATTRIBUTES_MAPPING: 'STARTED_ATTRIBUTES_MAPPING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
  HAVE_BETAFACE_IMAGE_ID: 'HAVE_BETAFACE_IMAGE_ID',
  USER_ACCEPTED: 'USER_ACCEPTED',
};

export const APP_LOAD_SOURCE = {
  ORGANIC: 'organic',
  NOTIFICATION: 'notification',
  DYNAMIC_LINK: 'dynamic_link',
  DEEP_LINK: 'deep_link',
  PERSONALIZED_SHORTCUT: 'personalized_icon',
};

export const LOGIN_MODAL_STATE = {
  USER_SKIPPED: 'user_skipped',
};
export const REMOTE_CONFIG_KEYS = {
  show_offer_rail_in_cart: 'show_offer_rail_in_cart',
  request_invite_modal_image: 'request_invite_modal_image',
  flash_deal_activation_suffix: 'flash_deal_activation_suffix',
  flash_deal_suffix: 'flash_deal_suffix',
  product_detail_wallet_header: 'product_detail_wallet_header',
  hide_routine: 'hide_routine',
  min_allowed_ios_version_v2: 'min_allowed_ios_version_v2',
  min_allowed_android_version_v2: 'min_allowed_android_version_v2',
  whatsapp_orderDetails_invoice: 'whatsapp_orderDetails_invoice',
  whatsapp_orderDetail_cancel: 'whatsapp_orderDetail_cancel',
  chat_cancel_order: 'chat_cancel_order',
  whatsapp_orderDetails_needHelp: 'whatsapp_orderDetails_needHelp',
  product_returnPolicy: 'product_return_policy',
  whatsapp_brandCollab_talkToUs: 'whatsapp_brandCollab_talkToUs',
  whatsapp_myProfile_contactUs: 'whatsapp_myProfile_contactUs',
  whatsapp_artistDashboard_talkToUs: 'whatsapp_artistDashboard_talkToUs',
  available_payment_options: 'available_payment_options',
  product_authenticity: 'product_authenticity',
  whatsapp_order_details_return_order: 'whatsapp_order_details_return_order',
  artist_brand_collab_list_top_banner_url: 'artist_brand_collab_list_top_banner_url',
  foxy_contact_info: 'foxy_contact_info',
  selfie_camera_text: 'selfie_camera_text',
  login_button_text: 'login_button_text',
  login_header_text: 'login_header_text',
  input_mobile_number_label_text: 'input_mobile_number_label_text',
  privacy_policy_tag_line: 'privacy_policy_tag_line',
  foxy_tag_line: 'foxy_tag_line',

  foreground_skip_visible_time: 'foreground_skip_visible_time',
  onboarding_background_notification_time: 'onboarding_background_notification_time',
  phone_input_skip_countdown_timer: 'phone_input_skip_countdown_timer',
  uac_threshold: 'uac_threshold',
  uac_gender: 'uac_gender',
  uac_apps: 'uac_apps',
  uac_device: 'uac_device',
  uac_places: 'uac_places',
  video_card_subheading: 'video_card_subheading',
  video_card_heading: 'video_card_heading',
  video_card_image_url: 'video_card_image_url',
  ask_for_review_message: 'ask_for_review_message',
  brand_collab_details_connect_instagram_text: 'brand_collab_details_connect_instagram_text',
  brand_collab_details_connect_youtube_instagram_text: 'brand_collab_details_connect_youtube_instagram_text',
  brand_collab_add_address: 'brand_collab_add_address',
  brand_collab_request_submitted_text: 'brand_collab_request_submitted_text',
  brand_collab_connect_youtube_text: 'brand_collab_connect_youtube_text',
  text_modal_participate_in_collabs: 'text_modal_participate_in_collabs',
  covid_19_cod_message: 'covid_19_cod_message',
  contactless_checkbox_subheading: 'contactless_checkbox_subheading',
  contactless_checkbox_heading: 'contactless_checkbox_heading',
  allow_without_contactless: 'allow_without_contactless',
  show_contactless_checkbox: 'show_contactless_checkbox',
  switch_contact_less_modal_message: 'switch_contact_less_modal_message',
  is_cod_available: 'is_cod_available',
  ask_for_review_toast_message: 'ask_for_review_toast_message',
  outofstock_modal_message: 'outofstock_modal_message',
  discontinued_product_modal_message: 'discontinued_product_modal_message',
  unstocked_product_modal_message: 'unstocked_product_modal_message',
  lands_on_offers_page: 'lands_on_offers_page',

  show_artist_welcome_gift_banner: 'show_artist_welcome_gift_banner',

  out_of_stock_toast_message: 'out_of_stock_toast_message',
  special_offer_popover_text: 'special_offer_popover_text',
  non_dimensional_video: 'non_dimensional_video',
  show_square_product_card: 'show_square_product_card',
  ask_for_review_button_text: 'ask_for_review_button_text',
  UPI_APPS: 'upi_apps',
  upi_apps_ios: 'upi_apps_ios',
  PAYMENT_ENABLED: 'payment_enabled',
  PAYMENT_ENABLED_WEB: 'payment_enabled_web',
  plastic_free_checked_by_default: 'plastic_free_checked_by_default',
  plastic_free_packaging_charge: 'plastic_free_packaging_charge',
  show_plastic_free_packaging_checkbox: 'show_plastic_free_packaging_checkbox',
  go_green_heading: 'go_green_heading',
  go_green_subheading: 'go_green_subheading',
  go_green_subheading_selected: 'go_green_subheading_selected',
  uac_apps_commerce: 'uac_apps_commerce',
  uac_apps_beauty: 'uac_apps_beauty',
  uac_selfie: 'uac_selfie',
  uac_gender_threshold: 'uac_gender_threshold',
  uac_new_threshold: 'uac_new_threshold',
  active_offer: 'active_offer',
  android_notification_channels: 'android_notification_channels',
  new_uac_device: 'new_uac_device',
  better_selfie_text: 'better_selfie_text',
  uxcam_enabled: 'uxcam_enabled',
  default_ios_device_score: 'default_ios_device_score',
  default_ios_beauty_apps_score: 'default_ios_beauty_apps_score',
  default_ios_commerce_app_score: 'default_ios_commerce_app_score',
  plastic_free_checked_by_default: 'plastic_free_checked_by_default',
  show_plastic_free_packaging_checkbox: 'show_plastic_free_packaging_checkbox',
  go_green_heading: 'go_green_heading',
  go_green_subheading: 'go_green_subheading',
  UPI_APPS: 'upi_apps',
  recycle_with_foxy_message: 'recycle_with_foxy_message',
  prepaid_payment: 'prepaid_payment',
  hide_payment_options: 'hide_payment_options',
  hide_more_banks_nb: 'hide_more_banks_nb',
  ios_notification_request: 'ios_notification_request',
  notification_prompt_request: 'notification_prompt_request',
  notification_prompt_request_v2: 'notification_prompt_request_v2',
  foxy_promise: 'foxy_promise',
  foxy_promise_toast: 'foxy_promise_toast',
  foxy_promise_delivery: 'foxy_promise_delivery',
  community_guideline_cta: 'community_guideline_cta',
  community_guidelines_cta_url: 'community_guidelines_cta_url',
  community_guideline_heading: 'community_guideline_heading',
  delivery_info: 'delivery_info',
  app_landing: 'app_landing',
  local_notification: 'local_notification',
  user_attributes_column_display: 'user_attributes_column_display',
  number_of_attributes_to_select: 'number_of_attributes_to_select',
  new_payment_journey: 'new_payment_journey',
  cart_reminder_notification_data: 'cart_reminder_notification_data',
  customised_cart_reminder_notification_data: 'customised_cart_reminder_notification_data',
  pagination_objects_count: 'pagination_objects_count',
  product_page: 'product_page',
  show_set_alert: 'show_set_alert',
  selfie_heading_subheading: 'selfie_heading_subheading',
  api_time_out_in_ms: 'api_time_out_in_ms',
  maxVideoDownloadLimit: 'maxVideoDownloadLimit',
  story_notification_data: 'story_notification_data',
  android_max_cache_limit: 'android_max_cache_limit',
  selfie_attribute_last_button_text: 'selfie_attribute_last_button_text',
  share_app_text: 'share_app_text',
  report_personalised_product: 'report_personalised_product',
  selfie_deletion_reasons: 'selfie_deletion_reasons',
  delete_account_reasons: 'delete_account_reasons',
  whatsapp_deleteSelfie_contactUs: 'whatsapp_deleteSelfie_contactUs',
  delete_selfie_reason_not_selected_error_message: 'delete_selfie_reason_not_selected_error_message',
  delete_selfie_logout_toast_message: 'delete_selfie_logout_toast_message',
  connection_error: 'connection_error',
  checkout_new: 'checkout_new',
  redirect_text: 'redirect_text',
  wishlist_deals_feature_card: 'wishlist_deals_feature_card',
  foxy_promise_cart: 'foxy_promise_cart',
  daily_deals_notifications: 'daily_deals_notifications',
  disable_btl: 'disable_btl',
  versions_to_be_hard_updated_v2: 'versions_to_be_hard_updated_v2',
  review_reminder_notification_data: 'review_reminder_notification_data',
  show_cod_confirmation_modal: 'show_cod_confirmation_modal',
  versions_to_be_hard_updated_ios_v2: 'versions_to_be_hard_updated_ios_v2',
  btl_timer_text: 'btl_timer_text',
  upload_status_tray_strings: 'upload_status_tray_strings',
  product_review_page_strings: 'product_review_page_strings',
  cod_confirmation_modal_message: 'cod_confirmation_modal_message',
  review_modal_title: 'review_modal_title',
  hide_actions: 'hide_actions',
  today_deals_feature_card: 'today_deals_feature_card',
  group_deals_contacts_strings: 'group_deals_contacts_strings',
  invite_center_strings: 'invite_center_strings',
  update_shipment_status: 'update_shipment_status',
  last_wishlisted_item_time: 'last_wishlisted_item_time',
  wishlist_tooltip: 'wishlist_tooltip',
  like_gif: 'like_gif',
  checkout_wishlist_deals: 'checkout_wishlist_deals',
  wishlist_modal_data: 'wishlist_modal_data',
  pay_on_foxy_benefits: 'pay_on_foxy_benefits',
  salon_reward_modal: 'salon_reward_modal',
  influencer_page: 'influencer_page',
  enable_generic_green_text: 'enable_generic_green_text',
  mild_delay_offer_terms: 'mild_delay_offer_terms',
  callback_reasons: 'callback_reasons',
  attempt_fail_reasons: 'attempt_fail_reasons',
  notification_sounds: 'notification_sounds',
  rate_product_data: 'rate_product_data',
  missed_delight_offer_data: 'missed_delight_offer_data',
  fire_user_information_update: 'fire_user_information_update',
  routine_rate_experience: 'routine_rate_experience',
  club_member: 'club_member',
  enabled_google_adv_tracking: 'enabled_google_adv_tracking',
  login_modal_info: 'login_modal_info',
  carry_bag_options: 'carry_bag_options',
  about_us_urls: 'about_us_urls',
  order_page_contact_us_number: 'order_page_contact_us_number',
  contact_us_email: 'contact_us_email',
  loyalty_plan_benefits_images: 'loyalty_plan_benefits_images',
  join_pro_modal_image: 'join_pro_modal_image',
  edge_coupon_colors: 'edge_coupon_colors',
  edge_address_id_for_cart: 'edge_address_id_for_cart',
  routine_minimum_participation_count: 'routine_minimum_participation_count',
  disable_places_api: 'disable_places_api',
  deals_tag_images: 'deals_tag_images',
  become_member_modal: 'become_member_modal',
  friday_drop_notification: 'friday_drop_notification',
  discount_offset: 'discount_offset',
  upgrade_tray_edge: 'upgrade_tray_edge',
  cart_builder: 'cart_builder',
  edge_join_success: 'edge_join_success',
  edge_extension_card_position_in_cart: 'edge_extension_card_position_in_cart',
  edge_extension_card_text: 'edge_extension_card_text',
  edge_constants_for_cart_tray: 'edge_constants_for_cart_tray',
  whitelisted_events: 'whitelisted_events',
  product_accordian_initial_expanded_index: 'product_accordian_initial_expanded_index',
  delete_account_description: 'delete_account_description',
  onboarding_notification_desc: 'onboarding_notification_desc',
  tailor_fit_guide_video: 'tailor_fit_guide_video',
  product_deals_tag_data: 'product_deals_tag_data',
  cod_fee_threshold: 'cod_fee_threshold',
  cart_builder_payment_page_recommended_list: 'cart_builder_payment_page_recommended_list',
  combo_builder_prompts: 'combo_builder_prompts',
  show_email_input_on_past_orders: 'show_email_input_on_past_orders',
  selfie_card: 'selfie_card',
  verify_phone_disclaimer_on_address_page: 'verify_phone_disclaimer_on_address_page',
  product_page_empty_address_delivery_data: 'product_page_empty_address_delivery_data',
  contact_us_modal_data: 'contact_us_modal_data',
  loyalty_plan_tab_cta: 'loyalty_plan_tab_cta',
  show_flash_deal_claim_button: 'show_flash_deal_claim_button',
  edge_members_only_text: 'edge_members_only_text',
  advance_order_campaign_product_text: 'advance_order_campaign_product_text',
  advance_order_campaign_cart_text: 'advance_order_campaign_cart_text',
  storage_permission_modal_image: 'storage_permission_modal_image',
  green_text_candidates: 'green_text_candidates',
  cart_notification_texts: 'cart_notification_texts',
  notification_tray_content: 'notification_tray_content',
  notification_modal_assets: 'notification_modal_assets',
  cart_notification_texts_with_one_timer: 'cart_notification_texts_with_one_timer',
  show_contact_us_card: 'show_contact_us_card',
  announcements_constants_for_cart_tray: 'announcements_constants_for_cart_tray',
  invite_from_offers: 'invite_from_offers',
  last_order_date_time_frame: 'last_order_date_time_frame',
  install_date_time_frame: 'install_date_time_frame',
  premium_device_model_name: 'premium_device_model_name',
  premium_device_brand_name: 'premium_device_brand_name',
  premium_device_ram_threshold_limit: 'premium_device_ram_threshold_limit',
  fetch_location_timestamp_threshold_hours: 'fetch_location_timestamp_threshold_hours',
  webview_html_and_style: 'webview_html_and_style',
  authorized_invite_friends_modal_data: 'authorized_invite_friends_modal_data',
  invite_center_assets: 'invite_center_assets',
  custom_injected_javascript: 'custom_injected_javascript',
  routine_tray_item: 'routine_tray_item',
  a_plus_content_data: 'a_plus_content_data',
  shopify_sorting_options: 'shopify_sorting_options',
  dermat_degrees_data: 'dermat_degrees_data',
  ekstore_credit_footer: 'ekstore_credit_footer',
  email_address: 'email_address',
  return_request_data: 'return_request_data',
  size_chart: 'size_chart',
  dermat_onbaord_pages_Header_text: 'dermat_onbaord_pages_Header_text',
  dermat_invite_patients: 'dermat_invite_patients',
  dermat_save_progress: 'dermat_save_progress',
  location_modal: 'location_modal',
  share_handle: 'share_handle',
};

export const LOGIN_MODAL_MESSAGES = {
  DEFAULT: 'Login • Sign up',
  MY_KIT: 'Login to view your kit',
  ADD_TO_CART: 'Login to buy this product',
  LIKE_ARTIST: 'Login to like this artist',
  LIKE_VIDEO: 'Login to like this video',
  LIKE_COLLECTION: 'Login to like this collection',
  VIEW_PROFILE: 'Login to view your profile',
  VIEW_INVITES: 'Login to view your invite center',
  VIEW_CONTACTS: 'Login to invite your contacts',
  FOLLOW_ARTIST: 'Login to follow this artist',
  LOGIN_AFTER_ONE_DAY: 'Login to keep using the app',
  CHECKOUT: 'Login to checkout',
  RATE: 'Login to rate this product',
  PAY_WITH_FOXY: 'Login to pay with Foxy',
  FOXY_EDGE: 'Login to check eligibility',
  WISHLIST_VIEW: 'Login to see your wishlist',
  APPLY_COUPON_CODE: 'Login to apply the offer code',
};

export const COMPETITOR_APP = {
  'com.amazon.avod.thirdpartyclient': 101,
  'in.startv.hotstar': 102,
  'com.netflix.mediaclient': 103,
  'com.graymatrix.did': 104,
  'com.tv.v18.viola': 105,
  'com.sonyliv': 106,
  'com.bsbportal.music': 107,
  'com.amazon.mp3': 108,
  'com.spotify.music': 109,
  'com.bigbasket.mobileapp': 110,
  'com.tul.tatacliq': 111,
  'com.grofers.customerapp': 112,
  'com.zeptoconsumerapp': 113,
  'app.trell': 114,
  'com.meesho.supply': 115,
  'net.one97.paytm': 116,
  'com.phonepe.app': 117,
  'com.google.android.apps.nbu.paisa.user': 118,
  'com.csam.icici.bank.imobile': 119,
  'com.dreamplug.androidapp': 120,
  'in.org.npci.upiapp': 121,
  'com.instagram.android': 122,
  'com.truecaller': 123,
  'com.facebook.katana': 124,
  'com.urbanclap.urbanclap': 125,
  'in.amazon.mShop.android.shopping': 126,
  'com.flipkart.android': 127,
  'com.myntra.android': 128,
  'com.fsn.nykaa': 129,
  'com.ril.ajio': 130,
  'com.manash.purplle': 131,
  'com.app.smytten': 132,
  'com.fsn.nds': 133,
  'com.fsn.nykaa.man': 134,
  'com.magicpin.local': 135,
  'com.licious': 136,
  'com.freshtohome': 137,
  'app.mycountrydelight.in.countrydelight': 138,
  'com.lenskart.app': 139,
  'in.swiggy.android': 140,
  'com.application.zomato': 141,
  'com.mamaearthapp': 142,
  'com.app.sugarcosmetics': 143,
  'com.myglamm.ecommerce': 144,
  'com.ril.tira': 145,
  'com.tatacliq.palette': 146,
};

export const FACIAL_FEATURE_TAGS = [
  {
    title: 'Hair Type',
    type: 'hair_type',

    values: [],
    additionalValues: [],
  },
  {
    title: 'Hair Condition',
    type: 'hair_condition',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Hair Length',
    type: 'hair_length',
    values: [],

    additionalValues: [],
  },

  {
    title: 'Hair Colour',
    type: 'hair_colour',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Skin Tone',
    type: 'skin_tone',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Skin Type',
    type: 'skin_type',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Skin Undertone',
    type: 'skin_undertone',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Skin Condition',
    type: 'skin_condition',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Face Shape',
    type: 'face_shape',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Beard Style',
    type: 'beard_style',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Beard Length',
    type: 'beard_length',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Moustache Type',
    type: 'mustache_length',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Gender',
    type: 'gender',
    values: [],
    additionalValues: [],
  },
  {
    title: 'Age',
    type: 'age',
    values: [],
    additionalValues: [],
  },
];

export const BETAFACE_TAGS_MAPPING = {
  GENDER: { male: 'Male', female: 'Female' },
  MOUSTACHE_TYPE: { short: 'Thin' },
  BEARD_LENGTH: { thick: 'Full grown', Short: 'Stubble' },
  HAIR_COLOUR_MALE: {
    red: 'Coloured',
    'brown light': 'Coloured',
    'not natural light': 'Coloured',
    'not natural': 'Coloured',
    gray: 'Salt N Pepper',
  },
  HAIR_COLOUR_FEMALE: {
    red: 'Coloured',
    'brown light': 'Brown',
    'not natural light': 'Coloured',
    'not natural': 'Coloured',
  },

  HAIR_LENGTH_MALE: {
    none: 'Short',
    'very short': 'Short',
    short: 'Short',
    average: 'Medium',
    long: 'Medium',
    'very long': 'Long',
    bald: 'Shaved',
  },
  HAIR_LENGTH_FEMALE: {
    none: 'Short',
    'very short': 'Short',
    average: 'Shoulder to Mid',
    long: 'Long',
    'very long': 'Long',
  },
};

export const stepIndicatorConfig = {
  stepIndicatorSize: 12,
  currentStepIndicatorSize: 12,
  separatorStrokeWidth: 2,
  currentStepStrokeWidth: 2,
  stepStrokeCurrentColor: colors.green,
  stepStrokeWidth: 2,
  stepStrokeFinishedColor: colors.green,
  stepStrokeUnFinishedColor: '#aaaaaa',
  separatorFinishedColor: colors.green,
  separatorUnFinishedColor: '#aaaaaa',
  stepIndicatorFinishedColor: colors.green,
  stepIndicatorUnFinishedColor: '#aaaaaa',
  stepIndicatorCurrentColor: colors.green,
  stepIndicatorLabelFontSize: 12,
  currentStepIndicatorLabelFontSize: 12,
  stepIndicatorLabelCurrentColor: colors.green,
  stepIndicatorLabelFinishedColor: colors.green,
  stepIndicatorLabelUnFinishedColor: '#aaaaaa',
  labelColor: '#999999',
  labelSize: 10,
  labelfontFamily: 'Roboto-Regular',
  currentStepLabelColor: colors.green,
  finishedStepLabelColor: colors.green,
  unFinishedStepLabelColor: colors.green,
};

export const SELFIE_ANALYSIS_ERROR_CODE = {
  '001': 'initial error',
  '002': 's3 image upload error',
  '003': 'marking image successful in foxy',
  '004': 'while detecting facial features',
};

export const PERMISSIONS = {
  CAMERA: 'camera',
  RECORD_AUDIO: 'record_audio',
  READ_STORAGE: 'read_store',
  READ_PHONE_STATE: 'read_phone_state',
  LOCATION: 'location',
};

export const POPULAR_BANKS = [
  { bankCode: 'HDFB', title: 'HDFC' },
  { bankCode: 'SBIB', title: 'SBI' },
  { bankCode: 'ICIB', title: 'ICICI' },
  { bankCode: 'AXIB', title: 'AXIS' },
  { bankCode: '162B', title: 'KOTAK' },
];

export const AnalyticsScreenNames = {
  SELFIE_PROMPT: 'Selfie Prompt',
  SALON_SELECTION: 'Salon Selection',
  REFER_AND_EARN: 'Refer and Earn',
  MY_PROFILE: 'My Profile',
  MY_KIT: 'My Kit',
  FULL_SCREEN_VARIANT: 'Full Screen Variant',
  FULL_SCREEN_MODAL: 'Full screen modal',
  ARTIST: 'Artist',
  ARTIST_CARD: 'Artist Card',
  ARTIST_DETAIL: 'Artist Detail',
  ARTIST_STORY_CARD: 'Artist Story Card',
  BRAND: 'Brand',
  HOME_PAGE: 'Home Page',
  MORE_PAGE: 'More Page',
  PRODUCT_DETAIL: 'Product Detail',
  STORE: 'Store',
  OFFER_DETAIL: 'Offer Detail',
  NON_LOGGED_IN_GUEST_HOME_PAGE: 'Non Logged In Guest Home Page',
  CART: 'Cart',
  EMPTY_CART: 'Empty Cart',
  CONSUMER_PROFILE: 'Consumer Profile',
  SEARCH: 'SEARCH',
};

export const SELFIE_STATE_ON_DELAY = {
  IMAGE_UPLOAD_START: 'image_upload_start',
  IMAGE_UPLOAD_DONE: 'image_upload_done',
  WAITING_FOR_USER_ATTRIBUTES: 'waiting_for_user_attributes',
  STARTED_ATTRIBUTES_MAPPING: 'started_attributes_mapping',
  SELFIE_ANALYSIS_DONE: 'selfie_analysis_done',
};

export const DEFAULT_REMOTE_CONFIG_VALUES = {
  show_offer_rail_in_cart: 'false',
  request_invite_modal_image: 'https://mufoxy.cdn.ekanek.app/media/app_asset/image/21/request_invite_graphic.png',
  flash_deal_activation_suffix: 'for you today',
  flash_deal_suffix: 'for you today',
  product_detail_wallet_header: JSON.stringify({
    heading: 'My wallet',
    subheading: 'Use your earned rewards',
  }),
  hide_routine: true,
  edge_address_id_for_cart: 0,
  privacy_policy_tag_line: 'Review our',
  foxy_tag_line: 'Personalized Beauty & Grooming',

  video_card_subheading:
    'Foxy is a curated marketplace where only the top products reviewed by our experts are available. ',
  video_card_heading: 'What does this mean ?',
  video_card_image_url: '',
  ask_for_review_message: `FOXY is a curated marketplace where only the top products recommended by experts are available. We suggest you check out our expert recommended alternatives for this products.

  If you believe this product should be available on FOXY, ask for it to be reviewed.`,

  covid_19_cod_message:
    "For your and delivery partner's safety from COVID-19 we have temporarily suspended the option. Please pay online and be safe.",
  contactless_checkbox_subheading: 'Delivery partner will leave your package outside your door and call to inform you.',
  contactless_checkbox_heading: 'Contactless Delivery',
  allow_without_contactless: 'yes',
  show_contactless_checkbox: 'no',
  switch_contact_less_modal_message:
    "We are not accepting orders without no-contact delivery temporarily . For your and delivery partner's safety. Please switch to no-contact delivery.",
  is_cod_available: 'no',
  ask_for_review_toast_message: 'We will notify you once product is reviewed',
  outofstock_modal_message: 'This product has sold out!',
  discontinued_product_modal_message: 'This product is discontinued',
  unstocked_product_modal_message: 'This product is not reviewed',
  out_of_stock_toast_message: 'We will notifiy you',
  non_dimensional_video: `Video doesn't exist.`,
  show_square_product_card: 'yes',
  ask_for_review_button_text: 'Request taken',
  lands_on_offers_page: 'no',
  payment_enabled: 'UPI',
  payment_enabled_web: 'UPI',
  uac_gender: '{"male":1,"female":3,"unknown":0}',
  uac_gender_score: 0,
  uac_new_threshold: 0,
  uac_gender_threshold: 0,
  active_offer: 0,
  better_selfie_text: 'Make sure you are in a well lit space and hair are not tied',
  uxcam_enabled: 'no',

  default_ios_device_score: 1,
  default_ios_beauty_apps_score: 2,
  default_ios_commerce_app_score: 2,
  plastic_free_checked_by_default: 'yes',
  plastic_free_packaging_charge: 7,
  show_plastic_free_packaging_checkbox: 'yes',
  go_green_heading: 'Go Green',
  go_green_subheading: `Add plastic free packaging for ${CURRENCY_SYMBOL} 9`,
  recycle_with_foxy_message:
    'Check out this amazing initiative on Foxy, which not only makes recycling easy but also rewarding! All you need to do is collect your empty beauty & personal care products & fill in the details to claim your reward Team Foxy',
  prepaid_payment: 'digital payments',
  hide_payment_options: JSON.stringify({
    NetBanking: false,
    Cards: false,
    GPay: false,
    PayTmWallet: false,
    UPI: false,
    Cash: false,
  }),
  hide_more_banks_nb: 0,
  ios_notification_request: JSON.stringify({
    initialDisplayDuration: 0,
    displayDurationInMins: 1440,
    offers_tab_title: "You don't want to miss your awesome discounts!",
    offers_tab_subtitle: 'Allow Push notification to enjoy it!',
    payoff_page_title: 'Loving the personalised beauty suggestions?',
    payoff_page_subtitle: 'Get notified whenever there is a price drop on your favs',
    order_success_title: 'Get the best offer updates',
    order_success_subtitle: 'The easy way.',
    product_not_reviewed_title: 'Enable Push Notifications!',
    product_not_reviewed_subtitle: 'Let us notify you when the product is available',
    product_out_of_stock_title: 'Enable Push Notifications!',
    product_out_of_stock_subtitle: 'Let us notify you when the products you love are available',
    recycling_page_title: 'Enable Push Notifications!',
    recycling_page_subtitle: 'Enable Push Notifications!',
    follow_artist_title: 'Never Miss Out',
    follow_artist_subtitle: 'We will let you know as soon as your favourites post new',
    product_page_title: 'Never Miss Out',
    product_page_subtitle: 'Get notified whenever there is a price drop on your favs',
    brand_page_title: 'Loving these Indie brands?',
    brand_page_subtitle: 'We will let you know when we have best deals for you',
    search_page_title: '',
    search_page_subtitle: '',
  }),
  notification_prompt_request: JSON.stringify({
    initialDisplayDuration: 0,
    displayDurationInMins: 2880,
    offers_tab_title: "You don't want to miss your awesome discounts!",
    offers_tab_subtitle: 'Allow Push notification to enjoy it!',
    offers_tab_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    payoff_page_title: 'Loving the personalised beauty suggestions?',
    payoff_page_subtitle: 'Get notified whenever there is a price drop on your favs',

    payoff_page_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    order_success_title: 'Yayy! We have received your order!',
    order_success_subtitle: 'Enable notifications to receive updates on the status of your order.',
    order_success_action_1: 'Keep me updated!',
    order_success_action_2: 'Remind me later',
    order_success_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    product_not_reviewed_title: 'Enable Push Notifications!',
    product_not_reviewed_subtitle: 'Let us notify you when the product is available',
    product_not_reviewed_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    product_out_of_stock_title: "It's not gone forever!",
    product_out_of_stock_subtitle: 'Enable notifications to be the first to know when this product is back.',
    product_out_of_stock_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    product_out_of_stock_action_1: 'Enable NOW',
    product_out_of_stock_action_2: 'Remind me later',

    recycling_page_title: 'Enable Push Notifications!',
    recycling_page_subtitle: 'Enable Push Notifications!',
    recycling_page_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    follow_artist_title: 'Never Miss Out',
    follow_artist_subtitle: 'We will let you know as soon as your favourites post new',
    follow_artist_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    product_page_title: 'Never Miss Out',
    product_page_subtitle: 'Get notified whenever there is a price drop on your favs',
    product_page_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    brand_page_title: 'Loving these Indie brands?',
    brand_page_subtitle: 'We will let you know when we have best deals for you',
    brand_page_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    search_page_title: '',
    search_page_subtitle: '',
    search_page_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    wishlist_page_title: 'Foxy Fact: The more you wishlist the more you SAVE!',
    wishlist_page_subtitle:
      "Don't miss out on updates on your wishlist and Price Drops! P.S: We promise to keep the alerts relevant to you.",
    wishlist_page_action_1: 'Enable notifications',
    wishlist_page_action_2: 'Remind me later',
    whishlist_page_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    me_page_title: 'Our experts have just the right product for you!',
    me_page_subtitle: 'Enable notifications and stay updated on latest trends!',
    me_page_action_1: "Let's do it",
    me_page_action_2: 'Another time maybe',
    me_page_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    order_history_title: "WAIT! You're missing out.",
    order_history_subtitle: 'Stay up to date on the status of each order from the comfort of your home screen.',
    order_history_action_1: 'Enable notifications',
    order_history_action_2: 'Remind me later',
    order_history_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    feed_page_title: 'You snooze, you lose!',
    feed_page_subtitle:
      'Enable notifications to be the first to hear about exclusive offers on all your favorite brands.',
    feed_page_action_1: 'YES. Keep me updated!',
    feed_page_action_2: 'Remind me later',
    feed_page_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    foxy_edge_title: 'Be the first one',
    foxy_edge_subtitle: 'to know about all voucher drops & freebie launches on Foxy Edge',
    foxy_edge_action_1: 'Yes please !',
    foxy_edge_action_2: 'Later',
    foxy_edge_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    invite_center_title: 'You snooze, you lose!',
    invite_center_subtitle: 'Enable notifications to be the first to hear about exclusive offers only on the Foxy App.',
    invite_center_action_1: 'YES. Keep me updated!',
    invite_center_action_2: 'Remind me later',
    invite_center_image: `${APP_ASSET_URL}/860/notification_activation_pros.png`,
    routines_title: '✨ Ready to Supercharge Your Beauty Routine? 🌟',
    routines_subtitle: 'Allow us to send you personalized tips, morning pick-me-ups, and surprise beauty hacks! 🌈✨',
    routines_action_1: "🌟 Yes, Let's Glow! 🌟",
    routines_action_2: '🚫 Maybe Later, Keep Glowing! 🚫',
    wallet_title: 'Your Cash Back needs to talk to you!',
    wallet_subtitle: 'Tap allow to enable Wallet alerts',
    wallet_action_1: 'Cha-Ching! 🌟',
    wallet_action_2: 'Miss out on Wallet Balance',
    product_detail_page_title: '❤️ on Foxy comes with benefits!',
    product_detail_page_subtitle: 'Click Allow to get notifications on price drops on your faves!',
    product_detail_page_action_1: 'Get Flash Deal Prices! 🌟',
    product_detail_page_action_2: "I don't want Flash Deals",
  }),
  notification_prompt_request_v2: JSON.stringify({
    initialDisplayDuration: 0,
    displayDurationInMins: 2880,
    offers_tab_title: "You don't want to miss your awesome discounts!",
    offers_tab_subtitle: 'Allow Push notification to enjoy it!',
    offers_tab_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    payoff_page_title: 'Loving the personalised beauty suggestions?',
    payoff_page_subtitle: 'Get notified whenever there is a price drop on your favs',

    payoff_page_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    order_success_title: 'Yayy! We have received your order!',
    order_success_subtitle: 'Enable notifications to receive updates on the status of your order.',
    order_success_action_1: 'Keep me updated!',
    order_success_action_2: 'Remind me later',
    order_success_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    product_not_reviewed_title: 'Enable Push Notifications!',
    product_not_reviewed_subtitle: 'Let us notify you when the product is available',
    product_not_reviewed_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    product_out_of_stock_title: "It's not gone forever!",
    product_out_of_stock_subtitle: 'Enable notifications to be the first to know when this product is back.',
    product_out_of_stock_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    product_out_of_stock_action_1: 'Enable NOW',
    product_out_of_stock_action_2: 'Remind me later',

    recycling_page_title: 'Enable Push Notifications!',
    recycling_page_subtitle: 'Enable Push Notifications!',
    recycling_page_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    follow_artist_title: 'Never Miss Out',
    follow_artist_subtitle: 'We will let you know as soon as your favourites post new',
    follow_artist_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    product_page_title: 'Never Miss Out',
    product_page_subtitle: 'Get notified whenever there is a price drop on your favs',
    product_page_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    brand_page_title: 'Loving these Indie brands?',
    brand_page_subtitle: 'We will let you know when we have best deals for you',
    brand_page_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    search_page_title: '',
    search_page_subtitle: '',
    search_page_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    wishlist_page_title: 'Foxy Fact: The more you wishlist the more you SAVE!',
    wishlist_page_subtitle:
      "Don't miss out on updates on your wishlist and Price Drops! P.S: We promise to keep the alerts relevant to you.",
    wishlist_page_action_1: 'Enable notifications',
    wishlist_page_action_2: 'Remind me later',
    whishlist_page_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    me_page_title: 'Our experts have just the right product for you!',
    me_page_subtitle: 'Enable notifications and stay updated on latest trends!',
    me_page_action_1: "Let's do it",
    me_page_action_2: 'Another time maybe',
    me_page_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    order_history_title: "WAIT! You're missing out.",
    order_history_subtitle: 'Stay up to date on the status of each order from the comfort of your home screen.',
    order_history_action_1: 'Enable notifications',
    order_history_action_2: 'Remind me later',
    order_history_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    feed_page_title: 'You snooze, you lose!',
    feed_page_subtitle:
      'Enable notifications to be the first to hear about exclusive offers on all your favorite brands.',
    feed_page_action_1: 'YES. Keep me updated!',
    feed_page_action_2: 'Remind me later',
    feed_page_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    foxy_edge_title: 'Be the first one',
    foxy_edge_subtitle: 'to know about all voucher drops & freebie launches on Foxy Edge',
    foxy_edge_action_1: 'Yes please !',
    foxy_edge_action_2: 'Later',
    foxy_edge_image: `${APP_ASSET_URL}/994/whatsapp_image_2024_03_07_at_18.24.45.jpeg`,
    routines_title: '✨ Ready to Supercharge Your Beauty Routine? 🌟',
    routines_subtitle: 'Allow us to send you personalized tips, morning pick-me-ups, and surprise beauty hacks! 🌈✨',
    routines_action_1: "🌟 Yes, Let's Glow! 🌟",
    routines_action_2: '🚫 Maybe Later, Keep Glowing! 🚫',
    wallet_title: 'Your Cash Back needs to talk to you!',
    wallet_subtitle: 'Tap allow to enable Wallet alerts',
    wallet_action_1: 'Cha-Ching! 🌟',
    wallet_action_2: 'Miss out on Wallet Balance',
    product_detail_page_title: '❤️ on Foxy comes with benefits!',
    product_detail_page_subtitle: 'Click Allow to get notifications on price drops on your faves!',
    product_detail_page_action_1: 'Get Flash Deal Prices! 🌟',
    product_detail_page_action_2: "I don't want Flash Deals",
  }),
  cart_builder: JSON.stringify({
    min_cart_items: 2,
    min_cart_value: 999,
    free_delivery_above: 299,
    show_recommended_products: false,
    recommendedListSlug: `/api/${LISTS_API_VERSION}/lists/cart-recommended-list.json`,
    show_unlock_free_delivery_coupon: false,
    belowMinValuListTitle: 'Add items worth ₹${value} more',
    belowMinValueListSubtitle: 'to unlock FREE delivery fee',
    aboveMinValueListTitle: 'Before you checkout',
    aboveMinValueListSubtitle: 'here’s something you might be interested in',
  }),
  foxy_promise_toast: '{}',
  foxy_promise:
    'https://cdn2.foxy.in/eyJidWNrZXQiOiJla2FuZWstZm94eS1hc3NldHMiLCJrZXkiOiJtZWRpYS9iYW5uZXIvaW1hZ2UvNTU4L2ZveHlfcHJvbWlzZS5wbmciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjcyMCwiaGVpZ2h0IjowLCJmaXQiOiJjb3ZlciJ9fX0=',
  skip_selfie: false,
  foxy_promise_delivery: 'Free Delivery',
  uac_selfie: '{}',
  community_guideline_cta: 'check community guidelines',
  community_guidelines_cta_url: 'https://www.foxy.in/terms-of-use',
  community_guideline_heading:
    'Upload a short video reviewing the product and get a voucher of Rs.100 for your next order.',
  delivery_info: 'Free Delivery above Rs.399',
  local_notification: JSON.stringify({
    subject: 'Hey, Hurry!!',
    body: 'Your $(itemCount) cart items are selling fast.',
    sticky: 'true',
    expire_in: '60',
    primary_cta: 'Checkout Now',
    primary_destination: `${Config.WEB_URL}/cart_items?utm_source=local_notification&utm_medium=cart`,
    secondary_cta: 'Dismiss',
    secondary_destination: 'null',
    channel: 'Offers',
  }),
  user_attributes_column_display: JSON.stringify({
    two_attributes: 2,
    three_attributes: 3,
    four_attributes: 2,
    five_or_more_attributes: 3,
  }),
  app_landing: JSON.stringify({
    s1c1: `${Config.WEB_URL}/feed`,
    s1c0: `${Config.WEB_URL}/feed`,
    s0c1: `${Config.WEB_URL}/feed`,
    s0c0: `${Config.WEB_URL}/feed`,
    onDay: 4,
  }),
  number_of_attributes_to_select: 3,
  new_payment_journey: true,
  cart_reminder_notification_data: JSON.stringify({
    type: 'CART_REMINDER',
    behaviour: 'sticky',
    hours: '07',
    min: '00',
    last_order_ago: '48',
    frequency: '24',
    messages: {
      0: {
        heading: 'You have amazing taste!',
        subheading: 'Your cart has some of our bestselling items. Complete your order before these items sell out.',
      },
      1: {
        heading: 'We’re still here to glam you up!',
        subheading: 'Your cart wasn’t empty when you left. So, we have saved your items. Order them right away!',
      },
      2: {
        heading: 'Check out & checkout 😉',
        subheading: 'You don’t want to miss out on the items lying in your cart. ',
      },
      3: {
        heading: 'We guarantee you’ll love us',
        subheading: 'Complete your order and we guarantee 100% original items with a hassle free delivery. ',
      },
      4: {
        heading: 'Pssst, it’s your cart calling',
        subheading: 'Items in your cart are waiting to be called yours. Place your order today!',
      },
      5: {
        heading: 'Come back, we’re already missing you!',
        subheading: 'We don’t want you to lose out on the amazing offers applied on your cart. Place your order today',
      },
      6: {
        heading: 'Just checking up on you',
        subheading:
          'You dropped by but didn’t say stay ☹️Don’t miss out on the amazing offers applied on your cart. Place your order right away!',
      },
    },
  }),
  customised_cart_reminder_notification_data: JSON.stringify({
    type: 'CART_REMINDER',
    behaviour: 'non-sticky',
    alarm_delay_hrs: '24',
    min: '0',
    last_order_ago: '2',
    cart_updated: '8',
    notification_to_even_id: true,
    notification_info_phrase_1:
      'You have %{count_cart} item(s) worth ${CURRENCY_SYMBOL}${cart_value} ${CURRENCY_SYMBOL}${to_pay}, ',
    notification_info_phrase_2_1: 'having a discount of ${CURRENCY_SYMBOL}${cart_discount}. ',
    notification_info_phrase_2_2: 'and ${gwp_count} FREE gift(s) in your bag. ',
    notification_info_phrase_3: 'Continue to next step?',
    headings: {
      1: '${name}, your cart summary is here!',
      2: '${step_count}/4 steps completed on your bag 🛍',
      3: 'You just added a product to your Foxy Bag! 🗿',
    },
    images: {
      added_to_bag: 'https://cdn3.foxy.in/media/app_asset/image/104/cart_1.png',
      added_shipping: 'https://cdn3.foxy.in/media/app_asset/image/105/cart_2.png',
    },
  }),
  pagination_objects_count: 10,
  product_page: JSON.stringify({
    offer: {
      heading: 'Pick your best offer',
      subheading: 'Free gift is always there. We promise.',
    },
    ingredients: {
      heading: 'What’s in it ?',
    },
    description: {
      heading: 'Everything you need to know ',
    },
    rating: {
      heading: 'Rating & Reviews',
    },
    address: {
      heading: 'Delivery Info',
    },
    promise: {
      heading: 'Foxy Promise',
    },
    foxy_live: {
      heading: 'Foxy Promise',
      subheading: 'You’ll be taken to the Playstore to download the Foxy Live App. Try it ; It’s Free',
    },
    foxy_promise: {
      orignal: {
        heading: 'Orignal Products',
        subheading: 'Orignal Products',
      },
      delivery: {
        heading: 'Fast delivery',
        subheading: 'Fast delivery',
      },
      return: {
        heading: 'Easy Return',
        subheading: 'Easy Return',
      },
      payment: {
        heading: 'Secure Payment',
        subheading: 'Secure Payment',
      },
    },
  }),
  show_set_alert: 'no',
  selfie_heading_subheading: JSON.stringify({
    GenderAndAge: {
      heading: 'Let’s get to know about you',
      subheading: 'This helps us in suggesting a routine for you',
    },
    BeardAttributes: {
      heading: 'Let’s get to know your Beard',
      subheading: 'This helps us in suggesting a routine to you',
    },
    HairAttributes: {
      heading: 'Let’s get to know your Hair',
      subheading: 'This helps us in suggesting a routine to you',
    },
    HairGoals: {
      heading: 'What are your haircare goals ?',
      subheading: 'Pick top {number}',
    },
    SkinAttributes: {
      heading: 'Let’s get to know your Skin',
      subheading: 'This helps us in suggesting a routine to you',
    },
    SkinCareAttributes: {
      heading: 'What are your skincare goals ?',
      subheading: 'Pick top {number}',
    },
  }),
  api_time_out_in_ms: 15000,
  maxVideoDownloadLimit: 20,
  story_notification_data: JSON.stringify({
    array: [
      {
        title: 'Need Some attention?',
        description: 'Our new personalised stories is all about YOUR needs. Check it out! 🌟',
      },
      {
        title: 'How Many Stories Do We Have Today? ',
        description: 'Guess there’s only one way to find out! 😏',
      },
      {
        title: 'A Story a Day Keeps the Bad Mood Away',
        description: 'In our case, more like infinite! 💫',
      },
      {
        title: 'Playing Hard To Get Huh?',
        description: 'Would a new personalised stories feature help get you back? 😅',
      },
      {
        title: 'Ordered For Delicious Stories Content?',
        description: 'Coming right up! 🤤',
      },
      {
        title: 'Real People, Real Stories…',
        description: 'And we have them specially curated just for you. Check it out now!',
      },
      {
        title: 'Confused About Your Beauty Purchase?',
        description: 'Let our personalised stories guide the way💄',
      },
      {
        title: 'Exclusive Content is Calling You!',
        description: 'Get the inside scoop you can’t find anywhere else! 🤫',
      },
      {
        title: 'Calling All Beauty Enthusiasts!',
        description: 'Check out our latest stories and never miss a single trend! 👸🏻',
      },
      {
        title: 'The Clock is Ticking!',
        description: 'Check out our latest stories before they disappear! ⏰',
      },
    ],
  }),
  android_max_cache_limit: 20,
  android_notification_channels: JSON.stringify([
    {
      channelId: `${Config.PACKAGE_NAME_ANDROID}.edge_exclusive`,
      channelName: 'Edge Exclusive',
      channelImportance: 'high',
      enableBadge: true,
      visibleOnLockScreen: true,
      enableVibration: true,
      enableSound: true,
      customNotificationSound: '',
    },
    {
      channelId: `${Config.PACKAGE_NAME_ANDROID}.offers_and_promotions`,
      channelName: 'Offers and Promotions',
      channelImportance: 'high',
      enableBadge: false,
      visibleOnLockScreen: true,
      enableVibration: true,
      enableSound: true,
      customNotificationSound: '',
    },
    {
      channelId: `${Config.PACKAGE_NAME_ANDROID}.regimes`,
      channelName: 'Regimes',
      channelImportance: 'high',
      enableBadge: true,
      visibleOnLockScreen: true,
      enableVibration: true,
      enableSound: true,
      customNotificationSound: '',
    },
    {
      channelId: `${Config.PACKAGE_NAME_ANDROID}.order_tracking`,
      channelName: 'Order Tracking',
      channelImportance: 'high',
      enableBadge: true,
      visibleOnLockScreen: true,
      enableVibration: true,
      enableSound: true,
      customNotificationSound: '',
    },
    {
      channelId: `${Config.PACKAGE_NAME_ANDROID}.bag_alerts`,
      channelName: 'Bag Alerts',
      channelImportance: 'low',
      enableBadge: true,
      visibleOnLockScreen: true,
      enableVibration: false,
      enableSound: false,
      customNotificationSound: 'foxy_alert',
    },
    {
      channelId: `${Config.PACKAGE_NAME_ANDROID}.deals_just_for_you`,
      channelName: 'Deals Just For You',
      channelImportance: 'high',
      enableBadge: true,
      visibleOnLockScreen: true,
      enableVibration: true,
      enableSound: true,
      customNotificationSound: 'foxy_alert',
    },
    {
      channelId: `${Config.PACKAGE_NAME_ANDROID}.new_launches`,
      channelName: 'New Launches',
      channelImportance: 'medium',
      enableBadge: true,
      visibleOnLockScreen: true,
      enableVibration: true,
      enableSound: true,
      customNotificationSound: '',
    },
    {
      channelId: `${Config.PACKAGE_NAME_ANDROID}.system_updates`,
      channelName: 'System Updates',
      channelImportance: 'low',
      enableBadge: false,
      visibleOnLockScreen: false,
      enableVibration: false,
      enableSound: false,
      customNotificationSound: '',
    },
    {
      channelId: `${Config.PACKAGE_NAME_ANDROID}.routine_alerts`,
      channelName: 'Routine Alerts',
      channelImportance: 'high',
      enableBadge: true,
      visibleOnLockScreen: true,
      enableVibration: true,
      enableSound: true,
      customNotificationSound: 'foxy_alert',
    },
  ]),
  selfie_attribute_last_button_text: 'Show my personalized items',
  share_app_text:
    'Hi, check out this amazing app that talks all things beauty.  Find your perfect beauty match from a wide range of most interesting brands on Foxy where everyday is a sale-day. Download here :',
  report_personalised_product:
    'https://foxyapp.typeform.com/to/AhoRbLuW#user_name=${user_name}&user_id=${guest_token}&slug=${slug}',
  selfie_deletion_reasons: `[
    {
       "reason":"I have privacy concerns",
       "index":1
    },
    {
       "reason":"I don’t like my selfie",
       "index":2
    },
    {
       "reason":"I am facing technical issues",
       "index":3
    }
  ]`,
  delete_account_reasons: `I have privacy concerns,I don't want to use Foxy anymore,I'm getting too many messages from Foxy,I'm using another account`,
  whatsapp_deleteSelfie_contactUs: 'Hi, I have a concern regarding my selfie on Foxy app',
  delete_selfie_reason_not_selected_error_message: 'Please select a reason',
  delete_selfie_logout_toast_message:
    'You are advised to log out from all other devices to remove your locally stored data',
  connection_error: `Something's not right. Please try checking both wifi/4G or try again later.`,
  checkout_new: JSON.stringify({
    change_add_to_cart_button: true,
    button_name: 'Add to cart',
    navigate_after_add_to_cart: true,
    address_before_cart: true,
    cart_priority: 1,
    payment_priority: 0,
    address_before_cart_for_added_products: false,
  }),
  redirect_text: 'Something went wrong, redirecting back in 3..',
  wishlist_deals_feature_card: JSON.stringify({
    title: 'Your wishlist Deals',
    subtitle: 'Insane prices on products for you today',
    guest_user_title: 'Your wishlist Deals',
    guest_user_subtitle: 'Login to unlock',
  }),
  go_green_subheading_selected: `Plastic free packaging added for ${CURRENCY_SYMBOL} 9`,
  foxy_promise_cart: 'https://cdn3.foxy.in/media/app_asset/image/592/foxy_ender.png',
  update_shipment_status: JSON.stringify([
    {
      reason: 'I have received my shipment already',
      index: 1,
    },
    {
      reason: 'I got shipment update directly from brand',
      index: 2,
    },
    {
      reason: 'I haven’t received my shipment',
      index: 3,
    },
  ]),
  daily_deals_notifications: JSON.stringify({
    0: {
      heading: 'Hurray',
      subheading: "Today's flash deals are here",
    },
    1: {
      heading: 'Hurray',
      subheading: "Today's flash deals are here ${product_name}",
    },
    2: {
      heading: 'Hurray',
      subheading: "Today's ${offer_discount} flash deals are here",
    },
    3: {
      heading: 'Hurray',
      subheading: "Today's flash deals are here",
    },
    4: {
      heading: 'Hurray',
      subheading: "Today's flash deals are here",
    },
    5: {
      heading: 'Hurray',
      subheading: "Today's flash deals are here",
    },
    6: {
      heading: 'Hurray',
      subheading: "Today's flash deals are here",
    },
  }),
  friday_drop_notification: JSON.stringify({
    hourOfDay: 20,
    dayOfWeek: 6,
    notifications: [
      {
        title: 'Hurray',
        description: "Don't miss today's deals",
        deepLink: `${Config.WEB_URL}/foxy_edge?utm_source=local_notification&utm_medium=foxy_edge`,
        primary_cta: 'CONTINUE',
        primary_destination: `${Config.WEB_URL}/foxy_edge?utm_source=local_notification&utm_medium=foxy_edge`,
        secondary_cta: 'DISMISS',
        secondary_destination: 'DELETE',
      },
      {
        title: 'Hurray',
        description: "Today's deals are here",
        deepLink: `${Config.WEB_URL}/foxy_edge?utm_source=local_notification&utm_medium=foxy_edge`,
        primary_cta: 'CONTINUE',
        primary_destination: `${Config.WEB_URL}/foxy_edge?utm_source=local_notification&utm_medium=foxy_edge`,
        secondary_cta: 'DISMISS',
        secondary_destination: 'DELETE',
      },
      {
        title: 'Hurray',
        description: 'Latest deals',
        deepLink: `${Config.WEB_URL}/foxy_edge?utm_source=local_notification&utm_medium=foxy_edge`,
        primary_cta: 'CONTINUE',
        primary_destination: `${Config.WEB_URL}/foxy_edge?utm_source=local_notification&utm_medium=foxy_edge`,
        secondary_cta: 'DISMISS',
        secondary_destination: 'DELETE',
      },
      {
        title: 'Hurray',
        description: 'Current deals',
        deepLink: `${Config.WEB_URL}/foxy_edge?utm_source=local_notification&utm_medium=foxy_edge`,
        primary_cta: 'CONTINUE',
        primary_destination: `${Config.WEB_URL}/foxy_edge?utm_source=local_notification&utm_medium=foxy_edge`,
        secondary_cta: 'DISMISS',
        secondary_destination: 'DELETE',
      },
    ],
  }),
  versions_to_be_hard_updated_v2: '{}',
  btl_timer_text: 'Deals in your bag expire in',
  review_reminder_notification_data: {
    heading: 'Review your order',
    subheading: 'Show off your new look and get 20% off on your next order.',
  },
  disable_btl: 'false',
  show_cod_confirmation_modal: 'true',
  versions_to_be_hard_updated_ios: '{}',
  review_modal_title: 'How satisfied are you with our ordering experience?',
  versions_to_be_hard_updated_ios_v2: '{}',
  upload_status_tray_strings: JSON.stringify({
    in_progress: 'Uploading..',
    failed: 'Uploading Failed',
    success: 'Your video was successfully uploaded',
    paused: 'Paused. Waiting for better network connection',
    buttonText: {
      failed: 'RETRY',
      success: 'GOT IT',
      in_progress: 'CANCEL',
    },
  }),
  product_review_page_strings: JSON.stringify({
    placeholderBody: 'Start typing your review here',
    errorBody: 'Please enter a description',
    titlePlaceholder: 'Title',
    errorTitle: 'Please enter a title',
  }),
  cod_confirmation_modal_message: 'You can pay via Cash/Card/UPI App at the time of delivery.',
  hide_actions: JSON.stringify({
    group_deal: false,
    wishlist_deal: false,
    invite_contacts: false,
    invite_centre_v2: true,
  }),
  invite_center_strings: JSON.stringify({
    popup_modal_title_subtitles: {
      foxy_edge: {
        heading: 'Request a membership invite',
        subheading: 'from your BFF',
      },
    },
    foxy_edge_request_modal: [
      'Request BFF from your contacts to invite you',
      'Your BFF who is already a member of Club Foxy will have free invites to give away',
      'Once they approve your request, you’ll be a Foxy member with a 90-day free trial.',
    ],
    how_to_become_eligible_info: [
      'Be a regular member on Foxy & get invited by us',
      'All you have to do is place orders on Foxy',
      'And open the app for offers, deals & more..',
      'And one fine day, you’ll get the good news. 😉',
    ],
    rich_message_non_member: `Hey, check out how you can save upto ${CURRENCY_SYMBOL}8300 on all things beauty by joining Foxy Edge ✨`,
    rich_message_member: ' Hey, I am saving BIGG with Foxy Edge and I recommend you try it out too! 😉',
    unauthorized_rich_message: `Hey, I am requesting an invite to join ${Config.APP_NAME}! Approve my request here: $link`,
    authorized_rich_message: `Hey, I am saving BIGGG with  ${Config.APP_NAME}, an exclusive beauty and electronics app. I recommend you try it too: $link`,
  }),
  group_deals_contacts_strings: JSON.stringify({
    rich_message_referral:
      'Hi, I want to gift you a personalised beauty kit worth $groupPrice for FREE with your first order on FOXY.\n \nStep 1. Install the Foxy App: $link\n \nStep 2. Claim your kit: $link\n \nI love this app. Do check it out.',
    rich_message:
      'Hi, I want to buy this foxy product and found this amazing group deal which unlocks $groupPrice ($discount) savings.\n \nStep 1. Install the Foxy App: $link\n \nStep 2. Wishlist this product: $link\n \nYou will also get this deal or buy anything else with Rs 200 off  (code: ‘FOXYGROUP’).\n \nPlease confirm, so I can order.',
    remind_message_referral:
      'Hi, don’t miss out on your FREE Foxy Kit.\n \nHere’s the link if you need it again: $link',
    remind_message: 'Hi, still waiting for you to accept my invitation.\nHere’s the link if you need it again: $link',
    reinvite_message_referral:
      'Hi, don’t miss out on your FREE Foxy Kit invitation.\n \nHere’s the link if you need it again: $link',
    popup_modal_title_subtitles: {
      referral: {
        heading: 'Gift this to your BFF',
        subheading: '& collect Foxy cashback for yourself',
      },
      group_deal: {
        heading: 'Claim the group deal',
        subheading: '',
      },
      referral_see_how: {
        heading: 'Invite more. Earn more.',
        subheading: `Earn upto ${CURRENCY_SYMBOL}2600 and more Foxy cashback when your BFFs accept your Foxy invite.`,
        banner_text: `1 friend invited = 100 points = ${CURRENCY_SYMBOL}200 Foxy cashback`,
      },
    },
    referral_popup_modal: [
      'Invite BFF from your contacts to Foxy',
      'BFF installs the app using your referral link and claims this kit for FREE with their first order',
      `You’ll get ${CURRENCY_SYMBOL}200 Foxy cashback after your BFF’s order is delivered`,
      `Invite more, earn more. Get exciting rewards & cashback worth ${CURRENCY_SYMBOL}2600 and more`,
    ],
    group_deal_popup_modal: [
      'Invite $required_group_size friends from your contacts to Foxy',
      'They install the app and wishlist this product within 24 hours',
      'Everyone unlocks the group deal',
    ],
    see_how_popup_modal: [
      'More friends you invite, bigger the cashback you can claim!',
      `For every successful friend invite, you get ${CURRENCY_SYMBOL}200 cashback and so on!`,
      `Get bonus ${CURRENCY_SYMBOL}200 cashback when your 5th friend joins, and ${CURRENCY_SYMBOL}400 more when your 10th friend joins`,
      'Convert all your earned cashback into as many vouchers as you need.',
    ],
    today_deals_feature_card: JSON.stringify({
      with_selfie_title: 'Your Flash Deals',
      with_selfie_subtitle: 'Here Now, Gone Tomorrow!',
      without_selfie_title: 'Your Flash Deals',
      without_selfie_subtitle: 'Take your selfie to unlock daily deals',
    }),
    group_deal_card_product_page: {
      uninitialized: {
        heading: 'Get this group deal for $groupPrice',
        subheading: 'Invite $required_group_size friends on foxy to claim the group deal',
        button_text: 'How this works?',
      },
      initialized: {
        heading: 'Get this for $groupPrice',
        subheading: 'Waiting for $numPendingInvitesToUnlock more people to unlock',
        button_text: 'Go to Invite Center',
      },
      completed: {
        heading: 'Group deal for $groupPrice unlocked 🎉',
        subheading: 'Congrats! Unlocked successfully. Redeem now!',
      },
    },
    invite_center_empty: {
      heading: 'Share cray deals with your gang',
      subheading: 'and unlock amazing discounts together!',
      image_url: 'https://cdn3.foxy.in/media/app_asset/image/119/product.png',
      button_text: 'Find now!',
      navigateTo: 'TodayDeals',
    },
    contact_already_exists: 'Already on Foxy',
    share_link_group_deal_title: 'Claim this group deal on Foxy!',
    maxContactsVisibleInACard: 10,
    maxSmsAllowed: 15,
    maxSmsSelectedToast: 'Only $maxSmsAllowed sms can be sent at once!',
    referral_gift_data: {
      image: 'https://cdn3.foxy.in/media/app_asset/image/383/referral_kit_removebg_preview.png',
      mrp: 850,
      name: 'Foxy kit with a pouch and 3 best-selling minis from Boho Botanist',
      slug: 'api/v6/lists/free-foxy-kit',
    },
    insufficient_points_toast: 'Low foxy points, refer more people to earn this reward',
    group_deals_url: `${Config.WEB_URL}/lists/group-deals-for-you-your-tribe`,
    points_history_empty_text: 'All your earned points’ history will show up here',
  }),
  last_wishlisted_item_time: 0,
  wishlist_tooltip: JSON.stringify({
    no_of_time_show_hint: 4,
    no_of_time_show_tooltip: 6,
  }),
  like_gif: 'https://cdn3.foxy.in/media/app_asset/image/125/item_like_for_autosearch_and_modal.gif',
  checkout_wishlist_deals: 'Checkout wishlist deals',
  wishlist_modal_data: JSON.stringify({
    heading: 'Yay! FLASHDEAL unlocked!',
    subheading: 'You just unlocked ${wishlist_discount}% OFF on this product. Keep wishlisting to get more discounts!',
    heading_flashdeal_inactive: 'You have a chance to get ${this.wishlistDiscount}% OFF',
    subheading_flashdeal_inactive:
      'Products you wishlist have a higher chance of appearing on Flashdeals! Keep wishlistin’ 😉',
  }),
  pay_on_foxy_benefits: JSON.stringify({
    header_title: 'Why should I pay on Foxy?',
    heading_1: 'Pay the Salon on Foxy',
    sub_heading_1: 'and get assured rewards everytime',
    find_salon_heading: 'Find the salon you’re at',
    find_salon_sub_heading: 'You can search it or scan the QR code present in the store',
    pay_on_foxy_heading: 'Pay the salon using Foxy',
    pay_on_foxy_sub_heading:
      'And get rewards! Everytime!! From a Beauty Kit worth INR 1500, to discounts upto 40% on beauty products. ',
    heading_2: 'Spin the wheel and get a reward everytime!',
    sub_heading_2: 'and get assured rewards everytime',
    heading_3: 'Add Important Info',
    recommend_foxy_heading: 'Recommend us a salon for Foxy',
    recommend_foxy_sub_heading: 'Become a local guide for Foxy by providing us salon details of salons',
    verify_foxy_heading: 'Wait for us to verify the details',
    verify_foxy_sub_heading: 'And get INR 1000 vouchers on Foxy once we sign up the salon for “Pay on Foxy”',
    button_text: 'Got it',
  }),
  salon_reward_modal: JSON.stringify({
    step_1: 'You can check out this offer on your Home Page & My Profile',
    step_2: 'Offer valid for the next 48 hours. Use it today!',
  }),
  influencer_page: JSON.stringify({
    horizontal_social_accounts_list: true,
  }),

  enable_generic_green_text: true,
  notification_sounds: JSON.stringify([
    'https://cdn3.foxy.in/generic_files/app_asset/generic_file/381/notification_sound_one.m4r',
    'https://cdn3.foxy.in/generic_files/app_asset/generic_file/381/notification_sound_one.m4r',
  ]),
  mild_delay_offer_terms: JSON.stringify({
    0: 'Foxy voucher for your next order',
    1: 'The voucher code will be sent to you via Whatsapp on the delivery of your current order ',
    2: 'You can also view your offers on the personalised page',
  }),
  callback_reasons: JSON.stringify({
    confirmed: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    cancellation_requested: [
      'I have order related queries',
      'I have payment related issue',
      'I have coupon related queries',
    ],
    cancelled: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    created: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    packed: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    ready_to_ship: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    shipped: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    delivered: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    lost_in_transit: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    in_transit: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    out_for_delivery_for_first_attempt: [
      'I have order related queries',
      'I have payment related issue',
      'I have coupon related queries',
    ],
    out_for_delivery_for_second_attempt: [
      'I have order related queries',
      'I have payment related issue',
      'I have coupon related queries',
    ],
    out_for_delivery_for_third_attempt: [
      'I have order related queries',
      'I have payment related issue',
      'I have coupon related queries',
    ],
    first_attempt_failed: [
      'I have order related queries',
      'I have payment related issue',
      'I have coupon related queries',
    ],
    second_attempt_failed: [
      'I have order related queries',
      'I have payment related issue',
      'I have coupon related queries',
    ],
    third_attempt_failed: [
      'I have order related queries',
      'I have payment related issue',
      'I have coupon related queries',
    ],
    rto: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    rto_received: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    picked: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    out_for_delivery: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    delay_before_delivery: [
      'I have order related queries',
      'I have payment related issue',
      'I have coupon related queries',
    ],
    lost: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    lost_before_dispatch: [
      'I have order related queries',
      'I have payment related issue',
      'I have coupon related queries',
    ],
    return_processing: [
      'I have order related queries',
      'I have payment related issue',
      'I have coupon related queries',
    ],
    rto_failed: ['I have order related queries', 'I have payment related issue', 'I have coupon related queries'],
    two_day_after_delivery: [
      'I have order related queries',
      'I have payment related issue',
      'I have coupon related queries',
    ],
    seven_day_after_delivery: [
      'I have order related queries',
      'I have payment related issue',
      'I have coupon related queries',
    ],
    delivered_without_delay: [
      'I have order related queries',
      'I have payment related issue',
      'I have coupon related queries',
    ],
    delivered_with_delay: [
      'I have order related queries',
      'I have payment related issue',
      'I have coupon related queries',
    ],
  }),
  attempt_fail_reasons: JSON.stringify(['one', 'two', 'three']),
  rate_product_data: JSON.stringify({
    image: 'https://cdn3.foxy.in/media/app_asset/image/214/nft_value.png',
    heading: 'Rate products and get rewarded',
    subheading: 'All you have to do is rate and review all products from your purchase!',
  }),
  missed_delight_offer_data: JSON.stringify({
    heading: 'Claim what you missed earlier!',
    success_heading: 'Offer successfully claimed',
  }),
  fire_user_information_update: false,
  routine_rate_experience: JSON.stringify({
    heading: 'Tell us how it went!',
    subheading: 'What worked for you? Is there something we can improve on? Help us out. 😊',
    image_url: 'https://cdn3.foxy.in/media/app_asset/image/233/2.png',
    button_image_url: 'https://cdn3.foxy.in/media/app_asset/image/287/star.png',
    button_text: 'Rate Experience',
  }),
  club_member: JSON.stringify({
    tab: 'Foxy Edge',
    tag: 'Flash Deal',
    emphasisText: 'for Foxy edge members',
    image_width: 80,
  }),
  enabled_google_adv_tracking: false,
  login_modal_info: JSON.stringify({
    DEFAULT: {
      title: 'Login • Sign up',
      subTitle: 'and start shopping',
      image: `${APP_ASSET_URL}/532/login_1.png`,
      type: 'DEFAULT',
    },
    MY_KIT: {
      title: 'Login',
      subTitle: 'To view your kit',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    ADD_TO_CART: {
      title: 'Login to get this product',
      subTitle: 'At amazing discounts! Only on Foxy',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    LIKE_ARTIST: {
      title: 'Login',
      subTitle: 'To like this artist',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    LIKE_VIDEO: {
      title: 'Login to this video',
      subTitle: 'Support artists on Foxy',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    LIKE_COLLECTION: {
      title: 'Login',
      subTitle: 'To like this collection',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    VIEW_PROFILE: {
      title: 'Login now',
      subTitle: 'To view your profile',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    VIEW_INVITES: {
      title: 'Login',
      subTitle: 'To view all pending invites',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    VIEW_CONTACTS: {
      title: 'Login',
      subTitle: 'To view contacts',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    FOLLOW_ARTIST: {
      title: 'Login',
      subTitle: 'To follow this artist on Foxy',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    LOGIN_AFTER_ONE_DAY: {
      title: 'Login again',
      subTitle: 'To keep using Foxy app',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    CHECKOUT: {
      title: 'Login ',
      subTitle: 'To proceed with checkout',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    RATE: {
      title: 'Login to rate this product',
      subTitle: 'and help fellow beauty enthusiasts',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    PAY_WITH_FOXY: {
      title: 'Login',
      subTitle: 'To pay with Foxy',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    WISHLIST_VIEW: {
      title: 'Login now ',
      subTitle: 'And build your wishlist to get crazy discounts on your favorites!',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    APPLY_COUPON_CODE: {
      title: 'Login',
      subTitle: 'To apply the offer code',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    TAKE_SELFIE: {
      title: 'Login',
      subTitle: 'to take selfie',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    LIKE_PRODUCT: {
      title: 'Login',
      subTitle: 'to wishlist this product',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    CHECK_ORDER_HISTORY: {
      title: 'Login',
      subTitle: 'to see your orders',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    FOXY_EGDE: {
      title: 'Login',
      subTitle: 'to check eligibility',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
    DERMAT_ONBOARD: {
      title: 'Login',
      subTitle: 'Create a FoxyRx dermatologist profile',
      image: `${APP_ASSET_URL}/532/login_1.png`,
    },
  }),
  carry_bag_options: JSON.stringify({
    title: 'Add carrybag',
    description: `Ask our staff to hand over a bag for ${CURRENCY_SYMBOL}`,
    price: 10,
    showCarryBagCheckbox: true,
    isCarryBagCheckedByDefault: true,
  }),
  about_us_urls: JSON.stringify({
    privacy_policy: Config.LINK_PRIVACY_POLICY,
    terms_of_use: '',
    setting_options: [],
  }),
  order_page_contact_us_number: '',
  contact_us_email: '',
  loyalty_plan_benefits_images: JSON.stringify({
    freebies: {
      image_url: `${APP_ASSET_URL}/516/image__9_.png`,
      width: 140,
      height: 180,
      top: -60,
      right: -15,
      background_color: '#7C9DEB',
    },
    deals: {
      image_url: `${APP_ASSET_URL}/517/image__10_.png`,
      width: 110,
      height: 140,
      top: -30,
      right: 0,
      background_color: '#9FD6B7',
    },
    experiences: {
      image_url: `${APP_ASSET_URL}/518/frame_6369.png`,
      width: 150,
      height: 160,
      top: -55,
      right: -20,
      background_color: '#F5AB5C',
    },
  }),
  join_pro_modal_image: `${APP_ASSET_URL}/546/become_pro_modal.png`,
  edge_coupon_colors: JSON.stringify({
    percentage: colors.edgeOfferCard.offer_green,
    absolute: colors.edgeOfferCard.offer_blue,
    gift: colors.edgeOfferCard.offer_yellow,
  }),
  routine_minimum_participation_count: 8,
  disable_places_api: false,
  deals_tag_images: JSON.stringify({
    flash_deal: `${APP_ASSET_URL}/28/label.png`,
    group_deal: `${APP_ASSET_URL}/135/ic_pink_label.png`,
  }),
  become_member_modal: JSON.stringify({
    heading: 'Be a member of Foxy Edge',
    subheading: 'Get exciting gifts, amazing benefits, extra discounts & a lot more...',
    images: [
      'https://cdn3.foxy.in/media/app_asset/image/390/become_member_image1.png',
      'https://cdn3.foxy.in/media/app_asset/image/391/become_member_image2.png',
      'https://cdn3.foxy.in/media/app_asset/image/392/become_member_image3.png',
      'https://cdn3.foxy.in/media/app_asset/image/393/become_member_image4.png',
    ],
    hideKnowMore: false,
  }),
  discount_offset: 1,
  upgrade_tray_edge: "You're missing out on some benefits",
  edge_join_success: "You've unlocked unlimited offers, giveaways & experiences with Foxy Edge 😉",
  edge_extension_card_position_in_cart: JSON.stringify({
    positions: ['above_bill_details', 'above_delivery_info', 'above_offers'],
    selected_position: 'above_bill_details',
    show_edge_extension_card: false,
  }),
  edge_extension_card_text: JSON.stringify({
    member: {
      description: 'Edge members usually save 20% extra on orders. Renew your plan to unlock offers.',
      cta_text: 'Renew',
      cta_url: `${Config.WEB_URL}/loyalty_plans`,
    },
    non_member: {
      description: 'Edge members usually save 20% extra on orders. Activate your plan (for free 😉) to unlock offers.',
      cta_text: 'Activate',
      cta_url: `${Config.WEB_URL}/loyalty_plans`,
    },
  }),
  loyalty_plan_tab_cta: JSON.stringify({
    non_member: 'Activate Edge Plan',
    expired: 'Renew now',
  }),
  edge_constants_for_cart_tray: JSON.stringify({
    trial_member: [
      {
        trayText: `Get yourself a FREE gift worth ${CURRENCY_SYMBOL}3000`,
        ctaText: 'Upgrade',
        ctaAction: `${Config.WEB_URL}/loyalty_plans`,
        image: {
          uri: 'https://cdn3.foxy.in/media/app_asset/image/514/edge_icon.png',
        },
      },
      {
        trayText: 'Claim your Edge benefits of the week',
        ctaText: 'Explore',
        ctaAction: `${Config.WEB_URL}/loyalty_plans`,
        image: {
          uri: 'https://cdn3.foxy.in/media/app_asset/image/514/edge_icon.png',
        },
      },
      {
        trayText: `Shop for ${CURRENCY_SYMBOL}{remaining_order_amount_for_extension} more to extend Edge plan! `,
        ctaText: 'Shop more',
        ctaAction: `${Config.WEB_URL}/loyalty_plans`,
        image: {
          uri: 'https://cdn3.foxy.in/media/app_asset/image/514/edge_icon.png',
        },
      },
    ],
    member: [
      {
        trayText: 'Claim your Edge benefits of the week',
        ctaText: 'Explore',
        ctaAction: `${Config.WEB_URL}/loyalty_plans`,
        image: {
          uri: 'https://cdn3.foxy.in/media/app_asset/image/514/edge_icon.png',
        },
      },
      {
        trayText: `Your savings on Edge - ${CURRENCY_SYMBOL}{membership_savings}. `,
        ctaText: 'Explore',
        ctaAction: `${Config.WEB_URL}/loyalty_plans`,
        image: {
          uri: 'https://cdn3.foxy.in/media/app_asset/image/514/edge_icon.png',
        },
      },
    ],
    expired_member: [
      {
        trayText: `You’ve saved ${CURRENCY_SYMBOL}{membership_savings} with Foxy Edge.`,
        ctaText: 'Renew',
        ctaAction: `${Config.WEB_URL}/loyalty_plans`,
        image: {
          uri: 'https://cdn3.foxy.in/media/app_asset/image/514/edge_icon.png',
        },
      },
    ],
    non_member: [
      {
        trayText: 'Claim FREE gifts with every purchase ',
        ctaText: 'Explore',
        ctaAction: `${Config.WEB_URL}/loyalty_plans`,
        image: {
          uri: 'https://cdn3.foxy.in/media/app_asset/image/514/edge_icon.png',
        },
      },
      {
        trayText: 'Edge members usually save 20% extra',
        ctaText: 'Join now',
        ctaAction: `${Config.WEB_URL}/loyalty_plans`,
        image: {
          uri: 'https://cdn3.foxy.in/media/app_asset/image/514/edge_icon.png',
        },
      },
    ],
    show_edge_data_in_cart_tray: false,
  }),
  storage_permission_modal_image: `${APP_ASSET_URL}/870/storage_icon.png`,
  /**
   *   little hack right now
   *   We need to fetch whitelisted events from remote config.
   *   Because of performance we cannot fetch it everytime because it happens every second.
   *   In Code, we are storing this JSON (Actual value will be json) in a static variable.
   *   If we give actual json as default value, we will never be able to differentiate between whats local value and whats remote value
   */
  whitelisted_events: '',
  product_accordian_initial_expanded_index: 0,
  delete_account_description: `All the data associated with your account (including your selfie, profile and reviews will be permanently deleted. You won't be able to retrieve any content or information associated with your account once it is deleted.`,
  onboarding_notification_desc: 'Continue your journey to level-up your beauty and grooming experience',
  tailor_fit_guide_video: 'https://www.youtube.com/watch?v=R8i9I_J9URo',
  product_deals_tag_data: JSON.stringify({
    flash_deal: {
      image: `${APP_ASSET_URL}/28/label.png`,
      image_width: 80,
      image_height: 20,
      product_page_label_width: 100,
      product_page_label_height: 20,
      label_name: Config.FLASHDEAL_FLAG_TEXT,
    },
    group_deal: {
      image: `${APP_ASSET_URL}/135/ic_pink_label.png`,
      image_width: 80,
      image_height: 20,
      product_page_label_width: 100,
      product_page_label_height: 20,
      label_name: Config.GROUPDEAL_FLAG_TEXT,
    },
    custom_label: {
      image: `${APP_ASSET_URL}/559/label.png`,
      image_width: 80,
      image_height: 20,
      product_page_label_width: 100,
      product_page_label_height: 20,
    },
    flash_deal_v2: {
      image: `${APP_ASSET_URL}/777/flash_deal_new.png`,
      image_width: 80,
      image_height: 20,
      product_page_label_width: 100,
      product_page_label_height: 20,
      label_name: 'Flash Deal',
      text_left_margin: 12,
      text_color: '#000000',
    },
  }),
  cart_builder_payment_page_recommended_list: false,
  cod_fee_threshold: 'Free on orders above Rs.699',
  combo_builder_prompts: JSON.stringify({
    offer_cap_reached: {
      message:
        'Maximum products allowed with this offer are ${offer_level_quantity_cap}. Adding more products will remove this offer.',
      bgColor: '#FFECE5',
    },
    sku_cap_reached: {
      message:
        'To avail of this offer, please limit the number of eligible items in your Foxy bag to max ${sku_level_quantity_cap} per item',
      bgColor: '#FFECE5',
    },
    combo_not_started: {
      message: '',
      bgColor: '#E4F2FF',
    },
    incomplete_combo: {
      message: 'Add ${quantity_required_to_complete_combo} item(s) to complete combo',
      bgColor: '#E4F2FF',
    },
    extendable_combo: {
      message: 'Yay! Combo completed. Shop more to save more',
      bgColor: '#E4F2FF',
    },
    combo_required_quantity_fulfiled: {
      message: 'Required quantity added for the combo, claim it now!',
      bgColor: '#FFECE5',
      cta_text: 'APPLY OFFER',
      cta_action: 'apply_offer',
    },
    combo_completed: {
      message: 'Yay! Combo completed.',
      bgColor: '#E4F2FF',
    },
    all_products_added_from_a_list: {
      message: 'All products from this list have been added',
      bgColor: '#E4F2FF',
    },
    default: { message: '', bgColor: '#E4F2FF' },
  }),
  show_email_input_on_past_orders: false,
  selfie_card: JSON.stringify({
    review_selfie: {
      title: 'Review your selfie',
      subtitle: 'to get recommendations & more benefits',
      buttonText: 'Review',
      buttonAction: 'review_selfie',
    },
    selfieNotPresentForMember: {
      title: 'Take a selfie',
      subtitle: 'To get recommendations & more benefits',
      buttonText: 'Take Selfie',
      buttonAction: 'take_selfie',
    },
    selfieNotPresentAndMembershipInactive: {
      title: 'Take a selfie',
      subtitle: 'To get recommendations & more benefits',
      buttonText: 'Take Selfie',
      buttonAction: 'take_selfie',
    },
    selfiePresentAndMembershipInactive: {
      title: 'Your selfie: updated ✅',
      subtitle: 'Check out personalised recommendations',
      buttonText: 'View',
      buttonAction: 'selfie_reviewed',
    },
  }),
  verify_phone_disclaimer_on_address_page: 'Verify this number to check if you have any saved addresses',
  product_page_empty_address_delivery_data: JSON.stringify({
    heading: 'Usually delivered in 1-5 days',
    message: 'Add address to see delivery timelines, COD availability and return pickup options',
  }),
  contact_us_modal_data: JSON.stringify({
    heading: 'How would you like to get in touch with us?',
    share_options: [
      {
        name: 'Phone',
        icon_url: '',
        type: 'dialer',
        value: '',
        message: '',
      },
      {
        name: 'WhatsApp',
        icon_url: '',
        type: 'whatsapp',
        value: '',
        message: '',
      },
      {
        name: 'Email',
        icon_url: '',
        type: 'email',
        value: '',
        message: '',
      },
    ],
  }),
  show_flash_deal_claim_button: true,
  edge_members_only_text: 'For Edge members only',
  advance_order_campaign_product_text: '',
  advance_order_campaign_cart_text: 'Dispatch by : {date}',
  green_text_candidates: JSON.stringify([
    'flash_deal',
    'flash_deal_activation',
    'boosted_offer',
    'group_deal_offer',
    'esp_offer',
  ]),
  cart_notification_texts: JSON.stringify({
    add_address: {
      title: `${CURRENCY_SYMBOL}{absolute_discount} ({percent_discount}%) discount`,
      description: 'in your bag going away in {hours} hrs {minutes} mins',
      fallbackTitle: 'Your bag is waiting',
      fallbackDescription: "Grab those items before they're gone",
      enableTimerInDescription: true,
    },
    make_payment: {
      title: `${CURRENCY_SYMBOL}{absolute_discount} ({percent_discount}%) discount`,
      description: 'in your bag going away in {hours} hrs {minutes} mins',
      fallbackTitle: 'Your bag is waiting',
      fallbackDescription: "Grab those items before they're gone",
      enableTimerInDescription: true,
    },
    payment_done: {
      title: 'Order confirmed ✨',
      description: "It’s all yours. We'll update the delivery details",
      fallbackTitle: 'Order confirmed ✨',
      fallbackDescription: "Its all yours. We'll update the delivery details",
      enableTimerInDescription: false,
    },
  }),
  notification_tray_content: JSON.stringify({
    trayText: 'Get notified about exclusive coupon drops',
    ctaText: 'Enable',
    trayType: 'notification_status',
    ctaAction: 'open_notification_settings',
    image: {
      uri: `${APP_ASSET_URL}/869/notification.png`,
    },
  }),
  notification_modal_assets: JSON.stringify({
    android: `${APP_ASSET_URL}/861/android_permission_denied_image.png`,
    ios: `${APP_ASSET_URL}/862/ios_permission_denied_image.png`,
  }),
  cart_notification_texts_with_one_timer: JSON.stringify({
    add_address: {
      title: '₹{absolute_discount} ({percent_discount}%) discount',
      description: 'in your bag going away in {duration}',
      fallbackTitle: 'Your bag is waiting',
      fallbackDescription: "Grab those items before they're gone",
      enableTimerInDescription: true,
    },
    make_payment: {
      title: '₹{absolute_discount} ({percent_discount}%) discount',
      description: 'in your bag going away in {duration}',
      fallbackTitle: 'Your bag is waiting',
      fallbackDescription: "Grab those items before they're gone",
      enableTimerInDescription: true,
    },
    payment_done: {
      title: 'Order confirmed ✨',
      description: "It’s all yours. We'll update the delivery details",
      fallbackTitle: 'Order confirmed ✨',
      fallbackDescription: "Its all yours. We'll update the delivery details",
      enableTimerInDescription: false,
    },
  }),
  show_contact_us_card: true,
  announcements_constants_for_cart_tray: JSON.stringify([]),
  invite_from_offers: 'Earn upto ₹300 wallet balance ',
  last_order_date_time_frame: JSON.stringify([
    { minDaysDifference: -9007199254740991, timeFrame: 275 },
    { minDaysDifference: 0, timeFrame: 270 },
    { minDaysDifference: 15, timeFrame: 271 },
    { minDaysDifference: 30, timeFrame: 272 },
    { minDaysDifference: 60, timeFrame: 273 },
    { minDaysDifference: 90, timeFrame: 274 },
  ]),
  install_date_time_frame: JSON.stringify([
    { minDaysDifference: 0, timeFrame: 264 },
    { minDaysDifference: 1, timeFrame: 265 },
    { minDaysDifference: 4, timeFrame: 266 },
    { minDaysDifference: 7, timeFrame: 267 },
    { minDaysDifference: 15, timeFrame: 268 },
    { minDaysDifference: 30, timeFrame: 269 },
  ]),
  premium_device_model_name: JSON.stringify(['SM-G781B', 'Pixel 6a', 'Pixel 7a']),
  premium_device_brand_name: JSON.stringify(['Apple', 'OnePlus', 'Google', 'Nothing', 'Microsoft']),
  premium_device_ram_threshold_limit: '10000',
  fetch_location_timestamp_threshold_hours: '12',
  webview_html_and_style: `<!DOCTYPE html>
  <html>
  <head>
    <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport">
      <style>
        body, html, #height-calculator { margin: 0; padding: 0 } #height-calculator { position: absolute; top: 0; left: 12px; right: 12px; } img {max-width: 100%}
      </style>
    </head>
    <body>
      <div id="height-calculator">
        RICH_CONTENT_HTML_DATA
      </div>
      <script>
          div = document.getElementById("height-calculator");
          function informReactNative() {
            window?.ReactNativeWebView?.postMessage(div.clientHeight);
           }
          window.onload = (event) => {
            setTimeout(informReactNative, 100);
          };
           new ResizeObserver(informReactNative).observe(div)
        </script>
    </body>
  </html>`,
  authorized_invite_friends_modal_data: JSON.stringify({
    heading: 'Spread the Foxy love!',
    subheading: `Congratulations on unlocking an exclusive access of Foxy! Share this privilege with your 10 BFFs and earn up to Rs. 1000 worth of coupons. Invite your friends now!`,
  }),
  invite_center_assets: JSON.stringify({
    request_invite: {
      image_url: 'https://mufoxy.cdn.ekanek.app/media/app_asset/image/23/send_invite.png',
      aspect_ratio: 0.85,
    },
    request_invite_benefits: {
      image_url: 'https://mufoxy.cdn.ekanek.app/media/app_asset/image/11/group_5997.png',
      aspect_ratio: 0.85,
    },
  }),
  custom_injected_javascript: JSON.stringify({
    MIPS: MIPS_CUSTOM_INJECTED_JAVASCRIPT,
    SIZE_CHART: SIZE_CHART_CUSTOM_INJECTED_JAVASCRIPT,
  }),
  routine_tray_item: JSON.stringify({
    active_subscribed_routine: [
      {
        ctaText: 'Check Now',
        trayText: 'Stay Up-to-Date with Your Routine',
        image: {
          uri: 'https://cdn3.foxy.in/media/app_asset/image/1003/rx_selected_icon.png',
        },
        show_routine_tray: true,
      },
    ],
  }),
  a_plus_content_data: JSON.stringify({}),
  shopify_sorting_options: JSON.stringify({
    label: 'Sort',
    type: 'SORT',
    key: 'Sort',
    default_quick_filter_id: 'filter.p.m.custom.sort.price_asc',
    values: [
      {
        count: 0,
        label: 'Recommended',
        id: 'filter.p.m.custom.sort.best_selling',
        input: 'BEST_SELLING',
        selected: true,
        key: 'Sort',
        reverse: false,
      },
      {
        count: 0,
        label: 'Price ▲',
        id: 'filter.p.m.custom.sort.price_asc',
        input: 'PRICE',
        selected: false,
        key: 'Sort',
        reverse: false,
      },
      {
        count: 0,
        label: 'Price ▼',
        id: 'filter.p.m.custom.sort.price_des',
        input: 'PRICE',
        selected: false,
        key: 'Sort',
        reverse: true,
      },
      {
        count: 0,
        label: 'Newest first',
        id: 'filter.p.m.custom.sort.created_at',
        input: 'CREATED',
        selected: false,
        key: 'Sort',
        reverse: true,
      },
    ],
  }),
  dermat_degrees_data: JSON.stringify({
    degrees: [
      'MBBS (Bachelor of Medicine, Bachelor of Surgery)',
      'MD (Doctor of Medicine)',
      'DNB (Diplomate of National Board)',
      'MS (Master of Surgery)',
      'DAMS (Diploma in Aesthetic Medicine and Surgery)',
      'DDLV (Diploma in Dermatology, Venereology, and Leprosy)',
      'DM (Doctorate of Medicine)',
      'MCh (Master of Surgery)',
      'DDV (Diploma in Dermatology, Venereology, and Leprosy)',
      'DVD (Diploma in Venerology and Dermatology)',
      'FCPS (Fellow of College of Physicians and Surgeons)',
      'PhD (Doctor of Philosophy)',
      'MSc (Master of Science in Dermatology)',
      'PG Diploma (Post Graduate Diploma in Dermatology)',
      'DD (Diploma in Dermatology)',
      'Fellowship in Dermatologic Surgery',
      'Fellowship in Aesthetic Medicine',
      'Fellowship in Pediatric Dermatology',
    ],
  }),
  ekstore_credit_footer: JSON.stringify({
    width: getScreenWidth(),
    height: 20,
    image: 'https://cdn3.foxy.in/media/app_asset/image/1109/powered_by_ekstore_image.png',
    show_power_by_ekstore: true,
  }),
  email_address: '',
  return_request_data: JSON.stringify({
    return_initiated_text: `Refund of ${CURRENCY_SYMBOL}{refund_amount} will be initiated once the items reach our warehouse.`,
    cancellation_initiated_text: `Refund of ${CURRENCY_SYMBOL}{refund_amount} will be credited to the original payment instrument used for the order.`,
    return_policies: [
      {
        image: `${APP_ASSET_URL}/1056/order_cancellation.png`,
        policy: 'Orders can be cancelled only until they are shipped',
      },
      { image: `${APP_ASSET_URL}/1054/return_period.png`, policy: 'Items can be returned withing 15 days of delivery' },
      {
        image: `${APP_ASSET_URL}/1057/delivery_fee.png`,
        policy: 'Delivery fee is non-refundable once the order is shipped',
      },
      {
        image: `${APP_ASSET_URL}/1055/return_fee.png`,
        policy: `A pick up fee of ${CURRENCY_SYMBOL}99 will be applied to the refund`,
      },
    ],
  }),
  size_chart: JSON.stringify({
    metrics: ['inches', 'cm'],
    image: 'https://cdn3.foxy.in/media/app_asset/image/1076/sizechart.png',
    sizeChartData: [
      {
        title: `Men's T-Shirts`,
        inchesChart: 'https://cdn3.foxy.in/media/app_asset/image/1079/mens_tshirt_inches.png',
        cmChart: 'https://cdn3.foxy.in/media/app_asset/image/1080/mens_tshirt_cm.png',
      },
      {
        title: `Women's T-Shirts`,
        inchesChart: 'https://cdn3.foxy.in/media/app_asset/image/1081/womens_tshirt_inches.png',
        cmChart: 'https://cdn3.foxy.in/media/app_asset/image/1082/womens_tshirt_cm.png',
      },
      {
        title: `Women's Tank Tops`,
        inchesChart: 'https://cdn3.foxy.in/media/app_asset/image/1087/womens_tank_top_inches.png',
        cmChart: 'https://cdn3.foxy.in/media/app_asset/image/1088/womens_tank_top_cm.png',
      },
      {
        title: `Men's Formal Regular Shirt`,
        inchesChart: 'https://cdn3.foxy.in/media/app_asset/image/1083/mens_formal_regular_shirt_inches.png',
        cmChart: 'https://cdn3.foxy.in/media/app_asset/image/1084/mens_formal_regular_shirt_cm.png',
      },
      {
        title: `Men's Straight Fit Jeans`,
        inchesChart: 'https://cdn3.foxy.in/media/app_asset/image/1091/mens_straight_fit_jeans_inches.png',
        cmChart: 'https://cdn3.foxy.in/media/app_asset/image/1092/mens_straight_fit_jeans_cm.png',
      },
      {
        title: `Women's High Waist Jeans`,
        inchesChart: 'https://cdn3.foxy.in/media/app_asset/image/1089/womens_high_waist_fit_inches.png',
        cmChart: 'https://cdn3.foxy.in/media/app_asset/image/1090/womens_high_waist_fit_cm.png',
      },
      {
        title: `Women's Kurta`,
        inchesChart: 'https://cdn3.foxy.in/media/app_asset/image/1077/women_kurta_inches.png',
        cmChart: 'https://cdn3.foxy.in/media/app_asset/image/1078/women_kurta_cm.png',
      },
      {
        title: `Men’s casual regular fit shirt`,
        inchesChart: 'https://cdn3.foxy.in/media/app_asset/image/1085/mens_casual_regular_fir_shirt_inches.png',
        cmChart: 'https://cdn3.foxy.in/media/app_asset/image/1086/mens_casual_regular_fir_shirt_cms.png',
      },
    ],
  }),
  dermat_onbaord_pages_Header_text: JSON.stringify({
    handleSelection: {
      title: 'Tell Us About Yourself',
      subtitle: 'Let’s get to know you better!',
    },
    saveProgress: {
      title: 'A little bit about you',
      subtitle: 'Choose whatever you like',
    },
    aboutYou: {
      title: 'Tell Us About Yourself',
      subtitle: 'Let’s get to know you better!',
    },
    consultationDetails: {
      title: 'Consultation Details',
      subtitle: 'Help patients book consultations with you!',
    },
    socialProfile: {
      title: 'Build Your Social Profile',
      subtitle: 'Increase your overall reach on FoxyRx!',
    },
    onboardCustomers: {
      title: 'A little bit about you',
      subtitle: 'Choose whatever you like',
    },
    map: {
      title: 'A little bit about you',
      subtitle: 'Choose whatever you like',
    },
  }),
  dermat_invite_patients: JSON.stringify({
    invite_patients_card_content: [
      'Invite your patients to follow you on FoxyRx',
      'Your patients receive a code for Free Minis on Foxy',
      'Ratings from your patients will boost your profile, increasing your visibility and reach',
    ],
    cta_text: 'Go to Your FoxyRx Profile',
    card_cta_text: 'Invite Your Patients',
  }),
  dermat_save_progress: JSON.stringify({
    textList: {
      header: 'Complete the other sections of your profile to',
      title: '1. List your consultation services on FoxyRx',
      subtitle: '2. Boost your FoxyRx profile to reach out to larger audience',
    },
    primary_cta_text: 'Complete your profile',
    secondary_cta_text: 'Save progress for now',
  }),
  location_modal: JSON.stringify({
    locationModalInfo: {
      title: 'Location Permission',
      message: 'Allow location access to set your dermatology clinic location.',
      buttonNeutral: 'Ask Me Later',
      buttonNegative: 'Cancel',
      buttonPositive: 'OK',
    },
    defaultRegion: {
      latitude: 28.6448,
      longitude: 77.216721,
      latitudeDelta: 0.001,
      longitudeDelta: 0.001,
    },
  }),
  share_handle: JSON.stringify({
    messageTemplate: {
      greetingText: 'Your FoxyRx Page is Ready',
      closingText: 'This is Your FoxyRx handle',
    },
  }),
};

export const COUPON_CODE_IMAGE = {
  IMAGE: `${APP_ASSET_URL}/536/coupon.png`,
};
export const NOTIFICATION_REQUEST_MODAL_TYPE = {
  OFFERS_TAB: 'offers_tab',
  PAYOFF_PAGE: 'payoff_page',
  ORDER_SUCCESS: 'order_success',
  PRODUCT_NOT_REVIEWED: 'product_not_reviewed',
  PRODUCT_OUT_OF_STOCK: 'product_out_of_stock',
  RECYCLING_PAGE: 'recycling_page',
  FOLLOW_ARTIST: 'follow_artist',
  PRODUCT_PAGE: 'product_page',
  BRAND_PAGE: 'brand_page',
  SEARCH_PAGE: 'search_page',
  FEED_PAGE: 'feed_page',
  STORE_PAGE: 'store_page',
  WISHLIST_PAGE: 'wishlist_page',
  ME_PAGE: 'me_page',
  ORDER_HISTORY: 'order_history',
  FOXY_EDGE: 'foxy_edge',
  CATEGORY_PAGE: 'category_page',
  CATEGORY_BROWSING: 'category_browsing',
  INVITE_CENTER: 'invite_center',
  ROUTINES: 'routines',
  WALLET: 'wallet',
  PRODUCT_DETAIL_PAGE: 'product_detail_page',
  PRODUCT_WISHLIST: 'product_wishlist',
  ACTIVE_ROUTINE: 'active_routine',
  POST_ROUTINE: 'post_routine',
};

export const TRUECALLER_RAW_ERRORS = {
  1: 'Network Failure',
  2: 'User pressed back',
  3: 'Incorrect Partner Key',
  4: 'User Not Verified on Truecaller',
  5: 'Truecaller App Internal Error',
  13: 'User pressed back while verification in process',
  14: 'User pressed SKIP or USE ANOTHER NUMBER',
  15: 'Truecaller sdk not usable',
};

export const DEFAULT_DEVICE_INFORMATION_OBJECT = {
  deviceModel: null,
  deviceBrand: null,
  deviceName: null,
  deviceDisplayID: null,
  deviceHardware: null,
  deviceChangeListNumber: null,
  deviceManufacturer: null,
  deviceOverallProductName: null,
  deviceUser: null,
  deviceApiLevel: null,
  deviceImei: null,
  deviceImsi: null,
  connectionType: null,
  effectiveConnectionType: null,
  email: null,
  generalDeviceInfo: {},
};

export const UPLOAD_NOTIFICATION_TEXT = {
  IMAGE_UPLOAD_TITLE: 'Uploading your selfie',
  IMAGE_UPLOAD_DESCRIPTION: 'Please wait',
  ANALYSIS_START_TITLE: 'Analysing your selfie',
  ANALYSIS_END_DESCRIPTION: 'Personalized feed coming up shortly',
};

export const BLOCKED_UXCAM_EVENTS = {
  page_load: true,

  list_rendered: true,
  perfect: true,
  selfie_analysis_state: true,
  face_not_detected: true,
  attribute_update: true,
  modal_view: true,
  video_buffering_stop: true,
  app_background: true,
  list_item_click: true,
  prompt_visible: true,
  attribute_detect: true,
  page_load_delay: true,
  content_error: true,
  too_close: true,
  app_foreground: true,
  info_device_memory: true,
  android_sms_listener_unregister: true,
  selfie_prompt_view: true,
  delivery_info_visible: true,
  uac_selfie_score: true,
  zoom_modal_click: true,
  foxy_live_element: true,
  campaign_view: true,
  uac_new_score: true,
  selfie_analysis_retry: true,
  search_open: true,
  selfie_camera_open: true,
  screen_flash_on: true,
  otp_detect: true,
  app_detect: true,
};

//Routes defined in this should be equivalent to routes defined in your AppNavigator, because navigation will act upon these routes.
export const NAVIGATION_BYPASS = {
  enable: true,
  landingPage: 'TabNavigator',
  landingPageForRegisteredUser: 'TabNavigator',
  screenAfterPhoneNumberInput: 'SelfieIntro',
  screenAfterSelfiePrompt: 'TabNavigator',
};

// In secs
export const TIME_RANGE_TO_REPLACE_GUEST_TOKEN = 40;

export const APP_LAUNCH_STATES = {
  organic: 'organic',
  app_launched_with_cart_items: 'app_launched_with_cart_items',
  app_opened_with_deep_link: 'app_opened_with_deep_link',
};
export const APP_STORE_LINK = Config.APP_STORE_LINK;
export const APP_STORE_LINK_WEB = Config.APP_STORE_LINK_WEB;
export const PLAY_STORE_LINK = Config.PLAY_STORE_LINK;
export const UPLOAD_STATUS_TRAY_CONSTANTS = {
  paused: 'paused',
  in_progress: 'in_progress',
  failed: 'failed',
  success: 'success',
};

export const IOS_FALLBACK_URL = `https://apps.apple.com/in/app/foxy-personalised-beauty-app/id${APP_CONSTANTS.IOS.APP_STORE_ID}`;
export const ANDROID_FALLBACK_URL = `https://play.google.com/store/apps/details?id=${APP_CONSTANTS.ANDROID.PACKAGE_NAME}`;
export const REGEX = {
  EMAIL: /@.*\./,
};
export const END_STATES = {
  add_delivery_instructions: {
    heading: 'Thank you ',
    subheading: 'We’ll deliver your order asap!',
  },

  update_shipment_status: {
    heading: 'Thank you!',
    subheading: 'Allow us some time to get back to you.',
  },
  upload_video: {
    heading: 'Your video uploaded successfully ✨',
    subheading: 'Once approved, we’ll send you your gift via Whatsapp. 😊',
  },
  get_detailed_status_from_brand: {
    heading: 'Thank you',
    subheading:
      'Our operations team will get in touch with you via email on priority',
  },
};
export const SALON_QR_SCAN_ALIGNMENT = {
  x: 233,
  y: 145,
};
export const SALON_QR_SCAN_OVERLAY = {
  x: 291,
  y: 444,
};

export const USN_QR_CODE_SCAN_OVERLAY = {
  x: 220,
  y: 220,
};

export const USN_BARCODE_CODE_SCAN_OVERLAY = {
  x: 300,
  y: 150,
};


export const SALON_SCAN_OVERLAY_HEIGHT = 470;

export const STATUS_BAR_HEIGHT = {
  ANDROID: 56,
  IOS: 44,
};
export const SALON_IN_PROGRESS_TIME_LABELS = { m: null, s: null };
export const SALON_IN_PROGRESS_TIME_FORMAT = ['M', 'S'];
export const SALON_LOCATION_PERMISSION_MODAL_DATA = {
  heading: 'Permission Required',
  subheading:
    'Change access permission inside Settings to allow location access',
};
export const SALON_CAMERA_PERMISSION_MODAL_DATA = {
  heading: 'Permission Required',
  subheading: 'Change access permission inside Settings to allow camera access',
};
export const API_ERRORS = {
  SERVER_ERROR: 'server_error',
  CONNECTION_ERROR: 'connection_error',
};

export const GENDER_IDS = {
  male: 62,
  female: 63,
};

export const STATUSBAR_HEIGHT = {
  IOS: 44,
  Android: 56,
};

export const PAGINATION_API_LIMIT = 5;
export const CATEGORY_BROWSING_PAGINATION_API_LIMIT = 1;

export const NOTIFICATION_IDENTIFIERS = {
  CART_REMINDER: '10002',
  PAYMENT_SUCCESS: '10009',
  PAYMENT_FAILED: '10002',
  PAYMENT_IN_PROGESS: '10002',
  PROFILE_PIC_UPLOAD: '10005',
  FRIDAY_DROP_IDENTIFIER: '10006',
  SELFIE_ANALYSIS: '10007',
  ONBOARDING_NOTIFICATION: '10008',
};

export const NOTIFICATION_TYPE = {
  CART_REMINDER: 'cart_reminder_notification',
  PAYMENT_SUCCESS: 'payment_success_notification',
  PAYMENT_FAILED: 'payment_failed_notification',
  PAYMENT_IN_PROGESS: 'payment_in_progress_notification',
  PROFILE_PIC_UPLOAD: 'profile_pic_upload_notification',
  SUBSCRIPTION_SUCCESS_OPEN_SELFIE:
    'subscription_success_open_routine_notification',
  SUBSCRIPTION_SUCCESS_GOTO_ROUTINE: 'subscription_success_goto_routine',
  ONBOARDING_NOTIFICATION: 'onboarding_skip', // using onboarding_skip here as it's being used from beginning, chaning it will break stats
};

export const NOTIFICATION_ACTIONS = {
  REMIND_IN_24_HOURS: { action: 'DELETE', cta: 'REMIND IN 24 HOURS' },
  CONTINUE: { cta: 'CONTINUE' },
  CLICK_SELFIE: { cta: 'Click selfie' },
  VIEW_ORDER_DETAILS: { cta: 'VIEW ORDER DETAILS' },
  DISMISS: { action: 'DELETE', cta: 'DISMISS' },
  LATER: { action: 'DELETE', cta: 'LATER' },
  TRY_AGAIN: { cta: 'TRY AGAIN' },
  CHECK_STATUS: { cta: 'CHECK STATUS', action: 'continue' },
};

export const COUNTDOWN = {
  timeToShowHMS: ['H', 'M', 'S'],
  emptyLabels: { m: null, s: null },
};

export const TRACKING_PARAMS = {
  INTERNAL: {
    utm_source: 'internal',
    utm_medium: 'internal',
  },
  SHARE: {
    GENERIC: {
      utm_source: 'share',
      utm_medium: 'share',
      utm_campaign: 'app',
    },
    BRAND: {
      utm_source: 'share',
      utm_medium: 'share',
      utm_campaign: 'brand',
    },

    VIDEO: {
      utm_source: 'share',
      utm_medium: 'share',
      utm_campaign: 'video',
    },

    PRODUCT: {
      utm_source: 'share',
      utm_medium: 'share',
      utm_campaign: 'product',
    },

    REFERRAL: {
      utm_source: 'referral',
      utm_medium: 'referral',
      utm_campaign: 'referral',
    },

    RETAIL: {
      utm_source: 'share',
      utm_medium: 'share',
      utm_campaign: 'retail_store',
    },
    CATEGORY: {
      utm_source: 'share',
      utm_medium: 'share',
      utm_campaign: 'category',
    },
  },

  NOTIFICATION: {
    PAYMENT_FAILURE: {
      utm_source: 'notification',
      utm_medium: 'local',
      utm_campaign: 'payment_failure',
    },

    PAYMENT_SUCCESS: {
      utm_source: 'notification',
      utm_medium: 'local',
      utm_campaign: 'payment_success',
    },

    CART: {
      utm_source: 'notification',
      utm_medium: 'local',
      utm_campaign: 'cart',
    },
  },
};
export const TAKE_SELFIE_CARD = {
  selfie_taken_title: 'Your selfie status: Updated✔️',
  selfie_taken_body: 'Now get a FREE 14-day trial invite to explore Foxy Edge',
  selfie_not_taken_title: 'Take Selfie',
  selfie_not_taken_body:
    'and get a FREE 14-day trial invite to explore Foxy Edge',
};

export const EDGE_MODAL_TYPES = {
  UPGRADE_MODAL: 'upgrade_modal',
  JOIN_EDGE_MODAL: 'join_edge_modal',
};

export const TESTIMONIAL_COLOR_BUCKET = ['#FFF6D9', '#EEFCB6', '#D9E0FC'];
export const ROUTINE_ACTIVITY_TYPES = {
  UPLOAD: 'upload',
  POLL: 'poll',
  ACTION: 'action',
  LINK: 'link',
  VIDEO: 'video',
  LIVE_STREAM: 'live_stream',
};

export const ROUTINE_ACTIVITY_ACTIONS = {
  upload: 'upload',
  action: 'mark_as_done',
  link: 'click',
  poll: 'click',
  video: 'play',
  live_stream: 'live_stream',
};

export const ZOOM_ANIMATION = {
  MAXIMUM_RENDER_COUNT: 1,
  MINIMUM_DAYS_OF_INTERVAL: 10,
};

export const { CUSTOMIZED_SKU_FORM_PAGE, ORDER_PAGE_TYPE } = Config;

export const ReturnKeyTypes = {
  NEXT: 'next',
  DONE: 'done',
};

export const CURRENCY = {
  INR: 'INR',
};

export const EVENT_BUS_ACTIONS = {
  SIDEBAR_SCROLL_TO_TOP: 'SIDEBAR_SCROLL_TO_TOP',
  CATEGORY_BROWSING_SCROLL_TO_TOP: 'CATEGORY_BROWSING_SCROLL_TO_TOP',
  ITEM_CLICK: 'item_click',
  ITEM_ACTION: 'item_action',
};

export const CART_NOTIFICATION_STATES = {
  addAddress: 'add_address',
  makePayment: 'make_payment',
  paymentDone: 'payment_done',
};

export const CART_NOTIFICATION_DEEPLINKS = {
  add_address: CART_NOTIFICATION_ADD_ADDRESS_URL,
  make_payment: CART_NOTIFICATION_MAKE_PAYMENT_URL,
  payment_done: CART_NOTIFICATION_PAYMENT_DONE_URL,
};

export const PERMISSION_STATUS = {
  not_asked: 'not_asked',
  granted: 'allowed',
  denied: 'denied',
  dismissed: 'dismissed',
  never_ask_again: 'never_ask_again',
  undetermined: 'not_asked',
  authorized: 'allowed',
  provisional: 'provisional',
};

export const PERMISSION_CONSTANTS = {
  notification: 'notification',
  liveActivity: 'live_activity',
  storage: 'storage',
};

export const EXTERNAL_LINKS = {
  appleStore: Config.APP_STORE_LINK,
  googlePaly: Config.PLAY_STORE_LINK,
  instagram: Config.INSTAGRAM_LINK,
  youtube: Config.YOUTUBE_LINK,
  fb: Config.FB_LINK,
  twitter: Config.TWITTER_LINK,
  mailTo: `mailto:${Config.CONTACT_MAIL}`,
  whatsapp: Config.WHATSAPP_LINK,
  discord: Config.DISCORD_LINK,
};

export const APP_DOWNLOAD_LINK = isWebIOS()
  ? EXTERNAL_LINKS.appleStore
  : EXTERNAL_LINKS.googlePaly;
export const EMPTY_OBJECT = {};
export const EMPTY_ARRAY = [];
export const EMPTY_STRING = '';
export const DESKTOP_HIDDEN_TABS = [
  'Search',
  'Product',
  'OfferDetail',
  'MoreItems',
  'Category',
  'Brand',
  'Ingredients',
  'PostReview',
  'AllReviews',
  'TodayDeals',
  'ArtistLists',
  'ProductLists',
  'FullScreenProductDescription',
  'ShopOnlineList',
  'ShopOnlineBrand',
  'ShopOnlineCategory',
  'ShopOnlineBrandCategory',
  'PreRoutineDetails',
  'DuringRoutineDetails',
  'RoutineLiveStream',
];

export const FOXY_URLS = {
  dynamicAppLink: Config.DYNAMIC_APP_LINK,
  dynamicOnelinkUrl: Config.DYNAMIC_ONE_LINK_URL,
  playStore: Config.PLAY_STORE_LINK,
  appStore: Config.APP_STORE_LINK,
  takeASelfieFeature: Config.TAKE_A_SLEFIE_FEATURE_LINK,
  takeASelfieFullBanner: Config.TAKE_A_SELFIE_FULL_BANNER_LINK,
  personalizedVerticalUserAttributes: Config.PERSONALIZED_VERTICAL_USER_ATTRIBUTES_LINK,
  foxyEdgeLink: Config.FOXY_EDGE_LINK,
  appInstallPage: {
    product: Config.PRODUCT_APP_INSTALL_LINK,
    offer: Config.OFFER_APP_INSTALL_LINK,
    category: Config.CATEGORY_APP_INSTALL_LINK,
    list: Config.LIST_APP_INSTALL_LINK,
    brand: Config.BRAND_APP_INSTALL_LINK,
    tag: Config.TAG_APP_INSTALL_LINK,
    store_or_home: Config.STORE_OR_HOME_APP_INSTALL_LINK,
    offers: Config.OFFERS_APP_INSTALL_LINK,
    cart: Config.CART_APP_INSTALL_LINK,
    payment_page: Config.PAYMENT_PAGE_APP_INSTALL_LINK,
    inStock1: Config.IN_STOCK_1_APP_INSTALL_LINK,
  },
  appOnlyOffers: Config.APP_ONLY_OFFERS_LINK,
  instagram: Config.INSTAGRAM_LINK,
};

export const STORAGE_MODAL_INFO = {
  take_selfie: {
    heading: 'Upload your photos and videos',
    subHeading:
      'Strike a pose! Your app profile is calling for your fabulous face.',
  },
  faq_section: {
    heading: 'Upload your photos and videos',
    subHeading: 'Show us the facts: Add an image to support your complaint.',
  },
  upload_unboxing_video: {
    heading: 'Upload your photos and videos',
    subHeading:
      'Ready to share your unboxing experience? Upload your video here!',
  },
  post_review: {
    heading: 'Upload your photos and videos',
    subHeading: '',
  },
  routine: {
    heading: 'Upload your photos and videos',
    subHeading: 'Share the Glow! Add your selfie and inspire others- routine',
  },
};

/**
 * This separator is getting used with shopify navigation library.
 * this is to format the source<sep>listItem<sep>Item<sep>destination_screen
 */
export const SEP = '---';

export const AddressBeforeLoginAppConfig = AppConfig.getBooleanValue(
  Config.ASK_ADDRESS_BEFORE_LOGIN,
);

export const DisableVarianListScrollability = AppConfig.getBooleanValue(
  Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
);

export const EnableMandatoryLogin = AppConfig.getBooleanValue(
  Config.ENABLE_MANDATORY_LOGIN,
);

export const ShowProductVariantLabelOnCartItem = AppConfig.getBooleanValue(
  Config.SHOW_PRODUCT_VARIANT_LABEL_ON_CART_ITEM,
);

export const SyncOrdersOnAppOpen = AppConfig.getBooleanValue(Config.SYNC_ORDER_ON_APP_OPEN);

export const ShowAplusContentWebview = AppConfig.getBooleanValue(
  Config.SHOW_A_PLUS_CONTENT_WEBVIEW,
);

export const WishlistAsBottomTab = Config.TABS.includes('Wishlist');

export const IST_OFFSET = 5.5 * 60 * 60000;

export const GRADIENT_CONFIGURATIONS = {
  // Vertical gradient (top to bottom)
  VERTICAL: {
    start: { x: 0, y: 0 },
    end: { x: 0, y: 1 },
  },

  // Horizontal gradient (left to right)
  HORIZONTAL: {
    start: { x: 0, y: 0 },
    end: { x: 1, y: 0 },
  },

  // Diagonal gradient (bottom-left to top-right)
  DIAGONAL_BL_TR: {
    start: { x: 0, y: 0 },
    end: { x: 1, y: 1 },
  },

  // Diagonal gradient (top-left to bottom-right)
  DIAGONAL_TL_BR: {
    start: { x: 0, y: 1 },
    end: { x: 1, y: 0 },
  },
};

export const EnableShopifyDirect = AppConfig.shopifyDirectEnabled;

export const ShowDermatProfileCard = AppConfig.getBooleanValue(
  Config.SHOW_DERMAT_PROFILE_CARD,
);
