import React, { Component } from 'react';
import { StaticNavigationHeader } from '../../components/shared';
import { View, StyleSheet, BackHandler } from 'react-native';
import BirthDateTextField from './BirthDateTextField';
import colors from '../../theme/Colors';
import BirthDayInfoCard from './BirthDayInfoCard';
import Utility from '../../utils/Utility';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { editProfile } from '../../actions/ActionTypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import Toast from 'react-native-easy-toast';
import withNavigation from '../../utils/WithNavigation';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';

class DateOfBirth extends Component {
  constructor(props) {
    super(props);
    const { date_of_birth, navigation } = props;
    this.state = {
      birthDate: date_of_birth || '',
      isBirthDateEmpty: false,
    };
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.goBack,
      );
    }
    const { route } = props;
    this.previousScreen = route.params?.previousScreen;
    if (Utility.isBlank(this.previousScreen)) {
      this.previousScreen = route.params?.params?.previousScreen;
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
  }

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  addBirthDate = (date) => {
    this.setState({
      birthDate: date,
    });
  };

  showToastAndGoBack = (message) => {
    const { navigation } = this.props;
    this.toast.show(message);
    setTimeout(() => {
      navigation.goBack();
    }, 1000);
  };

  onSubmit = () => {
    const { editProfile, navigation, date_of_birth = '', route } = this.props;
    const { birthDate } = this.state;
    if (date_of_birth !== '') {
      this.showToastAndGoBack('You have already added your Birth Date.');
      return;
    }
    if (birthDate !== '') {
      const user = { date_of_birth: birthDate };
      editProfile(user, {}, (callbackData = {}, success) => {
        if (success) {
          AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
            [EventParameterKey.TYPE]: 'add_dob',
          });
          if (
            this.previousScreen === SCREEN_CONSTANTS.LOYALTY_PLANS ||
            this.previousScreen === SCREEN_CONSTANTS.MEMBER_LANDING_PAGE
          ) {
            navigation.goBack();
            return;
          }
          navigateToScreen({
            navigation,
            type: 'replace',
            screen: 'LoyaltyPlans',
            params: {
              previousScreen: SCREEN_CONSTANTS.DATE_OF_BIRTH,
            },
          });
        }
        const { errors: { date_of_birth = [] } = {} } = callbackData;
        if (Utility.isPresent(date_of_birth)) {
          this.showToastAndGoBack(date_of_birth[0]);
        }
      });
      return;
    }
    this.setState({
      isBirthDateEmpty: true,
    });
  };

  toastRef = (ref) => {
    this.toast = ref;
  };

  render() {
    const { birthDate, isBirthDateEmpty } = this.state;
    const { date_of_birth = '', navigation } = this.props;
    return (
      <>
        <StaticNavigationHeader title='We’d like to know you better' />
        <View style={styles.container}>
          <BirthDayInfoCard />
          <BirthDateTextField
            date={birthDate}
            onSubmit={this.addBirthDate}
            error={isBirthDateEmpty ? 'Birth Date cannot be blank' : null}
            openOnLoad={date_of_birth === ''}
            navigation={navigation}
          />
        </View>
        <FoxyShadowButton
          width={Utility.getScreenWidth() - 24}
          title={"Let's Go"}
          onPress={this.onSubmit}
          style={styles.buttonLayout}
          firstColor={colors.black}
          secondColor={colors.black}
          underlayColor={colors.subtitle}
        />
        <Toast style={styles.toast} ref={this.toastRef} />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        editProfile,
      },
      dispatch,
    ),
  };
}

const mapStateToProps = (state) => ({
  date_of_birth: state.UserAccountInfo.profile.date_of_birth,
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(DateOfBirth),
);

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    paddingVertical: 10,
    backgroundColor: colors.white,
    flex: 1,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  buttonLayout: {
    marginTop: 12,
    position: 'absolute',
    bottom: Utility.isIOS ? 20 : 8,
    alignSelf: 'center',
  },
  toast: {
    position: 'absolute',
    bottom: 20,
    maxWidth: Utility.getScreenWidth() - 32,
  },
});
