import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, Image, Platform } from 'react-native';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import FastImageView from '../../components/FastImageView';
import images from '../../theme/Images';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import withNavigation from '../../utils/WithNavigation';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';

class FoxyEdgeHeader extends PureComponent {
  onProfilePress = () => {
    const { navigation, selfie_image_url = '' } = this.props;
    if (Utility.isBlank(selfie_image_url)) {
      navigation.navigate('TakeSelfie');
      return;
    }
    navigation.navigate('ConsumerProfile');
  };

  render() {
    const { selfie_image_url = '' } = this.props;
    const image = Utility.isPresent(selfie_image_url)
      ? selfie_image_url
      : images.edge.placeholder;
    return (
      <>
        <View style={styles.edgeHeaderContainer}>
          <View style={styles.introContainer}>
            <View style={styles.userImageContainer}>
              <TouchableOpacity onPress={this.onProfilePress}>
                <FastImageView source={image} style={styles.userImage} />
              </TouchableOpacity>
            </View>
            <View style={styles.headerContainer}>
              <Text style={styles.logoText}>Welcome to</Text>
              <FastImageView
                resizeMode='contain'
                source={images.edge.logo}
                style={styles.logo}
              />
            </View>
          </View>
          {/* <View style={styles.introCardFooterContainer}>
            <View style={styles.bottomCurveLayer1} />
            <View style={styles.bottomCurveLayer2} />
          </View> */}
        </View>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  membership_cohort: state.todayDeals.membership_cohort,
});

export default withNavigation(connect(mapStateToProps, null)(FoxyEdgeHeader));

const styles = StyleSheet.create({
  edgeHeaderContainer: {
    backgroundColor: isDesktop() ? colors.background : colors.white,
  },
  introContainer: {
    width: Utility.getScreenWidth(),
    backgroundColor: colors.background,
    borderBottomRightRadius: 50,
    paddingHorizontal: 12,
    paddingBottom: 16,
    alignSelf: 'center',
  },
  logo: {
    width: 92 * 1.15,
    height: 44 * 1.15,
    marginTop: 8,
  },
  logoText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.edge_logo_grey,
    marginTop: 8,
  },
  headerContainer: {
    backgroundColor: colors.background,
    borderBottomRightRadius: 50,
    paddingLeft: 14,
    paddingBottom: 12,
  },
  bottomCurveLayer1: {
    height: 50,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.background,
  },
  bottomCurveLayer2: {
    height: 50,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    marginTop: -50,
    borderTopLeftRadius: 200,
  },
  introCardFooterContainer: { marginBottom: -14, backgroundColor: 'red' },
  userImageContainer: {
    position: 'absolute',
    right: 26,
    bottom: 28,
    zIndex: 1,
  },
  userImage: {
    height: 61,
    width: 61,
    borderRadius: 28,
    ...Platform.select({
      web: {},
      default: { resizeMode: 'contain' },
    }),
  },
});
