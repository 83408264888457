import { Dimensions, Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';
import Config from '../../../libraries/ReactNativeConfig';
import colors, { Theme } from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import ScalingUtility from '../../../utils/ScalingUtility';
import Utility from '../../../utils/Utility';
import { isWeb } from '../../../utils/BooleanUtility';

let { height, width } = Dimensions.get('window');
if (height < width) {
  let temp = height;
  height = width;
  width = temp;
}

const resendViaWhatsapp = Config.OTP_RESEND_MESSAGE === 'whatsapp';

const actualHeight = resendViaWhatsapp ? 300 : 270;
const paddingTop = resendViaWhatsapp ? 24 : 12;
const warningContainerHeight = resendViaWhatsapp ? 10.43 : 9;
const modalHeight = ScalingUtility.moderateScale(actualHeight);
const modalInsetHeight = modalHeight - (24.0 + 20.0);
const modalInsetWidth = width - 24.0 * 2;
const contentHeight = modalInsetHeight / 1.6;

const otpContentHeightVar = resendViaWhatsapp ? 2.42 : 2;

export const { ids, styles } = StyleSheet.create({
  modal: {
    height: Utility.getScreenHeight(),
    width: '100%',
  },

  safeAreaView: {
    backgroundColor: colors.translucent,
    '@media (min-width: 768px)': {
      height: Utility.getScreenHeight(),
    },
  },

  keyboardAvoidContainerStyle: {
    ...Platform.select({
      web: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        flex: 1,
      },
      ios: {
        paddingBottom: 12,
      },
      android: {
        paddingBottom: 0,
      },
    }),
    '@media (min-width: 768px)': {
      position: 'static',
      width: null,
      bottom: null,
      flex: null,
    },
  },

  title: {
    fontSize: size.h1,
    color: Theme.light.textColor,
    flex: 1,
  },

  content: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    paddingHorizontal: 24,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingTop: 24,
    '@media (min-width: 800px)': {
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      height: 320,
    },
  },

  container: {
    alignItems: 'center',
    flexDirection: 'column',
  },

  contactInfoContainer: {
    flexDirection: 'row',
  },

  contactNumber: {
    color: colors.subtitle,
    alignSelf: 'center',
    fontSize: size.h3,
  },

  editContactText: {
    fontSize: size.h3,
    color: Theme.light.highlight,
    marginLeft: 8.0,
  },

  resendOtpContainer: {
    // height: contentHeight / 3.47,

    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },

  resend: {
    fontSize: size.h3,
    // width: modalInsetWidth / 6.63,
    textAlign: 'center',
  },

  notGettingCodeText: {
    color: colors.subtitle,
    fontSize: size.h3,
  },

  resendTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  otpContainer: {
    height: contentHeight / otpContentHeightVar,
    justifyContent: 'space-between',
    '@media (min-width: 800px)': {
      width: '100%',
      paddingTop: 30,
    },
    ...Platform.select({
      web: {
        width: '100%',
        paddingTop: 10,
      },
      default: {},
    })
  },

  cellStyle: {
    fontSize: 22,
    height: 53,
    // width: 50,
    borderRadius: 4,
    width: contentHeight / 3.2,
  },

  paytmCellStyle: {
    fontSize: 22,
    height: 53,
    borderRadius: 4,
    width: contentHeight / 4,
  },

  cellStyleGreen: {
    fontSize: 22,
    height: 53,
    width: contentHeight / 3.2,
    borderWidth: 2,
    borderRadius: 4,
    borderColor: colors.foxyGreen,
  },
  cellStyleRed: {
    fontSize: 22,
    height: 53,
    width: contentHeight / 3.2,
    borderWidth: 2,
    borderRadius: 4,
    borderColor: colors.foxyRed,
  },

  cellHeight: {
    height: contentHeight / 3.71,
  },

  warning: {
    color: Theme.light.errorColor,
    alignSelf: 'flex-start',
    lineHeight: 12,
  },

  warningPlaceholder: {
    height: 12,
  },

  separator: {
    height: 20.0,
    width: 2,
    backgroundColor: Theme.light.borderColor,
    marginLeft: 15.0,
    marginRight: 15.0,
  },

  whatsappOptionContainer: {
    flexDirection: 'row',
    width: 200,
    marginTop: 10,
    justifyContent: 'space-between',
  },

  whatsappIcon: {
    height: 16,
    width: 16,
  },

  titleRow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 12,
  },

  skipButton: {
    fontFamily: 'Roboto-Regular',
    marginTop: 5,
    fontSize: size.h3,
    color: colors.foxyBlue,
  },
  resendWhatsappTextStyle: {
    fontSize: size.h3,
    color: colors.subtitle,
    marginLeft: 5,
    paddingBottom: 16,
  },
  disableResendWhatsappTextStyle: {
    fontSize: size.h3,
    color: Theme.light.verifiedColor,
    marginLeft: 5,
    paddingBottom: 16,
  },
  buttonStyle: {
    marginBottom: 14,
    paddingTop: 10,
    '@media (min-width: 800px)': {
      width: '100%',
    },
  },
  touchableContainer: { height: '100%', width: '100%' },
  wrapperContainer: {
    '@media (min-width: 800px)': {
      width: '100%',
    },
  },
});
