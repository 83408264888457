import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import FastImageView from '../../components/FastImageView';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import Utility from '../../utils/Utility';
import { WEB_URL } from '../../config/Constants';
import withNavigation from '../../utils/WithNavigation';
import { navigateToScreen } from '../../utils/NavigationUtility';

class ContinueWhereYouLeftTray extends PureComponent {
  onContinuePress = () => {
    const {
      navigation,
      screenName,
      continueSlug = '',
      continueScreenType = '',
      onPressContinueWhereYouLeft = {},
    } = this.props;
    if (Utility.isBlank(continueSlug)) {
      return;
    }
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.SOURCE]: screenName,
      [EventParameterKey.ITEM_TYPE]:
        EventParameterValue.ITEM_TYPE.CONTINUE_TRAY,
      continue_screen_type: continueScreenType,
      slug: continueSlug,
    });
    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_CLICK, {
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.ITEM_TYPE]:
        EventParameterValue.ITEM_TYPE.CONTINUE_TRAY,
    });
    if (continueScreenType === 'product') {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: 'Product',
        params: {
          slug: continueSlug,
          display: 'screen',
        },
      });
    } else if (continueScreenType === 'cart') {
      navigation.push('Cart', {
        slug: continueSlug,
        display: 'screen',
      });
    } else if (continueScreenType === 'Routine') {
      navigation.push('PreRoutineDetails', {
        slug: continueSlug,
        display: 'screen',
      });
    }
    onPressContinueWhereYouLeft();
  };

  render() {
    const { continueImage = '' } = this.props;
    return (
      <View style={styles.container}>
        <TouchableOpacity
          style={styles.textContainer}
          onPress={this.onContinuePress}
        >
          <View style={styles.textContainer}>
            <FastImageView
              source={continueImage}
              style={styles.continueImage}
            />
            <Text style={styles.body}>Continue where you left</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={this.onContinuePress}>
          <FastImageView
            source={images.chevronRightBlack}
            style={styles.chevron}
          />
        </TouchableOpacity>
      </View>
    );
  }
}

export default withNavigation(ContinueWhereYouLeftTray);

const styles = StyleSheet.create({
  container: {
    width: Utility.getScreenWidth() - 24,
    marginHorizontal: 12,
    paddingRight: 12,
    paddingLeft: 4,
    borderRadius: 4,
    flexDirection: 'row',
    backgroundColor: colors.edge_upgrade.bright_yelllow,
    position: 'absolute',
    zIndex: 1,
    bottom: 8,
    alignItems: 'center',
    height: 44,
  },
  continueImage: {
    width: 36,
    height: 36,
    borderRadius: 18,
    borderWidth: 1.5,
    borderColor: colors.edge_upgrade.bright_yelllow_border,
    resizeMode: 'contain',
    marginRight: 8,
  },
  chevron: {
    width: 14,
    height: 14,
    // position: 'absolute',
    resizeMode: 'contain',
    right: 8,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  body: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    flex: 1,
  },
  buttonText: {
    color: colors.edge_upgrade.blue,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
  },
});
