import React, { Component } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  StyleSheet,
  StatusBar,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FoxyClub from '../AltBrands/FoxyClub';
import NonMemberLandingPage from './NonMemberLandingPage';
import { getUserTodayDeals } from '../../actions/ActionTypes';
import Utility from '../../utils/Utility';
import { AnalyticsManager, EventType } from '../../analytics';
import { ScreenPlaceholder } from '../../components/shared';
import { BagIcon } from '../../components/header';
import { sharedStyles } from '../../components/shared/styles';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import Share from '../../libraries/ReactNativeShare';
import RemoteConfig from '../../utils/RemoteConfig';
import withNavigation from '../../utils/WithNavigation';
import { REMOTE_CONFIG_KEYS, WWW_WEB_URL } from '../../config/Constants';
import FastImageView from '../../components/FastImageView';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';

class TheEdge extends Component {
  constructor(props) {
    super(props);
    this.navigationHeaderHeight = Utility.isIOS() ? 82 + Utility.topInset : 82;
    this.remConStrings = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.invite_center_strings),
    );
    const { rich_message_non_member = '', rich_message_member = '' } =
      this.remConStrings;
    const { membership_cohort, navigation } = this.props;
    this.shareMessage =
      membership_cohort === 'member'
        ? rich_message_member
        : rich_message_non_member;
    this.shareUrl = `${WWW_WEB_URL}/foxy_edge`;
    Utility.setStatusBarColor(colors.background);
    this.tabPressListener = navigation.addListener('tabPress', () => {
      if (this.isCurrentScreen) {
        this.scrollPageToTop();
      }
    });

    this.isCurrentScreen = false;
    this.scrollViewRef = null;
    this.navigationSource = getNavigationSource(this, this.props);
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.unsubscribeFocus = navigation.addListener('focus', () => {
      this.onWillFocus();
    });
    this.unsubscribeBlur = navigation.addListener('blur', () => {
      this.onWillBlur();
    });
  }

  componentWillUnmount() {
    const { navigation } = this.props;
    // Unsubscribe from the 'focus' event
    if (this.unsubscribeFocus) {
      navigation.removeListener(this.unsubscribeFocus);
    }

    // Unsubscribe from the 'blur' event
    if (this.unsubscribeBlur) {
      navigation.removeListener(this.unsubscribeBlur);
    }
  }

  onWillFocus = () => {
    const { getUserTodayDeals } = this.props;
    Utility.setStatusBarColor(colors.background);
    getUserTodayDeals(() => {
      const { membership_cohort = '', membership_id = '' } = this.props;
      AnalyticsManager.logEvent(EventType.discoveryEvents.TAB_VIEW, {
        tab: `foxy_edge_${membership_cohort}`,
        membership_id,
      });
      AnalyticsManager.setCurrentScreen(`foxy_edge_${membership_cohort}`);
    });

    this.isCurrentScreen = true;
  };

  onWillBlur = () => {
    Utility.setStatusBarColor(colors.background);
    this.isCurrentScreen = false;
  };

  scrollPageToTop = () => {
    if (this.isCurrentScreen && this.scrollViewRef) {
      this.scrollViewRef.scrollTo(0);
    }
  };

  onSharePress = () => {
    let title = 'Edge';

    if (Utility.isBlank(this.shareUrl)) {
      this.toast.show('An unexpected error occurred');
      return;
    }
    const getDynamicLink = DynamicLinkManager.getShortenUrl(this.shareUrl, {
      title,
      description: '',
    });
    getDynamicLink
      .then((link) => {
        const options = {
          title: 'Foxy',
          message: this.shareMessage,
          url: link,
        };

        Share.open(options)
          .then((res) => {})
          .catch((err) => {});
      })
      .catch((error) => {
        const options = {
          title: 'Foxy',
          message: this.shareMessage,
          url: this.shareUrl,
        };

        Share.open(options)
          .then((res) => {})
          .catch((err) => {});
      });
  };

  navigationToCart = () => {
    const { navigation } = this.props;
    navigation.navigate(this.navigationSource, 'Cart');
  };

  onSearchIconTap = () => {
    const { navigation } = this.props;
    navigation.navigate(this.navigationSource, 'Search');
  };

  onInfoTap = () => {
    const { navigation } = this.props;
    navigation.push(this.navigationSource, 'EdgeInfo');
  };

  setScrollViewRef = (ref) => {
    this.scrollViewRef = ref;
  };

  topButtonsContainer = () => {
    const buttonsHeaderContainer = [
      styles.buttonsHeaderContainer,
      {
        height: this.navigationHeaderHeight,
        paddingTop: Utility.isIOS() ? Utility.topInset : 0,
        paddingBottom: 12,
      },
    ];
    return (
      <View style={buttonsHeaderContainer}>
        <DebouncedTouchableOpacity
          onPress={this.onInfoTap}
          style={styles.foxyHeaderContainer}
          hitSlop={Utility.getHitSlop()}
        >
          <FastImageView
            resizeMode='contain'
            source={images.edge.logo}
            style={styles.foxyEdgeLogo}
          />
        </DebouncedTouchableOpacity>
        <DebouncedTouchableOpacity
          onPress={this.onInfoTap}
          style={styles.shareIconContainer}
          hitSlop={Utility.getHitSlop()}
        >
          <Image
            source={images.ic_info}
            style={sharedStyles.navbarInfoIconStyle}
          />
        </DebouncedTouchableOpacity>
        {!isWeb() && (
          <>
            <View style={styles.shareIconContainer}>
              <BagIcon handleTap={this.navigationToCart} showCart />
            </View>
            <DebouncedTouchableOpacity
              onPress={this.onSearchIconTap}
              style={styles.shareIconContainer}
              hitSlop={Utility.getHitSlop()}
            >
              <Image
                source={images.ic_search_product}
                style={sharedStyles.navbarShareIconStyle}
              />
            </DebouncedTouchableOpacity>
            <DebouncedTouchableOpacity
              onPress={this.onSharePress}
              style={styles.shareIconContainer}
              hitSlop={Utility.getHitSlop()}
            >
              <Image
                source={images.ic_share_product}
                style={sharedStyles.navbarShareIconStyle}
              />
            </DebouncedTouchableOpacity>
          </>
        )}
      </View>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <this.topButtonsContainer />
        <FoxyClub scrollViewRef={this.setScrollViewRef} />
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  membership_cohort: state.todayDeals.membership_cohort,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getUserTodayDeals,
    },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps)(TheEdge),
);

export const TheEdgeHeaderOptions = {
  headerShadowVisible: false,
};

const styles = StyleSheet.create({
  shareIconContainer: {
    width: 32,
    height: 32,
    marginLeft: 8,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  buttonsHeaderContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: colors.background,
    width: isDesktop() ? getScreenWidth() : '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: Utility.isIOS() ? 8 : 0,
    paddingRight: 12,
    borderBottomRightRadius: isDesktop() ? 0 : 40,
  },
  foxyHeaderContainer: {
    width: 98,
    height: 52,
    marginLeft: isDesktop() ? 0 : 8,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background,
    position: 'absolute',
    left: isDesktop() ? 0 : 16,
    top: Utility.isIOS() ? Utility.topInset + 12 : 14,
  },
  foxyEdgeLogo: { resizeMode: 'contain', width: 98, height: 52 },
  container: { flex: 1, backgroundColor: colors.background },
});
