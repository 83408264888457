import { getStateFromPath } from '@react-navigation/native';
import { isDesktop } from '../utils/BooleanUtility';

const linking = {
  config: {
    /* configuration for matching screens with paths */
    screens: {
      Influencer: 'artist/:bioHandle',
      ScreenNavigator: '',
      TabNavigator: {
        screens: {
          Feed: 'feed',
          Store: 'shop',
          ForYou: 'for-you',
          Reviews: 'reviews',
          Payoff: 'personalised-beauty',
          Brands: 'brands',
          Wishlist: 'wishlist',
          Categories: 'categories',
          TheEdge: 'foxy-edge',
          Routines: 'routines',
        },
      },
      MyProfile: 'famous',
      Tag: 'tags',
      CategoryDetail: 'category-details',
      UploadVideo: 'upload-video',
      NotificationHistory: 'notification-history',
      MyUploads: 'my-uploads',
      PaginatedList: 'paginated-list',
      ProfileEdit: 'profile-edit',
      Address: 'addresses',
      ConsumerProfile: 'consumer-profile',
      Interests: 'interests',
      PaymentMethods: 'payment-methods',
      ImageReview: 'image-review',
      StoryDetails: 'story-details',
      RecommendationPrompt: 'recommendation-prompt',
      FollowRecommendations: 'follow-recommendations',
      RecycleWithFoxyWebView: 'recycle-with-foxy',
      Cart: 'cart_items',
      MediaPlaylist: 'media-playlist',
      CartOffers: 'cart-offers',
      MyCollection: 'my-collection',
      OrderStatus: 'order-status',
      Payments: 'payment_methods',
      SavedCardScreen: 'saved-card-screen',
      FaqSection: 'Faq-section',
      FaqQuestionSection: 'Faq-question-section',
      FaqAnswerSection: 'Faq-answer-section',
      SwipableCards: 'swipable-cards',
      FullPageProductDescription: 'product-description',
      AccountSettings: 'account-settings',
      AboutUs: 'about-us',
      DeleteSelfie: 'delete-selfie',
      WebUrlView: 'view',
      FoxyClub: 'foxy-club',
      UpdateCart: 'update-cart',
      LoyaltyPlans: 'loyalty-plans',
      DateOfBirth: 'date-of-birth',
      OrdersHistory: 'order-history',
      Onboarding: 'onboarding',
      SelfiePrompt: 'selfie-prompt',
      ProfileDetails: 'profile-details',
      OrderDetails: 'order-details',
      TermsOfUse: 'terms-of-use',
      PrivacyPolicy: 'privacy-policy',
      ShippingPolicy: 'shipping-policy',
      PersonalisedOffers: 'personalised-offers',
      PrivacyConcerns: 'privacy-concerns',
      RequestCallBackPage: 'request-callback',
      ContactUsReasonPage: 'contact-us-reason',
      HandleSelection: 'pro/handle-selection',
      AboutYou: 'pro/about-you',
      ConsultationDetails: 'pro/consultation-details',
      PaymentsWaiting: 'payments-waiting',
      SocialProfile: 'pro/social-profile',
      AddLinks: 'pro/add-links',
      AddIntroVideo: 'pro/add-intro-video',
      RegistrationCompleted: 'pro/completed',
      Dashboard: 'pro/dashboard',
      OnboardCustomers: 'pro/onboard-customers',
      SaveProgress: 'pro/save-progress',
      EdgeInfo: 'edge-info',
      AwaitingPaymentOrchestrator: 'awaiting-payment',
      ContentModal: 'content-modal',
    },
  },
  getStateFromPath: (path, options) => {
    const [url, params] = path.split('?');
    let newPath = path;
    if (url.includes('/orders') && url.includes('/confirmation')) {
      const [, , orderId] = url.split('/');
      newPath = `/order-details?id=order-${orderId}&${params}`;
    }
    if (url.includes('/addresses')) {
      newPath = params ? `/addresses?${params}` : '/addresses';
    }
    if (url.includes('go_back')) {
      newPath = '/feed';
    }

    if (url.startsWith('/@')) {
      const bioHandle = url.replace("/@", '');
      newPath = `/artist/${bioHandle}`;
    }

    return getStateFromPath(newPath, options);
  },
};

if (isDesktop()) {
  linking.config.screens.TabNavigator.screens.Product = 'products/:shortSlug';
  linking.config.screens.TabNavigator.screens.OfferDetail = 'offers/:shortSlug';
  linking.config.screens.TabNavigator.screens.MoreItems = 'lists/:shortSlug';
  linking.config.screens.TabNavigator.screens.Category = 'categories/:first/:second?/:third?';
  linking.config.screens.TabNavigator.screens.Brand = 'brands/:shortSlug/:isCategory?/:first?/:second?/:third?';
  linking.config.screens.TabNavigator.screens.Search = 'search';
  linking.config.screens.TabNavigator.screens.TheEdge = 'foxy-edge';
  linking.config.screens.TabNavigator.screens.TheEdge = 'foxy_edge';
  linking.config.screens.TabNavigator.screens.Ingredients = 'ingredients/:shortSlug?';
  linking.config.screens.TabNavigator.screens.PostReview = 'products/:shortSlug/add_review';
  linking.config.screens.TabNavigator.screens.AllReviews = 'products/:shortSlug/reviews';
  linking.config.screens.TabNavigator.screens.TodayDeals = 'today-deals';
  linking.config.screens.TabNavigator.screens.ArtistLists = 'lists/artists/:handle/:listType';
  linking.config.screens.TabNavigator.screens.ProductLists = 'products/:name/:listType';
  linking.config.screens.TabNavigator.screens.FullScreenProductDescription = 'brands/:name/know-more';
  linking.config.screens.TabNavigator.screens.ShopOnlineList = 'lists/:name/shop-online';
  linking.config.screens.TabNavigator.screens.ShopOnlineBrand = 'brands/:name/shop-online';
  linking.config.screens.TabNavigator.screens.ShopOnlineCategory = 'categories/:first/:second?/:third?/shop-online';
  linking.config.screens.TabNavigator.screens.ShopOnlineBrandCategory = 'brands/:shortSlug/categories/:first/:second?/:third?/shop-online';
  linking.config.screens.TabNavigator.screens.PreRoutineDetails = 'routines/:slug';
  linking.config.screens.TabNavigator.screens.DuringRoutineDetails = 'during_routine/:slug';
} else {
  linking.config.screens.Product = 'products/:shortSlug';
  linking.config.screens.OfferDetail = 'offers/:shortSlug';
  linking.config.screens.MoreItems = 'lists/:shortSlug';
  linking.config.screens.Category = 'categories/:first/:second?/:third?';
  linking.config.screens.Brand = 'brands/:shortSlug/:isCategory?/:first?/:second?/:third?';
  linking.config.screens.Search = 'search';
  linking.config.screens.Ingredients = 'ingredients/:shortSlug?';
  linking.config.screens.PostReview = 'products/:shortSlug/add_review';
  linking.config.screens.AllReviews = 'products/:shortSlug/reviews';
  linking.config.screens.TodayDeals = 'today-deals';
  linking.config.screens.ArtistLists = 'lists/artists/:handle/:listType';
  linking.config.screens.ProductLists = 'products/:name/:listType';
  linking.config.screens.FullScreenProductDescription = 'brands/:shortSlug/know-more';
  linking.config.screens.ShopOnlineList = 'lists/:name/shop-online';
  linking.config.screens.ShopOnlineBrand = 'brands/:name/shop-online';
  linking.config.screens.ShopOnlineCategory = 'categories/:first/:second?/:third?/shop-online';
  linking.config.screens.ShopOnlineBrandCategory = 'brands/:shortSlug/categories/:first/:second?/:third?/shop-online';
  linking.config.screens.PreRoutineDetails = 'routines/:slug';
  linking.config.screens.DuringRoutineDetails = 'during_routine/:slug';
}

export default linking;
