import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import size from '../../theme/Fonts';
import { isDesktop } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';

const styles = {
  container: {
    flex: 1,
    backgroundColor: colors.white,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  imageStyle: {
    width: 80,
    height: 80,
  },
  topSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 18,
    marginRight: 20,
    marginTop: 12,
    alignItems: 'center',
  },
  topSectionHeading: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
  questionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 22,
    marginLeft: 20,
    marginRight: 20,
  },
  questionTextStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  rightArrowStyle: {
    width: 10,
    height: 16,
    tintColor: colors.foxyBlack,
    marginTop: 4,
  },
  horizontalLine: {
    width: isDesktop() ? 'calc(100% - 24px)' : getScreenWidth() - 24,
    height: 0,
    marginTop: 22,
    borderWidth: 0.5,
    borderColor: colors.subtitle,
    marginLeft: 12,
  },
  listContainer: {
    marginTop: 30,
    marginBottom: 20,
  },
  likeDislikeSectionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 20,
  },
  boldTextStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  likeIconStyle: {
    width: 44,
    height: 44,
    margin: 10,
    marginRight: 20,
  },
  disabledLikeIconStyle: {
    width: 44,
    height: 44,
    margin: 10,
    marginRight: 20,
    opacity: 0.3,
  },
  dislikeIconStyle: {
    width: 44,
    height: 44,
  },
  disabledDisLikeIconStyle: {
    width: 44,
    height: 44,
    opacity: 0.3,
  },
  contactUsContainer: {
    height: 130,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    marginTop: 12,
    marginBottom: 12,
    alignSelf: 'center',
  },
  subContainer: {
    marginHorizontal: 20,
    marginTop: 24,
    marginBottom: 14,
  },
  subHeadingStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginTop: 4,
  },
  blueTextButton: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.cta.lightBlue,
    marginTop: 8,
    right: 3,
  },
  greyHorizontalLine: {
    width: Utility.getScreenWidth() - 24,
    height: 0,
    marginTop: 22,
    borderWidth: 1,
    borderColor: colors.ultraLightGray,
    marginLeft: 12,
  },
  answerSectionContainer: {
    flex: 1,
  },
  answerSectionSubContainer: {
    // marginTop: 64,
    backgroundColor: colors.white,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  answerText: {
    paddingHorizontal: 20,
  },
  questionListContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  breadcrumbsListContainer: {
    paddingHorizontal: 20,
    paddingTop: 12,
  },
  breadCrumbsTextStyle: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: colors.cta.lightBlue,
  },
  body: {
    color: colors.foxyBlack,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    marginLeft: 12,
  },
  paragraph: {
    marginTop: 0,
    marginBottom: 0,
  },
  secondaryButtonContainer: {
    position: 'absolute',
    bottom: 0,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: Utility.isIOS() ? 20 : 8,
  },
};

export default styles;
