import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import FastImageView from '../../components/FastImageView';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import { camelCase } from 'lodash';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS, CURRENCY_SYMBOL } from '../../config/Constants';
import { isDesktop } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { isPresent } from '../../utils/BooleanUtility';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { getScreenWidth } from '../../utils/LayoutUtility';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';

class LoyaltyPlanMembershipDesktopCard extends PureComponent {
  constructor(props) {
    super(props);
    this.backgroundColors = {
      2: '#FFF4E9',
      1: '#FFF6D9',
      0: '#FFDACE',
    };
    this.tagColors = {
      2: '#FFDACE',
      1: '#F7CB77',
      0: '#F2AB8E',
    };

    this.tagOuterEdgeColors = {
      2: '#FFB79F',
      1: '#C59741',
      0: '#BA6A4A',
    };
    this.welcomeGiftImage = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.join_pro_modal_image,
    );
    this.darkColorShimmer = [colors.subtitle, '#DADADA', colors.subtitle];
  }

  tag = () => {
    const { itemData: { ribbonText = '', id = '' } = {} } = this.props;
    const planIdForDisplay = parseInt(id) % 3;
    const stickTagStyle = [
      styles.stickTag,
      { backgroundColor: this.tagColors[`${planIdForDisplay}`] },
    ];
    const tagEdgeStyle = [
      styles.tagEdge,
      { borderBottomColor: this.tagColors[`${planIdForDisplay}`] },
    ];
    const outerEdgeStyle = [
      styles.outerEdge,
      { borderBottomColor: this.tagOuterEdgeColors[`${planIdForDisplay}`] },
    ];
    return (
      <View style={styles.tagContainer}>
        <View style={tagEdgeStyle} />
        <View style={stickTagStyle}>
          <Text style={styles.tagTitle}>{ribbonText?.toLocaleUpperCase()}</Text>
        </View>
        <View style={outerEdgeStyle} />
      </View>
    );
  };

  bottomActionButton = () => {
    const { cta_text = '', loyaltyPlans = [], onButtonPress } = this.props;
    if (Utility.isBlank(loyaltyPlans)) {
      return (
        <View style={styles.actionButtonPlaceholder}>
          <ShimmerPlaceHolder
            colorShimmer={this.darkColorShimmer}
            style={styles.buttonPlaceholderText}
            autoRun
          />
        </View>
      );
    }
    const buttonText = Utility.isBlank(cta_text) ? 'Continue' : cta_text;
    const buttonColor = Utility.isBlank(cta_text)
      ? colors.subtitle
      : colors.black;
    return (
      <FoxyShadowButton
        width={300}
        title={buttonText}
        onPress={onButtonPress}
        firstColor={buttonColor}
        secondColor={buttonColor}
        underlayColor={colors.subtitle}
      />
    );
  };

  handlePress = () => {
    const { onPress = () => {}, itemData } = this.props;
    onPress(itemData);
  }


  render() {
    const {
      isEligible = false,
      isSelected = false,
      onPress = () => {},
      itemData: {
        id = '',
        displayName: name = '',
        planPrompt = '',
        shortDescription: description = '',
        imageUrl = '',
        mrp = '',
        cardImageUrl,
      } = {},
      itemData = {},
      index,
    } = this.props;
    const conditionalContainerStyle = isSelected
      ? styles.containerSelected
      : styles.containerUnselected;
    const planIdForDisplay = parseInt(index) % 3;
    const containerStyle = [
      conditionalContainerStyle,
      { backgroundColor: this.backgroundColors[`${planIdForDisplay}`] },
    ];
    const imageContainerStyle = isEligible
      ? styles.imageContainerSelected
      : [
          styles.imageContainer,
          { borderColor: this.tagColors[`${planIdForDisplay}`] },
        ];
    const showWelcomeGiftImage = Utility.isPresent(mrp) && parseInt(mrp) !== 0;
    return (
      <TouchableOpacity
        style={containerStyle}
        onPress={this.handlePress}
      >
        <View style={styles.contentContainer}>
          <View style={styles.radioButton}>
            {isSelected && <View style={styles.activeRadioButton} />}
          </View>
          <View style={imageContainerStyle}>
            <FastImageView
              source={getMinifiedImage(
                imageUrl || images.edge.placeholder,
                styles.image.width,
                styles.image.height,
              )}
              style={styles.image}
            />
            {isEligible && (
              <FastImageView
                source={getMinifiedImage(
                  images.edge.checked_icon,
                  styles.checkedIcon.width,
                  styles.checkedIcon.height,
                )}
                style={styles.checkedIcon}
                resizeMode='contain'
              />
            )}
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.topTitle}>
              {`${description}`.toLocaleUpperCase()}
            </Text>
          </View>
        </View>
        <View>
          <Text style={styles.infoTitle}>{planPrompt}</Text>
        </View>
        <View style={styles.buttonContainer}>
          <this.bottomActionButton />
        </View>
        {isPresent(cardImageUrl) && (
          <FastImageView
            source={getMinifiedImage(cardImageUrl)}
            style={styles.welcomeGift}
            resizeMode='cover'
          />
        )}
        <this.tag />
      </TouchableOpacity>
    );
  }
}

export default LoyaltyPlanMembershipDesktopCard;

const styles = StyleSheet.create({
  containerSelected: {
    marginRight: 7,
    borderRadius: 8,
    borderWidth: 1.5,
    borderColor: colors.green,
    height: 400,
  },
  containerUnselected: {
    marginRight: 7,
    borderRadius: 8,
    borderWidth: 1.5,
    borderColor: colors.transparent,
    height: 400,
  },
  contentContainer: {
    flexDirection: 'row',
    marginTop: 12,
    paddingTop: 8,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  contentContainer1: {
    flexDirection: 'row',
    marginTop: 12,
    paddingTop: 8,
    justifyContent: 'flex-start',
  },
  radioButton: {
    width: 20,
    height: 20,
    backgroundColor: colors.white,
    borderWidth: 1.5,
    borderColor: '#DADADA',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    marginTop: 30,
    marginLeft: 12,
  },
  activeRadioButton: {
    width: 12,
    height: 12,
    backgroundColor: '#706F6F',
    borderRadius: 6,
  },
  imageContainer: {
    borderWidth: 2,
    borderColor: '#FFDACE',
    borderRadius: 50,
    marginLeft: 8,
    overflow: 'hidden',
    marginTop: 8,
  },
  imageContainerSelected: {
    borderWidth: 2,
    borderColor: '#01B460',
    borderRadius: 50,
    marginLeft: 8,
    overflow: 'hidden',
  },
  image: {
    width: 60,
    height: 60,
    resizeMode: 'contain',
  },
  textContainer: {
    padding: 18,
    justifyContent: 'space-between',
    flex: 1,
  },
  topTitle: {
    lineHeight: 18,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    color: colors.foxyBlack,
    letterSpacing: 1,
  },
  infoTitle: {
    marginTop: 8,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: colors.foxyBlack,
    flexWrap: 'wrap',
    lineHeight: 18,
    padding: 12,
  },
  stickTag: {
    alignItems: 'flex-start',
    height: 20,
    justifyContent: 'center',
    paddingRight: 16,
  },
  tagTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    letterSpacing: 1,
    marginLeft: 8,
    color: colors.foxyBlack,
  },
  tagEdge: {
    width: 0,
    height: 0,
    top: 0,
    borderLeftWidth: 12,
    borderBottomWidth: 20,
    borderLeftColor: colors.transparent,
    borderRightColor: colors.transparent,
  },
  outerEdge: {
    position: 'absolute',
    right: 0,
    bottom: -4.5,
    width: 0,
    height: 0,
    borderLeftWidth: 5.5,
    borderBottomWidth: 5.5,
    borderLeftColor: colors.transparent,
    borderRightColor: colors.transparent,
    transform: [{ rotate: '180deg' }],
  },
  tagContainer: {
    position: 'absolute',
    right: -6.5,
    top: 10,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  checkedIcon: {
    position: 'absolute',
    right: 1,
    bottom: 1,
    zIndex: 1,
    height: 16,
    width: 16,
  },
  welcomeGift: {
    width: Utility.getScreenWidth() - 68, // internal padding, radio button and image subtracted
    height: (Utility.getScreenWidth() - 68) / 3,
    alignSelf: 'center',
    borderRadius: 16,
    marginTop: 0,
    marginBottom: 12,
    overflow: 'hidden',
  },
  buttonContainer: {
    flex: 1,
    alignItems: 'center',
    position: 'absolute',
    bottom: 20,
    right: 0,
    left: 0,
  },
  buttonPlaceholderText: {
    height: 46,
    width: 300,
  },
});