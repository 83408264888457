import { saveProductData } from '../actions/ActionTypes';
import { DESKTOP_HIDDEN_TABS } from '../config/Constants';
import { SCREEN_CONSTANTS } from '../config/ScreenConstants';
import Config from '../libraries/ReactNativeConfig';
import { getStoreRef } from '../store/StoreUtility';
import { isDesktop, isNative, isWeb } from './BooleanUtility';
import {
  getCategoryUrlParamsObject,
  getBrandUrlParamsObject,
  getCompactSlug,
  getArtistListsParamsObject,
  getProductListsParamsObject,
} from './StringUtility';

export const getAllTabsRoutes = () => {
  if (isDesktop()) {
    return [...(Config.DESKTOP_TABS.split(',')), ...DESKTOP_HIDDEN_TABS];
  }
  if (isWeb()) {
    return Config.MOBILE_TABS.split(',');
  }
  return Config.TABS.split(',');
};

export const getTabsRoutes = () => {
  if (isDesktop()) {
    return Config.DESKTOP_TABS.split(',');
  }
  if (isWeb()) {
    return Config.MOBILE_TABS.split(',');
  }
  return Config.TABS.split(',');
};

export const getHiddenTabRoutes = () => {
  if (isDesktop()) {
    return DESKTOP_HIDDEN_TABS;
  }
  return [];
};

export const webFallbackGoBack = (navigation) => {
  if (isNative()) {
    navigation.goBack();
    return;
  }
  if (navigation.canGoBack()) {
    navigation.goBack();
  } else {
    window.history.back();
  }
};

export const goBackWithFallbackHome = (
  navigation,
  fallbackPage = '',
  params = {},
) => {
  if (isWeb()) {
    window.history.back();
  } else if (navigation.canGoBack()) {
    navigation.goBack();
  } else {
    navigation.replace('TabNavigator');
  }
};

export const goToHomeIfNoPreviousScreen = (navigation, fallbackPage = '', params = {}) => {
  if (isNative()) return;
  if (!navigation.canGoBack()) {
    if (fallbackPage) {
      navigateToScreen({
        navigation,
        type: 'replace',
        screen: fallbackPage,
        params,
      });
    } else {
      navigateToScreen({
        navigation,
        type: 'replace',
        screen: 'Feed',
      });
    }
  }
};

export const navigateToScreen = ({
  navigation,
  type,
  screen,
  params = {},
  navigationSource,
}) => {
  if (!navigation) return;
  let newParams = params;
  let newScreen = screen;
  if (isWeb()) {
    const { slug } = params;
    if (screen === SCREEN_CONSTANTS.CATEGORY && slug) {
      newParams = getCategoryUrlParamsObject(slug);
      if (params.property) {
        newParams.property = params.property;
      }
      if (params.propertyName) {
        newParams.propertyName = params.propertyName;
      }
    } else if (screen === 'Brand' && slug) {
      newParams = getBrandUrlParamsObject(slug);
    } else if (screen === 'Product' && slug) {
      const { toggleCartVisibility, ...rest } = params;
      const shortSlug = getCompactSlug(slug);
      getStoreRef().dispatch(saveProductData({ ...rest, shortSlug }));
      newParams = { shortSlug };
    } else if (screen === 'MoreItems' && slug) {
      if (slug.includes('artists/')) {
        newScreen = 'ArtistLists';
        newParams = getArtistListsParamsObject(slug);
      } else if (slug.includes('products/')) {
        newScreen = 'ProductLists';
        newParams = getProductListsParamsObject(slug);
      } else {
        newParams = { shortSlug: getCompactSlug(slug) };
        if (params.property) {
          newParams.property = params.property;
        }
      }
    } else if (screen === 'Ingredients' && slug) {
      newParams = { shortSlug: getCompactSlug(slug) };
    } else if (screen === 'OfferDetail') {
      const { promptData = {} } = params;
      const { offer = {} } = promptData;
      const { slug: promptSlug } = offer;
      if (slug || promptSlug) {
        newParams = { shortSlug: getCompactSlug(promptSlug || slug) };
      }
    } else if (screen === 'TheEdge') {
      newParams = {};
      newScreen = 'TheEdge';
    } else if (screen === 'LoyaltyPlans') {
      newParams = {};
      newScreen = 'LoyaltyPlans';
    } else if (screen === 'PreRoutineDetails') {
      const { itemData, ...restParams } = params;
      newParams = restParams;
    }
  }
  if (getAllTabsRoutes().includes(newScreen)) {
    navigation[type]('TabNavigator', { screen: newScreen, params: newParams });
  } else if (navigationSource) {
    navigation[type](navigationSource, newScreen, newParams);
  } else {
    navigation[type](newScreen, newParams);
  }
};
