import { View, Text, StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import some from 'lodash/some';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import { isPresent } from '../../utils/BooleanUtility';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import FastImageView from '../../components/FastImageView';
import Utility from '../../utils/Utility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { navigateOnSearchQuerySelect } from '../search/Search';
import { AnalyticsManager, EventType } from '../../analytics';

const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

export default function SearchSuggest({
  item = {},
  navigation,
  hideSearchSuggest,
  searchQuery,
  index,
}) {
  const [itemData, setItemData] = useState(item);

  useEffect(() => {
    const { routine = {} } = itemData;
    if (isPresent(routine)) {
      try {
        new JSONAPIDeserializer({
          typeAsAttribute: false,
          pluralizeType: true,
          keyForAttribute: 'snake_case',
        })
          .deserialize(routine)
          .then((data) => {
            setItemData({ ...data, type: 'routine' });
          });
      } catch (e) {}
    }
  }, []);

  const todayDeals = useSelector(state => state.todayDeals) || {};
  const handlePress = () => {
    const { type, name, id } = itemData;
    AnalyticsManager.logEvent(EventType.search.SEARCH_INITIATE, { search_query: searchQuery });
    AnalyticsManager.logEvent(EventType.search.SEARCH_AUTOCOMPLETE_CLICK, {
      autocomplete_result: name,
      id,
      item_position: index,
      search_query: searchQuery,
      type,
    });
    hideSearchSuggest();
    if (navigateOnSearchQuerySelect({
      navigation,
      item: itemData,
      query: searchQuery,
    })) {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: 'Search',
        params: { q: searchQuery },
      });
    }
  };

  const { skus = {} } = todayDeals;

  const isFlashDealAvailable = some(
    itemData.sku_ids,
    (itemObject) => skus[`${itemObject}`],
  );

  let searchSuggestImageStyle = isFlashDealAvailable
    ? styles.searchSuggestAnimation
    : styles.searchSuggestIcon;

  let searchAutosuggestImage = isFlashDealAvailable
    ? images.itemLikeAnimation
    : images.search_autosuggest_icon;

  if (isPresent(itemData?.image_url)) {
    searchAutosuggestImage = itemData.image_url;
    searchSuggestImageStyle = styles.searchSuggestImage;
  }

  const title = itemData.name || itemData.text;

  return (
    <DebouncedTouchableOpacity
      onPress={handlePress}
      style={styles.searchSuggestItem}
    >
      <FastImageView
        source={Utility.getMinifiedImage(searchAutosuggestImage, 120)}
        style={searchSuggestImageStyle}
      />

      <View style={styles.searchSuggestTextBox}>
        <Text numberOfLines={1} style={styles.searchSuggestText}>
          {title}
        </Text>
        {isPresent(itemData.subtext) && (
          <Text numberOfLines={1} style={styles.searchSuggestSubText}>
            {itemData.subtext}
          </Text>
        )}
      </View>
    </DebouncedTouchableOpacity>
  );
}

const styles = StyleSheet.create({
  searchSuggestItem: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
    borderBottomWidth: 0.7,
    borderBottomColor: colors.background,
    height: 34,
  },
  searchSuggestAnimation: {
    width: 28,
    height: 28,
    paddingRight: 10,
    marginLeft: 12,
    resizeMode: 'contain',
  },
  searchSuggestIcon: {
    width: 28,
    height: 28,
    paddingRight: 10,
    resizeMode: 'contain',
    marginLeft: 12,
  },
  searchSuggestImage: {
    width: 28,
    height: 28,
    paddingRight: 10,
    resizeMode: 'contain',
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 4,
    marginLeft: 12,
  },
  searchSuggestText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    color: colors.foxyBlack,
    marginRight: 8,
  },
  searchSuggestTextBox: {
    flex: 1,
    marginLeft: 12,
    marginRight: 12,
    alignItems: 'flex-start',
    height: 34,
    justifyContent: 'center',
  },
  searchSuggestSubText: {
    fontSize: 10,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    color: colors.subText,
    marginTop: 2,
  },
});
