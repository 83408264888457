import {
  View,
  StyleSheet,
  ScrollView,
  BackHandler,
  RefreshControl,
} from 'react-native';
import React, { Component } from 'react';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getListData } from '../../actions/PayoffActions';
import { List } from '../List';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import {
  getUserTodayDeals,
  getUserTodayDealsWithCallback,
} from '../../actions/ActionTypes';
import { LISTS_API_VERSION } from '../../config/Constants';
import FoxyEdgeHeader from './FoxyEdgeHeader';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import _ from 'lodash';
import withNavigation from '../../utils/WithNavigation';
import NavigationService from '../../navigator/NavigationService';
import { StaticNavigationHeader } from '../../components/shared';
import Config from '../../libraries/ReactNativeConfig';
import { navigateToScreen } from '../../utils/NavigationUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { ReactNavigationPerformanceView } from '@shopify/react-native-performance-navigation';
import { isDesktop, isPresent } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';

class NonMemberLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemData: {},
      loading: true,
      serverError: false,
      connectionError: false,
      refreshing: false,
    };

    this.colorShimmerArray = ['#fafafa', '#eeeeee', '#fafafa'];
    this.debouncedFetchData = _.debounce(this.getPayoffPageWithShimmers, 2000);
  }

  componentDidMount() {
    Utility.setStatusBarColor(colors.background);

    const { navigation } = this.props;

    this.unsubscribe = navigation.addListener('focus', () => {
      this.onFocus();
    });
    this.unsubscribeBlur = navigation.addListener('blur', () => {
      Utility.setStatusBarWhite();
    });

    this.debouncedFetchData();
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribeBlur();
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
  }

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  getPayoffPageWithShimmers = () => {
    const { getListData, membership_id = '' } = this.props;
    const { refreshing = false } = this.state;
    this.setState({
      loading: true,
    });
    Utility.setPageLoadStart(
      SCREEN_CONSTANTS.NON_MEMBER_LANDING_PAGE,
      0,
      '',
      true,
      {
        refreshing,
        membership_id,
      },
    );
    getListData(
      `/api/${LISTS_API_VERSION}/lists/main-benefit-list`,
      this.fetchPayoffCallback,
    );
  };

  fetchPayoffCallback = (success, response) => {
    const { refreshing } = this.state;
    const { membership_id, membership_cohort } = this.props;
    Utility.setPageLoadEnd(SCREEN_CONSTANTS.NON_MEMBER_LANDING_PAGE, 0, {
      refreshing,
      membership_id,
    });
    AnalyticsManager.logEvent('membership_status', {
      membership_cohort: membership_cohort || 'error',
      membership_id: membership_id || 'error',
    });
    this.setState({
      loading: false,
      refreshing: false,
      serverError: !success && !response?.connectionError,
      connectionError: !success && response?.connectionError,
    });

    if (success && response) {
      this.setState({
        itemData: response,
        serverError: false,
      });
    }
  };

  onFocus = () => {
    AnalyticsManager.setCurrentScreen(SCREEN_CONSTANTS.NON_MEMBER_LANDING_PAGE);
    Utility.setStatusBarColor(colors.background);
  };

  onSelectMembershipPress = () => {
    const { authToken, membership_cohort } = this.props;
    AnalyticsManager.logEvent('item_action', {
      type: 'select_membership',
      is_guest_user: Utility.isBlank(authToken),
    });
    if (Utility.isBlank(authToken)) {
      this.invokeLoginFlow();
      Utility.furtherAction = {
        action: this.refreshTodayDealsAndNavigate,
      };
    } else {
      this.conditionalNavigation();
    }
  };

  invokeLoginFlow = () => {
    const { setPhoneNumberModalInfo } = this.props;
    setPhoneNumberModalInfo(Utility.getLoginModalInfo('FOXY_EDGE'));
    NavigationService.renderOnboarding();
  };

  refreshTodayDealsAndNavigate = () => {
    const { getUserTodayDealsWithCallback, navigation } = this.props;
    getUserTodayDealsWithCallback((success, response) => {
      if (response?.membership_cohort === 'member') {
        Utility.foxy_edge_source = SCREEN_CONSTANTS.NON_MEMBER_LANDING_PAGE;
        NavigationService.push('TheEdge');
        return;
      }
      this.conditionalNavigation();
    });
  };

  conditionalNavigation = () => {
    const { navigation, membership_cohort } = this.props;
    const route = membership_cohort === 'member' ? 'TheEdge' : 'LoyaltyPlans';

    navigateToScreen({
      navigation,
      type: 'navigate',
      screen: route,
      params: {
        previousScreen: SCREEN_CONSTANTS.NON_MEMBER_LANDING_PAGE,
      },
    });
  };

  screenPlaceholder = () => {
    return (
      <View>
        <this.screenPlaceholderCard />
        <this.screenPlaceholderCard />
        <this.screenPlaceholderCard />
      </View>
    );
  };

  screenPlaceholderCard = () => {
    return (
      <View style={styles.placeholderCard}>
        <View style={styles.placeholderCardContent}>
          <ShimmerPlaceHolder
            style={styles.title1Placeholder}
            colorShimmer={this.colorShimmerArray}
            autoRun
          />
          <ShimmerPlaceHolder
            style={styles.title2Placeholder}
            colorShimmer={this.colorShimmerArray}
            autoRun
          />
          <ShimmerPlaceHolder
            style={styles.subtitlePlaceholder}
            colorShimmer={this.colorShimmerArray}
            autoRun
          />
        </View>
        <ShimmerPlaceHolder
          style={styles.imagePlaceholder}
          colorShimmer={this.colorShimmerArray}
          autoRun
        />
      </View>
    );
  };

  listContainer = () => {
    const { itemData = {}, loading } = this.state;
    const { navigation } = this.props;
    if (Utility.isBlank(itemData) || loading) return <this.screenPlaceholder />;
    return (
      <View style={{ marginTop: -18 }}>
        <List
          itemData={itemData}
          navigation={navigation}
          previousScreen={SCREEN_CONSTANTS.NON_MEMBER_LANDING_PAGE}
        />
      </View>
    );
  };

  onRefresh = () => {
    this.setState({
      refreshing: true,
    });
    this.debouncedFetchData();
  };

  render() {
    const {
      selfie_image_url,
      hideHeader = false,
      membership_cohort,
    } = this.props;
    const {
      itemData = {},
      loading,
      refreshing,
      serverError,
      connectionError,
    } = this.state;
    const showActionButton =
      Utility.isPresent(itemData) && !serverError && !connectionError;
    return (
      <ReactNavigationPerformanceView
        screenName='non_member_landing_page'
        interactive={isPresent(itemData)}
      >
        <ErrorBoundary
          showServerError={serverError}
          onPressReload={this.debouncedFetchData}
          loading={refreshing}
          connectionError={false}
          hideHeader
          screen_name={'non_member_page'}
        >
          <StaticNavigationHeader
            title=""
            showSearchButton
            showShareButton
            bgColor={colors.background}
            overrideBackButtonBgColor
            skipDivider
            showCart
            shareUrl={Config.NON_MEMBER_LANDING_PAGE_DEEPLINK}
          />
          <ScrollView
            style={styles.scrollview}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={styles.scrollViewContent}
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={this.onRefresh}
                backgroundColor={colors.background}
              />
            }
          >
            <FoxyEdgeHeader selfie_image_url={selfie_image_url} />
            <this.listContainer />
          </ScrollView>
        </ErrorBoundary>

        {showActionButton && (
          <View style={styles.buttonContainer}>
            <FoxyShadowButton
              width={Utility.getScreenWidth() - 24}
              title={
                membership_cohort === 'member'
                  ? 'View my membership'
                  : 'Select membership'
              }
              onPress={this.onSelectMembershipPress}
              style={styles.button}
              firstColor={colors.black}
              secondColor={colors.black}
              underlayColor={colors.subtitle}
            />
          </View>
        )}
      </ReactNavigationPerformanceView>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    selfie_image_url: state.UserAccountInfo.profile.selfie_image_url,
    name: state.UserAccountInfo.profile.name,
    facialAnalysis: state.UserAccountInfo?.facialAnalysis?.my_attributes_values,
    authToken: state.UserAccountInfo.authToken,
    has_selfie: state.UserAccountInfo.has_selfie,
    membership_cohort: state.todayDeals.membership_cohort,
    membership_id: state.todayDeals.membership_id,
  };
};

const styles = StyleSheet.create({
  button: {
    height: 44,
  },
  scrollViewContent: {
    backgroundColor: colors.white,
    paddingBottom: isDesktop() ? 0 : 20,
  },
  buttonContainer: {
    width: Utility.getScreenWidth(),
    backgroundColor: '#ffffffb3',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: Utility.isIOS() ? 20 : 8,
    alignSelf: 'center',
  },
  scrollview: {
    flex: 1,
    backgroundColor: colors.white,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  placeholderCard: {
    width: Utility.getScreenWidth() - 40,
    marginHorizontal: 20,
    marginVertical: 10,
    flexDirection: 'row',
    alignSelf: 'center',
  },
  title1Placeholder: {
    width: 171,
    height: 28,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  title2Placeholder: {
    width: 100,
    height: 28,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    marginTop: 4,
  },
  subtitlePlaceholder: {
    marginTop: 16,
    width: 170,
    height: 20,
    borderRadius: 4,
  },
  placeholderCardContent: {
    flex: 1,
    alignItems: 'flex-start',
  },
  imagePlaceholder: {
    width: 140,
    height: 210,
    borderRadius: 16,
  },
  shareIconContainer: {
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  shareHeaderContainer: {
    alignItems: 'center',
    backgroundColor: colors.background,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: Utility.isIOS() ? 8 : 0,
    paddingRight: 12,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getListData,
      setPhoneNumberModalInfo,
      getUserTodayDeals,
      getUserTodayDealsWithCallback,
    },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps)(NonMemberLandingPage),
);
