import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import FastImageView from '../../components/FastImageView';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import RemoteConfig from '../../utils/RemoteConfig';
import Utility from '../../utils/Utility';
import withNavigation from '../../utils/WithNavigation';

class UpgradeMembershipTray extends PureComponent {
  onUpgradePress = () => {
    const { navigation, screenName } = this.props;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.SOURCE]: screenName,
      [EventParameterKey.ITEM_TYPE]: EventParameterValue.ITEM_TYPE.UPGRADE_TRAY,
    });
    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_CLICK, {
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.ITEM_TYPE]: EventParameterValue.ITEM_TYPE.UPGRADE_TRAY,
    });
    navigation.navigate('LoyaltyPlans', {
      selectedPlanName: 'Pro',
      previousScreen: screenName,
      upgrade: true,
    });
  };

  render() {
    const upgradeTray = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.upgrade_tray_edge,
    );
    return (
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <FastImageView
            source={images.edge.offer}
            style={styles.upgradeLogo}
          />
          <Text style={styles.body}>{upgradeTray}</Text>
        </View>
        <TouchableOpacity onPress={this.onUpgradePress}>
          <Text style={styles.buttonText}>Upgrade</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

export default withNavigation(UpgradeMembershipTray);

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: 12,
    paddingVertical: 14,
    flexDirection: 'row',
    backgroundColor: colors.edge_upgrade.yellow,
    opacity: 0.95,
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    alignItems: 'center',
    paddingBottom: 14,
  },
  upgradeLogo: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    marginRight: 8,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  body: {
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    flex: 1,
  },
  buttonText: {
    color: colors.edge_upgrade.blue,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
  },
});
