import { StyleSheet } from 'react-native';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LottieView from 'lottie-react-native';
import animations from '../../theme/Animations';
import { useNavigation, useRoute } from '@react-navigation/native';
import Utility from '../../utils/Utility';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import colors from '../../theme/Colors';
import { setCongratsModalForEdge } from '../../actions/FoxyEdgeAction';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

const EdgeCongratsModal = ({ displayNotificationRequestModal = () => {} }) => {
  const showCongratsModalForEdge = useSelector(
    (state) => state.UserAccountInfo.showCongratsModalForEdge,
  );
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { name: routeName } = useRoute();

  useEffect(() => {
    if (showCongratsModalForEdge) {
      setTimeout(() => {
        const edgeJoinModal = RemoteConfig.getValue(
          REMOTE_CONFIG_KEYS.edge_join_success,
        );
        if (routeName !== SCREEN_CONSTANTS.ALERT_MODAL) {
          navigation.push(SCREEN_CONSTANTS.ALERT_MODAL, {
            hideSecondButton: true,
            alertBoxTitle: 'CONGRATS',
            alertMessage: edgeJoinModal,
            firstButtonTitle: 'Start Shopping',
            firstButtonOnPress: closeModal,
            onTapOutside: closeModal,
            autoWrapContent: true,
            firstButtonTextColor: colors.cta.lightBlue,
            showAnimation: true,
            animation: animations.tick_animation,
            animationStyle: styles.animationStyle,
          });
        }
      }, 500);
    }
  }, [showCongratsModalForEdge]);

  const closeModal = () => {
    navigation.pop();
    dispatch(setCongratsModalForEdge(false));
    displayNotificationRequestModal();
  };

  if (!showCongratsModalForEdge) return null;

  return (
    <LottieView
      source={animations.confettiAnimation}
      style={styles.confettiStyle}
      resizeMode="cover"
      autoPlay
      loop
    />
  );
};

export default memo(EdgeCongratsModal);

const styles = StyleSheet.create({
  confettiStyle: {
    height: Utility.getScreenHeight(),
    width: Utility.getScreenWidth(),
    position: 'absolute',
  },
  animationStyle: {
    height: 100,
    width: 100,
    alignSelf: 'center',
    marginBottom: 12,
  },
});
