import React, { Component } from 'react';
import {
  ScrollView,
  RefreshControl,
  StyleSheet,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withNavigation from '../../utils/WithNavigation';
import { getListData, getMemberPage } from '../../actions/PayoffActions';

import { List } from '../List';
import { ScreenPlaceholder } from '../../components/shared';
import {
  CLUB_FOXY_LANDING_INACTIVE_SLUG,
  CLUB_FOXY_LANDING_ACTIVE_SLUG,
  CLUB_FOXY_LANDING_ELIGIBLE_SLUG,
  NOTIFICATION_REQUEST_MODAL_TYPE,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import {
  getUserTodayDeals,
  setShowEdgePendingIndicator,
} from '../../actions/ActionTypes';
import { addContinueAction } from '../../actions/FoxyEdgeAction';
import size from '../../theme/Fonts';
import FoxyEdgeHeader from '../FoxyEdge/FoxyEdgeHeader';
import UpgradeMembershipTray from '../FoxyEdge/UpgradeMembershipTray';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import _ from 'lodash';
import ContinueWhereYouLeftTray from '../FoxyEdge/ContinueWhereYouLeftTray';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import RemoteConfig from '../../utils/RemoteConfig';
import NotificationUtils from '../../utils/NotificationUtils';
import EdgeCongratsModal from './EdgeCongratsModal';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { ReactNavigationPerformanceView } from '@shopify/react-native-performance-navigation';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isWeb } from '../../utils/BooleanUtility';

export class FoxyClub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foxyClub: {},
      loading: true,
      refreshing: false,
      serverError: false,
      paginating: false,
      connectionError: false,
    };
    this.page = 0;
    this.debouncedFetchData = _.debounce(this.getEdgeData, 2000, {
      leading: true,
      trailing: false,
    });
    this.debouncedRefreshData = _.debounce(this.refreshMemberPage, 2000, {
      leading: true,
      trailing: false,
    });
  }

  componentDidMount() {
    const { navigation, showCongratsModalForEdge = false } = this.props;

    if (!showCongratsModalForEdge) {
      this.displayNotificationRequestModal();
    }
    NotificationUtils.updateMoEngageChannelIfNotificationPermissionIsAllowed();
    this.setLoading(true);

    this.debouncedFetchData();
    this.focusListener = navigation.addListener('focus', () => {
      this.onFocus();
    });
    this.unsubscribeBlur = navigation.addListener('blur', () => {
      Utility.setStatusBarWhite();
    });
  }

  componentWillUnmount() {
    this.focusListener();
    this.unsubscribeBlur();
  }

  setLoading = (loading) => {
    this.setState({
      loading,
    });
  };

  onFocus = () => {
    const { membership_cohort } = this.props;
    this.page = 0;
    Utility.setStatusBarColor(colors.background);
    AnalyticsManager.setCurrentScreen(`foxy_edge_${membership_cohort}`);
    this.debouncedRefreshData();
  };

  getEdgeData = () => {
    const {
      getListData,
      todayDeals: { membership_id = '' } = {},
      getUserTodayDeals,
      membership_cohort,
    } = this.props;
    const { refreshing } = this.state;

    Utility.setPageLoadStart(`foxy_edge_${membership_cohort}`, 0, '', true, {
      refreshing,
      membership_id,
    });

    getListData(
      CLUB_FOXY_LANDING_ACTIVE_SLUG,
      this.fetchListCallback,
      `${this.page}&show_true_stock_status=true`,
    );
    setTimeout(() => {
      getUserTodayDeals();
    }, 100);
  };

  refreshMemberPage = () => {
    const {
      getMemberPage,
      todayDeals: { membership_id = '' } = {},
      getUserTodayDeals,
      membership_cohort,
    } = this.props;
    const { refreshing } = this.state;

    Utility.setPageLoadStart(`foxy_edge_${membership_cohort}`, 0, '', true, {
      refreshing,
      membership_id,
    });

    getMemberPage(CLUB_FOXY_LANDING_ACTIVE_SLUG, this.refreshCallback);

    setTimeout(() => {
      getUserTodayDeals();
    }, 100);
  };

  onRefresh = () => {
    this.setState({
      refreshing: true,
      loading: true,
    });
    this.debouncedFetchData();
  };

  fetchListCallback = (success, response) => {
    const { refreshing } = this.state;
    const { membership_id, setShowEdgePendingIndicator, membership_cohort } =
      this.props;
    setShowEdgePendingIndicator(false);
    Utility.setPageLoadEnd(`foxy_edge_${membership_cohort}`, 0, {
      refreshing,
      membership_id,
    });

    this.setLoading(false);

    this.setState({
      serverError: !success && !response?.connectionError,
      connectionError: !success && response?.connectionError,
      refreshing: false,
      foxyClub: !success ? {} : response,
    });
    setTimeout(this.getPaginatedList, 1000);
  };

  refreshCallback = (success, response) => {
    const { refreshing } = this.state;
    const { membership_id, setShowEdgePendingIndicator, membership_cohort } =
      this.props;
    setShowEdgePendingIndicator(false);
    Utility.setPageLoadEnd(`foxy_edge_${membership_cohort}`, 0, {
      refreshing,
      membership_id,
    });

    this.setLoading(false);

    this.setState({
      serverError: !success && !response?.connectionError,
      connectionError: !success && response?.connectionError,
      refreshing: false,
      foxyClub: !success ? {} : response,
    });
  };

  getPaginatedList = () => {
    const { getListData } = this.props;

    this.setPaginated(true);

    getListData(
      CLUB_FOXY_LANDING_ACTIVE_SLUG,
      this.fetchListCallbackPaginated,
      `${++this.page}&show_true_stock_status=true`,
    );
  };

  fetchListCallbackPaginated = (success, response) => {
    this.setLoading(false);
    this.setPaginated(false);
    const { foxyClub } = this.state;

    if (success && response) {
      let foxyClubData = foxyClub;
      if (this.page > 0) {
        foxyClubData = {
          ...foxyClubData,
          objects: [...foxyClubData.objects, ...response?.objects],
        };
      }
      this.setState(
        {
          foxyClub: foxyClubData,
        },
        () => {
          const { membership_cohort } = this.props;
          Utility.setPageLoadEnd(`foxy_edge_${membership_cohort}`, this.page);
        },
      );
      if (response?.objects.length === 0 && this.page > 0) {
        this.page = 0;
        return;
      }
      setTimeout(this.getPaginatedList, 1000);
    }
  };

  setPaginated = (paginating) => {
    this.setState({
      paginating,
    });
  };

  scrollPageToTop = () => {
    if (this.listRef) {
      this.listRef.scrollTo({ y: 0, animated: true });
    }
  };

  setListRef = (ref) => {
    this.listRef = ref;
    const { navigation, scrollViewRef } = this.props;
    if (scrollViewRef) {
      scrollViewRef(ref);
    }
    navigation.setParams({
      scrollToTop: this.scrollPageToTop,
    });
  };

  renderLists = () => {
    const { foxyClub, loading, refreshing } = this.state;
    const { navigation, todayDeals: { membership_cohort } = {} } = this.props;
    if (loading || refreshing) {
      return (
        <ScreenPlaceholder previousScreen={`foxy_edge_${membership_cohort}`} />
      );
    }
    return (
      <>
        <List
          itemData={foxyClub}
          navigation={navigation}
          previousScreen={`foxy_edge_${membership_cohort}`}
        />
      </>
    );
  };

  renderMembershipScreen = () => {
    const { refreshing, serverError, connectionError, paginating, loading } =
      this.state;
    const { selfie_image_url, todayDeals: { membership_cohort } = {} } =
      this.props;
    return (
      <ErrorBoundary
        showServerError={serverError}
        onPressReload={this.onRefresh}
        loading={refreshing}
        connectionError={false}
        hideHeader
        screen_name={'edge'}
      >
        <ScrollView
          ref={this.setListRef}
          style={styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={this.onRefresh}
              backgroundColor={colors.background}
            />
          }
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.scrollViewContent}
        >
          <this.renderLists />
          {paginating && (
            <>
              <View style={styles.loader}>
                <ActivityIndicator
                  animating
                  color={colors.green}
                  size="large"
                />
                <View style={styles.emptySpace} />
              </View>
            </>
          )}
        </ScrollView>
      </ErrorBoundary>
    );
  };

  showNotificationModal = (showNotificationPrompt) => {
    const { navigation } = this.props;
    navigation.push('NotificationModal', {
      showNotificationPrompt,
      type: NOTIFICATION_REQUEST_MODAL_TYPE.FOXY_EDGE,
    });
  };

  displayNotificationRequestModal = () => {
    const { initial_app_opened_at } = this.props;
    Utility.canDisplayNotificationRequestModal(
      this.props.lastNotificationModalDisplayTime,
      'foxyEdge',
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(showNotificationPrompt);
        }
      },
      initial_app_opened_at,
      true,
    );
  };

  onPressContinueWhereYouLeft = () => {
    const { addContinueAction } = this.props;
    addContinueAction('', '', '');
  };

  navigateToLoyaltyPage = () => {
    const { membership_cohort, navigation } = this.props;
    AnalyticsManager.logEvent('item_action', {
      type: 'activate_membership',
      previous_screen: `foxy_edge_${membership_cohort}`,
    });
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'LoyaltyPlans',
    });
  };

  render() {
    const {
      todayDeals: { is_upgradable = false } = {},
      continueSlug = '',
      continueImage = '',
      continueScreenType = '',
      membership_cohort,
    } = this.props;
    const {
      loading,
      refreshing,
      serverError,
    } = this.state;

    const showUpgradeTray = is_upgradable && !loading && !refreshing;
    const { non_member: nonMemberCta, expired: expiredCta } =
      Utility.jsonParser(
        RemoteConfig.getValue(REMOTE_CONFIG_KEYS.loyalty_plan_tab_cta),
      );

    const showEdgeCongratsModal = !loading && !refreshing;
    const isLoading = loading || refreshing;
    return (
      <ReactNavigationPerformanceView
        screenName='the_edge'
        interactive={!isLoading}
      >
        <this.renderMembershipScreen />
        {Utility.isPresent(continueSlug) && (
          <ContinueWhereYouLeftTray
            screenName={`foxy_edge_${membership_cohort}`}
            continueSlug={continueSlug}
            continueImage={continueImage}
            continueScreenType={continueScreenType}
            onPressContinueWhereYouLeft={this.onPressContinueWhereYouLeft}
          />
        )}
        {['expired', 'non_member'].includes(membership_cohort) && !serverError && (
          <View style={styles.buttonContainer}>
            <FoxyShadowButton
              width={Utility.getScreenWidth() - 24}
              title={
                membership_cohort === 'non_member' ? nonMemberCta : expiredCta
              }
              onPress={this.navigateToLoyaltyPage}
              style={styles.button}
              firstColor={colors.black}
              secondColor={colors.black}
              underlayColor={colors.subtitle}
            />
          </View>
        )}
        {Utility.isBlank(continueSlug) &&
          showUpgradeTray &&
          !['expired', 'non_member'].includes(membership_cohort) && (
            <View style={styles.membershipTrayStyle}>
              <UpgradeMembershipTray
                screenName={`foxy_edge_${membership_cohort}`}
              />
            </View>
          )}
        {showEdgeCongratsModal && (
          <EdgeCongratsModal
            displayNotificationRequestModal={
              this.displayNotificationRequestModal
            }
          />
        )}
      </ReactNavigationPerformanceView>
    );
  }
}

const mapStateToProps = (state) => ({
  todayDeals: state.todayDeals,
  membership_cohort: state.todayDeals.membership_cohort,
  selfie_image_url: state.UserAccountInfo.profile.selfie_image_url,
  showCongratsModalForEdge: state.UserAccountInfo.showCongratsModalForEdge,
  initial_app_opened_at: state.UserAccountInfo.initial_app_opened_at,
  continueSlug: state.UserAccountInfo?.continueAction?.continueSlug,
  continueImage: state.UserAccountInfo?.continueAction?.continueImage,
  continueScreenType: state.UserAccountInfo?.continueAction?.continueScreenType,
  showEdgePendingIndicator: state.UserAccountInfo.showEdgePendingIndicator,
  address: state.UserAccountInfo.addresses,
  authToken: state.UserAccountInfo.authToken,
  date_of_birth: state.UserAccountInfo.profile.date_of_birth,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getListData,
      getUserTodayDeals,
      setShowEdgePendingIndicator,
      addContinueAction,
      getMemberPage,
    },
    dispatch,
  ),
});

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    backgroundColor: isWeb() ? colors.background : colors.white,
  },
  scrollViewContent: {
    paddingBottom: 50,
  },
  background: {
    backgroundColor: colors.foxyGreen,
    alignItems: 'center',
    flex: 1,
  },
  socialMediaTagPlaceHolderContainer: {
    flex: 1,
    flexDirection: 'row',
    marginVertical: 32,
  },
  emptySpace: {
    height: 60,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.background,
  },
  loader: {
    marginVertical: 8,
  },
  tagPlaceHolderContainer: {
    flex: 1,
    flexDirection: 'row',
    marginVertical: 12,
  },
  introContainer: {
    paddingTop: 24,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.background,
    borderBottomRightRadius: 50,
    paddingHorizontal: 12,
    paddingBottom: 52,
  },
  cardContainer: {
    backgroundColor: colors.background,
    alignItems: 'center',
    paddingHorizontal: 24,
    marginBottom: 30,
    borderBottomRightRadius: 200,
    zIndex: 100,
  },
  introBackground: {
    alignItems: 'center',
    backgroundColor: colors.white,
    padding: 12,
  },
  headerContainer: {
    backgroundColor: colors.background,
    borderBottomRightRadius: 50,
  },
  profilePictureContainer: { alignItems: 'center' },
  profileImage: {
    height: 96,
    width: 96,
    borderRadius: 48,
  },
  videoPlayIcon: { height: 24, width: 24, marginTop: -14 },
  influencerName: {
    color: colors.black,
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
    marginTop: 24,
  },
  namePlaceHolder: {
    height: 24,
    width: Utility.getScreenWidth() - 150,
    marginTop: 24,
    borderRadius: 4,
  },
  influencerBio: {
    marginTop: 8,
    textAlign: 'center',
    fontSize: size.h3,
    color: colors.black,
  },
  tagPlaceHolder: {
    height: 24,
    width: 112,
    borderRadius: 21,
    marginHorizontal: 4,
  },
  socialTagPlaceHolder: {
    height: 32,
    width: 32,
    borderRadius: 4,
    marginHorizontal: 8,
  },
  socialAccountRow: {
    flexDirection: 'row',
    backgroundColor: colors.foxyGreen,
    height: 74,
    paddingHorizontal: 8,
    marginBottom: 12,
    borderRadius: 37,
    alignItems: 'center',
    justifyContent: 'center',
  },
  disableVerticalOrientationButton: {
    flexDirection: 'row',
    height: 44,
    paddingHorizontal: 12,
    marginBottom: 16,
    borderRadius: 60,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'grey',
    borderWidth: 1,
  },
  disableVerticalOrientationButtonCta: {
    color: colors.black,
    fontFamily: 'Roboto-Medium',
  },
  disableVerticalOrientationButtonIcon: {
    height: 14,
    width: 14,
    tintColor: colors.black,
    marginLeft: 6,
    marginTop: 1,
  },
  rowIcon: { height: 52, width: 52, borderRadius: 32 },
  rowDP: {
    height: 52,
    width: 52,
    borderRadius: 32,
    borderWidth: 2,
    borderColor: colors.white,
  },
  socialAccountTagList: {
    borderBottomRightRadius: 100,
  },
  socialAccountTag: {
    backgroundColor: colors.foxyGreen,
    height: 42,
    width: 42,
    marginHorizontal: 6,
    borderRadius: 21,
    alignItems: 'center',
    justifyContent: 'center',
  },
  disableHorizontalOrientationButton: {
    backgroundColor: colors.white,
    height: 41,
    width: 41,
    marginHorizontal: 6,
    borderRadius: 21,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: 'grey',
  },
  disableHorizontalOrientationButtonIcon: {
    height: 18,
    width: 18,
    tintColor: colors.black,
  },
  socialAccountIcon: { height: 32, width: 32, borderRadius: 16 },
  socialMediaCta: {
    backgroundColor: colors.black,
    paddingHorizontal: 12,
    borderRadius: 16,
    paddingVertical: 4,
    paddingBottom: 6,
    justifyContent: 'center',
    alignContent: 'center',
  },
  socialMediaCtaText: {
    color: colors.white,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
  },
  socialHandle: {
    flex: 1,
    marginHorizontal: 12,
    fontFamily: 'Roboto-Medium',
    color: colors.black,
    fontSize: size.h2,
  },
  rowListContainer: {
    paddingHorizontal: 12,
    paddingTop: 12,
    backgroundColor: colors.white,
    width: Utility.getScreenWidth(),
    borderRadius: 50,
  },
  iconListContainer: {
    paddingVertical: 12,
    backgroundColor: colors.white,
    borderRadius: 52,
  },
  bottomCurveLayer1: {
    height: 50,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.background,
  },
  bottomCurveLayer2: {
    height: 50,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    marginTop: -50,
    borderTopLeftRadius: 200,
  },
  shareButton: {
    flex: 1,
    height: 32,
    width: 32,
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginRight: 12,
    marginTop: Utility.isIOS() ? Utility.topInset + 4 : 12,
  },
  shareIcon: { height: 24, width: 24 },
  tagsContainer: {
    // flex: 1,
    width: Utility.getScreenWidth(),
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 12,
  },
  tag: {
    flexDirection: 'row',
    height: 22,
    borderRadius: 12,
    margin: 4,
    paddingHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tagIcon: { height: 14, width: 14, marginRight: 8 },
  tagText: {
    fontFamily: 'Roboto',
    fontSize: size.h3_5,
    color: colors.black,
  },
  registerOneLink: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: Utility.isIOS() ? Utility.topInset + 98 : 98,
    marginTop: 12,
  },
  oneLinkCta: {
    height: 64,
    width: 192,
    borderRadius: 32,
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: { height: 26, width: 55, resizeMode: 'contain' },
  oneLinkText: {
    fontSize: size.h0,
    fontFamily: 'Roboto-Bold',
    color: colors.black,
  },
  ctaText: {
    fontSize: size.h3,
    fontFamily: 'Roboto',
    marginTop: 4,
    color: colors.black,
    marginLeft: 6,
  },
  accountListContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: Utility.getScreenWidth(),
    marginBottom: 4,
    padding: 4,
  },
  scrollContainer: {
    alignItems: 'center',
  },
  errorCardContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: Utility.getScreenWidth(),
    padding: 24,
    backgroundColor: colors.white,
    marginBottom: 12,
  },
  heading: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    color: colors.foxyBlack,
    marginBottom: 8,
    marginTop: 16,
  },
  subHeading: {
    fontFamily: 'Roboto',
    fontSize: size.h3,
    textAlign: 'center',
    color: colors.black,
  },
  followingButtonContainer: {
    height: 28,
    width: 152,
    borderRadius: 16,
    backgroundColor: 'white',
    marginVertical: 12,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderWidth: 1,
  },
  followingButtonIcon: {
    height: 16,
    width: 16,
    marginRight: 8,
    tintColor: 'black',
  },
  followingButtonText: { fontFamily: 'Roboto-Medium', color: 'black' },
  followButtonContainer: {
    height: 28,
    width: 152,
    borderRadius: 16,
    backgroundColor: 'black',
    marginVertical: 12,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  followButtonIcon: {
    height: 16,
    width: 16,
    marginRight: 8,
  },
  followButtonText: { fontFamily: 'Roboto-Medium', color: 'white' },
  introCardFooterContainer: { marginBottom: -14, backgroundColor: 'red' },
  errorCardHeight: { height: 120 },
  shareIconContainer: {
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  shareHeaderContainer: {
    alignItems: 'center',
    backgroundColor: colors.background,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 12,
  },
  buttonContainer: {
    width: Utility.getScreenWidth(),
    backgroundColor: '#ffffffb3',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: Utility.isIOS() ? 20 : 8,
    zIndex: 100,
    alignSelf: 'center',
  },
  button: {
    height: 44,
  },
  expiredOverlay: {
    position: 'absolute',
    bottom: 0,
    width: Utility.getScreenWidth(),
    zIndex: 100,
    height: '100%',
    flex: 1,
  },
  membershipTrayStyle: {
    bottom: 52,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(FoxyClub),
);
