import React, { PureComponent } from 'react';
import { TextField } from 'react-native-material-textfield';
import colors from '../../theme/Colors';
import { StyleSheet, Text } from 'react-native';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';

class BirthDateTextField extends PureComponent {
  componentDidMount() {
    const { openOnLoad = false } = this.props;
    if (openOnLoad) {
      setTimeout(this.onFocus, 500);
    }
  }

  onFocus = () => {
    const { navigation, onSubmit } = this.props;
    navigation.navigate('DateSelectionModal', {
      onDateChange: this.onDateChange,
      onSubmit,
    });
  };

  onDateChange = (date) => {
    this.selectedDate = date;
  };

  render() {
    const { date, error = null } = this.props;
    const birthDateText = date ? Utility.formatDateWithYear(date) : '';
    return (
      <>
        <Text style={styles.title}>Your birthdate</Text>
        <DebouncedTouchableOpacity onPress={this.onFocus}>
          <TextField
            placeholder={'dd/mm/yyyy'}
            lineWidth={1}
            activeLineWidth={1}
            blurOnSubmit={false}
            value={birthDateText}
            label={'Birth Date'}
            labelTextStyle={styles.labelStyle}
            style={styles.dateStyle}
            placeholderTextColor={colors.silver}
            hideLabel={false}
            inputContainerStyle={{ borderBottomColor: colors.border }}
            returnKeyType="done"
            error={error}
            editable={false}
            tintColor={colors.silver}
          />
        </DebouncedTouchableOpacity>
      </>
    );
  }
}

export default BirthDateTextField;

const styles = StyleSheet.create({
  labelStyle: {
    color: colors.subtitle,
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
  },
  title: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h2,
    marginTop: 16,
  },
});
