// Dependencies

import React, { PureComponent } from 'react';
import { View, ImageBackground, Text, Image } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import ProductCardStyles from './styles/styles';
import { LAYOUT, REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { withEither, withMaybe } from '../../lib/Monads';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { AnalyticsUtilty } from '../../analytics';
import FastImageView from '../FastImageView';
import RatingContainer from '../productRatings/RatingContainer';
import foxyImages from '../../theme/Images';
import FullWidthShimmer from '../../lib/FullWidthShimmer';
import images from '../../theme/Images';
import RemoteConfig from '../../utils/RemoteConfig';
import Config from '../../libraries/ReactNativeConfig';
import AppConfig from '../../config/AppConfig';
import { isDesktop } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { getStoreRef } from '../../store/StoreUtility';
import { isPresent } from '../../utils/BooleanUtility';

class ProductImageAndRating extends PureComponent {
  constructor(props) {
    super(props);
    const {
      layout,
      size,
      matchedProduct,
      itemData: { product_image_aspect_shape = '', type = '' } = {},
      transparentImageUrl = '',
      fancyHeroCard,
    } = this.props;
    let layoutSize = size;
    if (Utility.isBlank(layoutSize)) {
      layoutSize = 'defaultSize';
    }
    const { imageheight = 0, imagewidth } = PRODUCT.CARD?.[layout]?.[layoutSize] || {};

    this.resizeMode =
      product_image_aspect_shape === 'square' && layoutSize === 'large'
        ? 'cover'
        : 'contain';

    if (
      (matchedProduct && layout === LAYOUT.RAIL) ||
      ((layout === LAYOUT.RAIL || layout === LAYOUT.GRID) &&
        Utility.isBlank(transparentImageUrl))
    ) {
      this.imageStyle = ProductCardStyles.fullHeight;
      this.extraMarginForRail = ProductCardStyles.marginTop;
    } else if (matchedProduct) {
      this.imageStyle = ProductCardStyles.fullHeightWithPadding;
    } else if (
      (layout === LAYOUT.VERTICALRAIL || layout === LAYOUT.LIST) &&
      product_image_aspect_shape === 'vertical'
    ) {
      this.imageStyle = { height: 120, width: 90 };
    } else if (fancyHeroCard) {
      this.imageStyle = { height: 200, marginVertical: 12 };
    } else {
      this.imageStyle = { height: imageheight - 24, marginVertical: 12 };
    }
    this.productDealsTagData = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.product_deals_tag_data),
    );
    this.memoizedLabelImageStyleCache = {};
  }

  listOrVerticalRailCondition = (props) =>
    !(props.layout === LAYOUT.RAIL || props.layout === LAYOUT.GRID);

  imageError = () => {
    const { id, imageUrl, name, navigation, onScreen = '' } = this.props;
    AnalyticsUtilty.fireImageErrorAnalytics(
      id,
      imageUrl,
      name,
      'product',
      onScreen,
    );
  };

  RatingComponent = (props) => {
    const { layout, rating, previousScreen } = props;
    if (previousScreen === 'free_items' || Utility.isBlank(rating)) {
      return null;
    }

    return (
      <RatingContainer
        rating={rating}
        styles={ProductCardStyles.ratingRectangle}
      />
    );
  };

  conditionalRating = withMaybe(this.listOrVerticalRailCondition)(
    this.RatingComponent,
  );

  renderTagShimmer = () => {
    const { layout = '' } = this.props;
    const labelShimmerStyle =
      layout === 'slimGrid'
        ? ProductCardStyles.smallLabelShimmer
        : ProductCardStyles.labelShimmer;
    return <FullWidthShimmer style={labelShimmerStyle} speed={2000} />;
  };

  memoizedLabelImageStyle = () => {
    const styles = ProductCardStyles;
    return (width, height) => {
      if (!this.memoizedLabelImageStyleCache[`${width}${height}`]) {
        this.memoizedLabelImageStyleCache[`${width}${height}`] = [
          styles.labelImageBackground,
          { width, height },
        ];
      }

      return this.memoizedLabelImageStyleCache[`${width}${height}`];
    };
  };

  flashDealTag = (props) => {
    const { isVariant, layout = '' } = this.props;
    const { image, title, showRedDot = false, imageWidth, imageHeight } = props;
    const styles = ProductCardStyles;
    let labelOverflowContainer = isVariant
      ? styles.labelContainerVariant
      : styles.labelContainer;
    let tagImageWidth = imageWidth;
    let tagImageHeight = imageHeight;
    let labelTextStyle = styles.labelTextExtraMarginStyle;
    if (layout === 'slimGrid') {
      tagImageWidth = 72;
      tagImageHeight = 14;
      labelTextStyle = styles.smallLabelTextExtraMarginStyle;
      labelOverflowContainer = styles.slimGridLabelOverflowContainer;
    }
    if (isDesktop()) {
      labelOverflowContainer = { ...labelOverflowContainer, left: -10 };
    }
    return (
      <View style={labelOverflowContainer}>
        <ImageBackground
          source={image}
          style={this.memoizedLabelImageStyle()(tagImageWidth, tagImageHeight)}
          resizeMode='stretch'
        />
        <View style={styles.labelTextContainer}>
          {showRedDot && <View style={styles.redDot} />}
          <Text style={labelTextStyle} numberOfLines={1} ellipsizeMode='tail'>{title}</Text>
          <this.renderTagShimmer />
        </View>
      </View>
    );
  };

  productCardTag = (props) => {
    const {
      skuToCheck,
      itemData: {
        variants_details: {
          group_buying_price: productGroupBuyingPrice = '',
        } = {},
        sku: { group_buying_price: skuGroupBuyingPrice = '' } = {},
        label: customLabel = '',
      } = {},
    } = props;
    const { hideTag } = this.props;

    if (hideTag) {
      return null;
    }
    const skus = this.getTodayDealSkus();
    const isGroupBuyingPresent =
      Utility.isPresent(productGroupBuyingPrice) ||
      Utility.isPresent(skuGroupBuyingPrice);

    const {
      flash_deal_v2: {
        image: flashDealImage = '',
        image_width: flashDealImageWidth,
        image_height: flashDealImageHeight,
        label_name: flashDealLabelName = '',
      } = {},
      group_deal: {
        image: groupDealImage = '',
        image_width: groupDealImageWidth,
        image_height: groupDealImageHeight,
        label_name: groupDealLabelName = '',
      } = {},
      custom_label: {
        image: customLabelImage = '',
        image_width: customLabelImageWidth,
        image_height: customLabelImageHeight,
      },
    } = this.productDealsTagData;

    if (
      !isGroupBuyingPresent &&
      Utility.isBlank(customLabel) &&
      Utility.isBlank(skus[`${skuToCheck}`])
    )
      return null;

    let tagTitle = Utility.isPresent(skus[`${skuToCheck}`])
      ? flashDealLabelName
      : groupDealLabelName;
    let labelImage = Utility.isPresent(skus[`${skuToCheck}`])
      ? { uri: flashDealImage }
      : { uri: groupDealImage };
    let labelImageWidth = Utility.isPresent(skus[`${skuToCheck}`])
      ? flashDealImageWidth
      : groupDealImageWidth;
    let labelImageHeight = Utility.isPresent(skus[`${skuToCheck}`])
      ? flashDealImageHeight
      : groupDealImageHeight;
    let showRedDot = false;

    if (Utility.isPresent(customLabel)) {
      labelImage = { uri: customLabelImage };
      tagTitle = customLabel;
      labelImageWidth = customLabelImageWidth;
      labelImageHeight = customLabelImageHeight;
      showRedDot = true;
    }

    return (
      <this.flashDealTag
        title={tagTitle}
        image={labelImage}
        showRedDot={showRedDot}
        imageWidth={labelImageWidth}
        imageHeight={labelImageHeight}
      />
    );
  };

  rating = (props) => {
    const { ratingsPosition, showWishlistIcon = '0' } = props;
    if (
      AppConfig.getBooleanValue(Config.HIDE_RATING) ||
      ratingsPosition === 'bottom' ||
      ratingsPosition === 'hide' ||
      showWishlistIcon === '1'
    ) {
      return null;
    }
    const {
      rating,
      layout,
      size = 'defaultSize',
      previousScreen,
      disabled = false,
      showEditButton,
    } = props;

    if (
      Utility.isBlank(rating) ||
      rating === null ||
      rating === '' ||
      disabled ||
      showEditButton ||
      !rating
    ) {
      return null;
    }
    return (
      <this.conditionalRating
        size={size}
        rating={rating}
        layout={layout}
        previousScreen={previousScreen}
      />
    );
  };

  lockIcon = ({ disabled }) => {
    if (!disabled) return null;
    return (
      <View styles={ProductCardStyles.ratingRectangle}>
        <Image
          source={foxyImages.lock}
          style={ProductCardStyles.lockImageStyle}
        />
      </View>
    );
  };

  getTodayDealSkus = () => {
    const state = getStoreRef().getState();
    const { todayDeals: { skus = {} } = {} } = state;
    return skus;
  }

  render() {
    const {
      rating,
      layout,
      size = 'defaultSize',
      images,
      imageUrl,
      image_webp_url,
      webp_images,
      image_url,
      cardImage,
      previousScreen,
      disabled = false,
      matchedProduct = false,
      isVariant,
      itemData: {
        product_image_aspect_shape = '',
        type = '',
        card_image: primaryProductImage = '',
      } = {},
      itemData = {},
      showEditButton,
      transparentImageUrl = '',
      display = '',
      backgroundImageUrl = '',
      ratingsPosition = '',
      showWishlistIcon = '',
    } = this.props;

    const { variants_details: { principal_sku_id } = {}, sku_id } = itemData;

    const skuToCheck = principal_sku_id || sku_id;

    let layoutSize = size;
    if (Utility.isBlank(layoutSize)) {
      layoutSize = 'defaultSize';
    }

    const { imageheight = 0, imagewidth } = PRODUCT.CARD?.[layout]?.[layoutSize] || {};
    let image = Utility.getImageUrlForProducts(images, imageUrl, type);

    if (Utility.isPresent(cardImage)) {
      image = cardImage;
    }

    if (Utility.isPresent(image_url)) {
      image = image_url;
    }

    if (Utility.isBlank(image) || image === '') {
      return null;
    }
    let newUrl = getMinifiedImage(
      image,
      imagewidth,
      imageheight,
    );

    if (matchedProduct && product_image_aspect_shape !== 'vertical') {
      this.imageStyle = [
        {
          height: '100%',
        },
      ];
    }

    if (isPresent(primaryProductImage)) {
      newUrl = primaryProductImage;
    }

    if (Utility.isPresent(transparentImageUrl)) {
      newUrl = transparentImageUrl;
      this.imageStyle = [
        this.imageStyle,
        {
          flex: 1,
          marginVertical: 12,
        },
      ];
    }

    const isFancyCard =
      display === 'fancy_rail' || display === 'fancy_hero_rail';

    if (isFancyCard) {
      this.imageStyle = [
        this.imageStyle,
        {
          flex: 1,
          marginVertical: 12,
          zIndex: -2,
        },
      ];
    }

    if (layout === 'slimGrid') {
      this.imageStyle = [this.imageStyle, { overflow: 'visible' }];
    }

    return (
      <FastImageView
        style={[this.imageStyle, isDesktop() && { height: '100%' }]}
        source={getMinifiedImage(newUrl, ProductCardStyles.imageWidth)}
        resizeMode={this.resizeMode}
        onError={this.imageError}
      >
        <View style={this.extraMarginForRail}>
          <this.rating
            rating={rating}
            layout={layout}
            size={size}
            previousScreen={previousScreen}
            disabled={disabled || isFancyCard}
            showEditButton={showEditButton}
            ratingsPosition={ratingsPosition}
            showWishlistIcon={showWishlistIcon}
          />
          <this.lockIcon disabled={disabled} />
          <this.productCardTag
            skuToCheck={skuToCheck}
            itemData={itemData}
          />
        </View>
        {Utility.isPresent(backgroundImageUrl) && (
          <FastImageView
            source={getMinifiedImage(
              backgroundImageUrl,
              ProductCardStyles.imageWidth,
            )}
            style={this.imageStyle}
            resizeMode='contain'
          />
        )}
      </FastImageView>
    );
  }
}

// PropTypes
ProductImageAndRating.propTypes = {
  id: PropTypes.string,
  layout: PropTypes.string,
  size: PropTypes.string,
  imageUrl: PropTypes.string,
  images: PropTypes.array,
};

export default withNavigation(ProductImageAndRating);
