import React, { useState, useEffect } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import DateTimePicker from 'react-native-ui-datepicker';
import dayjs from 'dayjs';
import FoxyButton from '../shared/FoxyButton';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { useNavigation } from '@react-navigation/native';
import images from '../../theme/Images';
import size from '../../theme/Fonts';
import ModalContainer from './ModalContainer';
import withNavigation from '../../utils/WithNavigation';
import { connect } from 'react-redux';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isDesktop } from '../../utils/BooleanUtility';

const DateSelectionModal = (props) => {
  const navigation = useNavigation();
  const { date_of_birth } = props;

  const initialDate = date_of_birth ? dayjs(date_of_birth).toDate() : dayjs('2000-01-01').toDate();
  const [selectedDate, setSelectedDate] = useState(initialDate);

  useEffect(() => {
    if (date_of_birth) {
      setSelectedDate(dayjs(date_of_birth).toDate());
    }
  }, [date_of_birth]);

  function onDateChange(date) {
    setSelectedDate(date);
  }

  function onConfirmPress() {
    const { route: { params = {} } = {} } = props;
    const { onSubmit = () => {} } = params;
    onSubmit(selectedDate);
    dismissModal();
  }

  function dismissModal() {
    navigation.pop();
  }


  return (
    <ModalContainer showModalInCenter={isDesktop()}>
      <View style={styles.modalContainer}>
        <View style={styles.crossContainer}>
          <TouchableOpacity
            onPress={dismissModal}
            hitSlop={Utility.getHitSlop()}
          >
            <Image source={images.salon.cross_black_2x} style={styles.cross} />
          </TouchableOpacity>
        </View>
        <DateTimePicker
          mode="single"
          date={dayjs(selectedDate)}
          onChange={(params) => onDateChange(params.date.toDate())}
          maximumDate={dayjs().toDate()}
          style={styles.datePicker}
          headerContainerStyle={styles.datePickerHeader}
        />
        <FoxyButton
          title={'Done'}
          actionButtonColor={colors.black}
          onActionPress={onConfirmPress}
          layoutStyle={styles.buttonLayoutStyle}
        />
      </View>
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  date_of_birth: state.UserAccountInfo.profile.date_of_birth,
});

export default withNavigation(
  connect(mapStateToProps, null)(DateSelectionModal),
);

const styles = StyleSheet.create({
  modalContainer: {
    width: getScreenWidth(),
    paddingVertical: 24,
    backgroundColor: colors.white,
    borderRadius: 16,
  },
  crossContainer: {
    top: 12,
    right: 12,
    zIndex: 1,
    position: 'absolute',
  },
  cross: {
    height: 16,
    width: 16,
    tintColor: colors.black,
  },
  datePicker: {
    width: Utility.getScreenWidth() - 24,
    marginBottom: 80,
    alignSelf: 'center',
  },
  dateStyle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h2,
  },
  buttonLayoutStyle: {
    bottom: 8,
    left: 12,
    right: 12,
  },
  datePickerHeader: {
    paddingTop: 12,
  },
});