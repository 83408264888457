// Dependencies
import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StarRating } from '../../lib/StarRating';
import { ProductRatingStyles } from './styles';
import Utility from '../../utils/Utility';
import { rateProduct } from '../../actions/ActionTypes';
import colors from '../../theme/Colors';
import { AnalyticsManager } from '../../analytics';
import RatingContainer from '../productRatings/RatingContainer';
import { isPresent } from '../../utils/BooleanUtility';

class RateProduct extends Component {
  rateTextHeader = {
    0: 'Rate this Product',
    1: 'HATE IT',
    2: 'IT WAS BAD',
    3: 'COULD BE BETTER',
    4: 'LIKED IT',
    5: 'LOVED IT',
  };

  onFinishRating = (rating) => {
    const {
      slug,
      rateProduct,
      id,
      itemData,
      selectedVariant,
      selectedVariantId,
      artistHandle = '',
    } = this.props;

    const skuId = Utility.getSkuIdForProduct(itemData, selectedVariant);
    const data = {
      id: skuId || id,
      rating: {
        star: rating,
      },
      artistHandle,
    };
    rateProduct(data, (success, response) => {
      AnalyticsManager.logEvent('rate_product', {
        sku_id: skuId,
        product_id: itemData?.id,
        status: 'new',
        screen: 'product_detail',
        rating,
      });
      this.props.onReview(rating, response.id);
    });
  };

  getRatingText = () => {
    const { rating, heading = '' } = this.props;
    let ratingTextHeader = 'How do you rate this product';
    if (isPresent(heading)) {
      ratingTextHeader = heading;
    }
    if (rating) {
      ratingTextHeader = 'Your Rating';
    }
    return ratingTextHeader;
  };

  render() {
    const {
      isShopifyRating,
      layout_style,
      productRatings,
      id,
      reviewData = {},
      heading = 'How do you rate this product',
    } = this.props;
    let { rating } = this.props;

    const styles = ProductRatingStyles;
    if (isShopifyRating) {
      rating = productRatings[id];
    }
    return (
      <View style={styles.rateProductContainer}>
        {Utility.isBlank(layout_style) && (
          <View style={styles.rateTextContainer}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <RatingContainer
                rating={rating || '?'}
                showRatingStar
                styles={{ height: 18, marginRight: 12 }}
              />
              <Text style={styles.rateProductMessageText}>
                {this.getRatingText()}
              </Text>
            </View>
            <View style={styles.rateProductCard}>
              <StarRating
                rating={rating}
                count={5}
                defaultRating={0}
                size={38}
                onFinishRating={this.onFinishRating}
              />
            </View>
          </View>
        )}
        {layout_style === 'row' && (
          <View
            style={{
              flexDirection: 'row',
              paddingTop: 8,
              paddingBottom: 4,
              paddingLeft: 18,
              paddingRight: 18,
              marginBottom: 5,
            }}
          >
            {/* <View
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}
            > */}

            <Text style={styles.rateProductMessageText}>{heading}</Text>
            <View
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                flex: 1,
                alignSelf: 'flex-end',
              }}
            >
              <StarRating
                rating={rating}
                count={5}
                defaultRating={0}
                size={20}
                onFinishRating={this.onFinishRating}
                containrSize={150}
              />
            </View>
          </View>
        )}
        {layout_style === 'rowInDynamicElement' && (
          <View style={styles.rateProductCardForDynamicElement}>
            <Text style={styles.rateProductMessageText}>Add a review</Text>
            <StarRating
              rating={rating}
              count={5}
              defaultRating={0}
              size={20}
              containerSize={200}
              onFinishRating={this.onFinishRating}
            />
          </View>
        )}
        {/* <View
          style={{
            height: 12,
            width: Utility.getScreenWidth(),
            backgroundColor: colors.background,
          }}
        /> */}
      </View>
    );
  }
}

const mapStateToProps = (store, ownProps) => {
  const { itemData, selectedVariant } = ownProps;
  return {
    rating:
      !!store.UserAccountInfo.productRatings[
        Utility.getSkuIdForProduct(itemData, selectedVariant)
      ] &&
      store.UserAccountInfo.productRatings[
        Utility.getSkuIdForProduct(itemData, selectedVariant)
      ],
    productRatings: store.UserAccountInfo.productRatings,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ rateProduct }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RateProduct);
