const EventParameterValue = {
  SCREEN_NAME: {
    ARTIST_SIGNUP: 'Artist Signup',
    ARTIST_TYPE: 'Artist Type',
    FAVOURITE_BRANDS: 'Favourite Brands',
    YOUTUBE_CONNECT: 'Youtube Connect',
    INSTAGRAM_CONNECT: 'Instagram Connect',
    ARTIST_DASHBOARD: 'Artist Dashboard',
    EDIT_PROFILE: 'Edit Profile',
    ADDRESS_LIST: 'Address List',
    ADDRESS_DETAIL: 'Address Detail',
    UPLOAD_POST: 'Upload Post',
    YOUTUBE_ANALYTICS: 'YouTube Analytics',
    INSTAGRAM_ANALYTICS: 'Instagram Analytics',
    INSTAGRAM_VERIFICATION: 'Instagram Verification',
    SERVICE_SELECTION: 'Service Selection',
    SALON_SELECTION: 'Salon Selection',
    SERVICE_AREA_SELECTION: 'Service Area Selection',
    ACADEMY_SELECTION: 'Academy Selection',
    COURSE_SELECTION: 'Course Selection',
    STORE_SELECTION: 'Store Selection',
    MY_VIDEOS: 'My Videos',
    VIDEO_DETAIL: 'Video Detail',
    CAMPAIGN_LIST: 'Campaign List',
    CAMPAIGN_DETAIL: 'Campaign Detail',
    BOOKING_COMING_SOON: 'Booking Coming Soon',
    INVITE_AND_EARN: 'Invite and Earn',
    VIDEO_PLAYER: 'Video Player',
    ARTIST_TERMS_AND_CONDITIONS: 'Artist Terms and Conditions',
    ARTIST_PROPOSITION: 'ARTIST_PROPOSITION',
    FEED: 'Feed',
    STORE: 'Store',
    MY_COLLECTION: 'My Collection',
    HOME: 'home',
    PRODUCT_CARD: 'product_card',
    SALON_SEARCH: 'salon_search',
    SALON_AMOUNT: 'salon_amount',
    REVIEWS: 'reviews',
    ROUTINES_PAGE: 'routines_page',
    NEED_HELP: 'need_help',
    PRE_ROUTINE: 'pre_routine',
    DURING_ROUTINE: 'during_routine',
    POST_ROUTINE: 'post_routine',
  },
  MODAL_NAME: {
    PHONE_NUMBER_INPUT: 'Phone Number Input',
    OTP_VERIFICATION: 'OTP Verification',
    ARTIST_SIGNUP_SUCCESSFUL: 'Artist Signup Successful',
    UPLOAD_VIDEO: 'Upload Video',
    REQUEST_BOOST: 'Request Boost',
    REQUEST_REVIEW: 'Request Review',
    APP_REVIEW: 'App Review',
    UNFULLFILLED_OFFER: 'unfulfilled_offer',
    SUCCESS_OFFER: 'success_offer',
    BECOME_MEMBER_MODAL: 'become_member_modal',
    UPGRADE_EDGE_MODAL: 'upgrade',
  },
  INPUT_METHOD: {
    MANUAL: 'Manual',
    PASTED: 'Pasted',
    DEVICE_PROMPT: 'Device Prompt',
    AUTO_READ: 'Auto Read',
    AUTO_CLICK: 'Auto click',
    BACKGROUND: 'Background',
    TRUECALLER: 'truecaller',
  },
  MEDIUM: {
    SMS: 'SMS',
    WHATSAPP: 'Whatsapp',
    FACEBOOK: 'Facebook',
    INSTAGRAM: 'Instagram',
    YOUTUBE: 'Youtube',
    OTHER: 'Other',
    AUTOCOMPLETE: 'autocomplete',
    MANUAL: 'manual',
    OPTION_PILL: 'option_pill',
  },
  CARD_TITLE: {
    UPLOAD_VIDEO: 'Upload Video',
    YOUTUBE_ANALYTICS: 'Youtube Analytics',
    INSTAGRAM_ANALYTICS: 'Instagram Analytics',
    MY_PROFESSIONAL_PROFILE: 'My Professional Profile',
    MY_VIDEOS: 'My Videos',
    BRAND_COLLABS: 'Brand Collabs',
    GET_BOOKINGS: 'Get Bookings',
    INVITE_AND_EARN: 'Invite and Earn',
    TALK_TO_US: 'Talk to us',
    SELFIE: 'selfie',
    LOGIN: 'login',
  },
  ITEM_TYPE: {
    PRODUCT: 'product',
    MEDIA: 'media',
    ARTIST: 'artist',
    BRAND: 'brand',
    SALON: 'salon',
    CHECKLIST: 'checklist',
    FEEDBACK: 'feedback',
    UPGRADE_TRAY: 'upgrade_tray',
    MEMBERSHIP_TYPE: 'membership_type',
    WHATSAPP_JOIN: 'whatsapp_join',
    CONTINUE_TRAY: 'continue_tray',
    FAQ_FEEDBACK: 'faq_feedback',
    CONTACT_US: 'contact_us',
    OFFER_CARD: 'offer_card',
  },
  HINT_PROMPT_TYPES: {
    MOBILE: 'mobile',
    EMAIL: 'email',
  },
  SELFIE_REVIEW_CLICK_CASE: {
    SUCCESS: 'success',
    FAILURE: 'failure',
  },
  GENDER: {
    MALE: 'male',
    FEMALE: 'female',
  },
  SOURCE: {
    CART: 'cart',
    AUTOMATIC: 'automatic',
    SELFIE_REVIEW_SCREEN: 'Selfie Review Screen',
    RETRY_UPLOAD_MODAL: 'Retry Upload Modal',
    NON_PERSONALISED_HOMEPAGE: 'Non personalised homepage',
    FOLLOW_ARTIST: 'follow artist',
    SELFIE_CARD: 'Selfie card',
    MY_PROFILE: 'My Profile',
    LIKE_BUTTON: 'Like Button',
    LIKE_A_VIDEO: 'Like Video',
    MY_KIT: 'My kit',
    ARTIST_ONBOARDING: 'Artist Onboarding',
    NOTIFICATION: 'NOTIFICATION',
    MOENGAGE_NOTIFICATION: 'MOENGAGE_NOTIFICATION',
    CHIP_BUTTON: 'chip button',
    RETAKE_WHILE_SELFIE_ANALYSIS: 'Retake while selfie analysis',
    BACKGROUND_NOTIFICATION: 'Background notification',
    FOREGROUND_NOTIFICATION: 'Foreground notification',
    PROFILE_PAGE: 'profile_page',
    OTP_SCREEN: 'otp_screen',
    SALON_AMOUNT: 'salon_amount',
    HOMEPAGE: 'homepage',
    PRE_ROUTINE: 'pre_routine',
    EDGE_SELFIE_CARD: 'edge_selfie_card',
    ADDRESS_NUDGE: 'address_nudge'
  },
  PHONE_NUMBER_ERROR: {
    REGEX_ERROR: 'regex_error',
    PHONE_NUMBER_FORMATTING: 'phone_number_formatting',
  },
  STATE: {
    NON_LOGGED_IN_USER: 'Non logged in user',
    ANALYSIS_ERROR: 'Analysis error',
    UPLOAD_ERROR: 'Upload error',
    ANALYSING: 'Analysing',
    TRUECALLER: 'Truecaller',
    HINT_PROMPT: 'Hint prompt',
    LOGGED_IN_USER: 'Logged in user',
  },
  FLOW: {
    INITIAL: 'initial',
    UPGRADE: 'upgrade',
  },
  LINKS: {
    LINK_TYPE: {
      deeplink: 'deeplink',
      dynamicLink: 'dynamicLink',
    },
    STATUS: {
      recieved: 'received',
      mapped: 'mapped ',
      navigated: 'navigated',
    },
  },
  NOTIFICATION_REQUEST_MODAL: {
    ACTION: {
      MODAL_SHOWN: 'modal_shown',
      CLICK_YES: 'click_yes',
      CLICK_NO: 'click_no',
      TAP_OUTSIDE: 'tap_outside',
      CROSS: 'cross',
    },
  },
  GROUP_DEALS_REFERRALS: {
    GROUP_DEAL: 'group_deal',
    GROUP_DEAL_PRICE: 'group_deal_price',
    INVITE_CENTRE: 'invite_centre',
    INVITE_CONTACTS: 'invite_contacts',
    GROUP_DEAL_MODAL: 'group_deal_modal',
    COPY_COUPON: 'copy_coupon',
    SEE_HOW: 'see_how',
  },
  FOXY_EDGE: {
    VIEW_OTHER_OPTIONS: 'view_other_options',
  },
  CARDS: {
    SELECT_GENDER_CARD: 'select_gender_card',
    TAKE_SELFIE: 'take_selfie',
  },
  ERROR_PAGE: {
    CONNECTION_ERROR: 'connection_error',
    SERVER_ERROR: 'server_error',
    PAGE_NOT_FOUND: 'page_not_found',
  },
  ERROR_BUTTON_ACTION: {
    RETRY: 'retry',
    GO_TO_HOME: 'go_to_home',
  },
  PERMISSIONS: {
    notifications: 'notifications',
  },
  STORAGE_REQUEST_MODAL: {
    ACTION: {
      MODAL_SHOWN: 'modal_shown',
      CLICK_YES: 'click_yes',
      CLICK_NO: 'click_no',
      TAP_OUTSIDE: 'tap_outside',
      CROSS: 'cross',
    },
  },
};

export default EventParameterValue;
