import React from 'react';
import { View, Text, FlatList, StyleSheet, Platform } from 'react-native';
import { startCase, toLower } from 'lodash';
import LinearGradient from 'react-native-linear-gradient';
import { BlurView } from '../../libraries/ReactNativeCommunityBlur';
import colors from '../../theme/Colors';
import { getScreenHeight, getScreenWidth } from '../../utils/LayoutUtility';
import FastImageView from '../../components/FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { isBlank, isDesktop, isWeb } from '../../utils/BooleanUtility';

const Degree = ({ item = {} }) => {
  const { attributes: { title = '', certificate_thumbnail_url, institute = '' } = {} } = item;

  if (isBlank(certificate_thumbnail_url)) return null;
  const gradientColors = ['#00000000', '#00000FFF'];
  return (
    <>
      <View style={styles.degreeContainer}>
        <FastImageView
          resizeMode='cover' // Changed to 'contain' to show the whole image
          source={getMinifiedImage(certificate_thumbnail_url, styles.certificate.width)}
          style={styles.certificate}
        />
        <LinearGradient colors={gradientColors} style={styles.infoContainer}>
          <Text numberOfLines={1} style={styles.title}>
            {title}
          </Text>
          <Text ellipsizeMode='tail' numberOfLines={1} style={styles.specialization}>
            {`${startCase(toLower(institute))}`}
          </Text>
        </LinearGradient>
        <BlurView style={styles.blurView} blurRadius={1} blurType={'light'} blurAmount={0.5} />
      </View>
    </>
  );
};


const Qualifications = (props) => {
  const {
    qualifications: { data = [] },
  } = props;
  if (isBlank(data)) return null;
  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Text style={styles.headerTitle}>Qualifications</Text>
        <Text style={styles.headerSubtitle}>
          Check out the achievements by your Dr.
        </Text>
      </View>
      <View style={styles.qualificationContainer}>
        <FlatList
          data={data}
          renderItem={({ item }) => <Degree item={item} />}
          keyExtractor={(item, index) => index.toString()}
          horizontal
          showsHorizontalScrollIndicator={false}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 18,
    width: getScreenWidth(),
    backgroundColor: colors.background,
    marginTop: 32,
  },
  qualificationContainer: {
    paddingLeft: 6,
  },
  text: {
    fontSize: 14,
    color: colors.foxyBlack,
  },
  headerTitle: {
    fontSize: 18,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
  },
  headerSubtitle: {
    fontSize: 14,
    color: colors.subtitle,
    fontFamily: 'Roboto-Regular',
  },
  headerContainer: {
    padding: 12,
  },
  certificate: {
    height: 190,
    width: isDesktop() ? getScreenWidth() / 4.8 : getScreenWidth() / 1.7 - 30,
    alignSelf: 'center',
  },
  degreeContainer: {
    alignSelf: 'center',
    marginHorizontal: 6,
    width: isDesktop() ? getScreenWidth() / 4.5 : getScreenWidth() / 1.7 - 20,
  },
  infoContainer: {
    position: 'absolute',
    alignItems: 'center',
    paddingVertical: 4,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    width: isDesktop() ? getScreenWidth() / 4.5 - 2 : getScreenWidth() / 1.7 - 18,
    justifyContent: 'center',
    alignSelf: 'center',
    // right: 6,
    bottom: 0,
    zIndex: 1000,
  },
  title: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.white,
    paddingHorizontal: isWeb() ? 10 : 8,
    alignSelf: 'flex-start',
    paddingTop: 4,
  },
  specialization: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.white,
    textAlign: 'center',
    height: 30,
    width: getScreenWidth() / 1.7 - 20,
    paddingHorizontal: isWeb() ? 10 : 8,
    paddingTop: 4,
    alignSelf: 'flex-start',
  },
  blurView: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    height: 190,
    width: isDesktop() ? getScreenWidth() / 4.8 : getScreenWidth() / 1.7 - 30,
    alignSelf: 'center',
  },
});

export default Qualifications;
