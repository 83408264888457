import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import size from '../../../theme/Fonts';
import Config from '../../../libraries/ReactNativeConfig';
import { isDesktop } from '../../../utils/BooleanUtility';

const sizeVariantPillHeight =
  ((Utility.getScreenWidth() - 120) * 34) / (7 * 44);

export const { ids: productInfoIds, styles: ProductInfoStyles } = StyleSheet.create({
  productImagesContainer: {
    backgroundColor: colors.white,
    overflow: 'hidden',
  },
  productImagesContainerWithTopMargin: {
    backgroundColor: colors.white,
    overflow: 'hidden',
    marginTop: (Utility.isIOS() ? 50 + Utility.topInset : 56) - 20,
  },
  productVariantTickImage: {
    height: 15,
    width: 18,
    resizeMode: 'contain',
    elevation: 1,
    shadowRadius: 0.5,
    shadowColor: colors.black,
    shadowOpacity: 0.2,
    zIndex: 1,
  },
  deliveryText: {
    lineHeight: 19,
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  smallSeparator: {
    height: 1,
    width: '100%',
    backgroundColor: colors.border,
    marginLeft: 12,
    marginRight: 12,
  },
  deliveryTextContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  contactNameStyle: {
    lineHeight: 16,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 2,
    marginLeft: 8,
    flex: 1,
  },
  productDetailSquareImage: {
    height: 1.1 * Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
    resizeMode: 'cover',
  },
  productDetailSquaresmallImage: {
    height: 0.66 * Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
    resizeMode: 'cover',
  },
  productDetailVerticalImage: {
    height: (4 / 3) * Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
    resizeMode: 'cover',
  },
  productDetailVerticalsmallImage: {
    height: (4 / 3) * 0.6 * Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
    resizeMode: 'cover',
  },
  variantModalSquareImage: {
    height: 0.6 * Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
  },
  variantModalVerticalImage: {
    height: Utility.getScreenWidth() / 1.8,
    width: Utility.getScreenWidth() / 2.4 - 8,
    marginRight: 8,
  },
  activeDotStyle: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    // elevation: 2,
    // shadowRadius: 1,
    // shadowColor: colors.black,
    // shadowOpacity: 0.1,
    // shadowOffset: { width: 1, height: 1 },
    backgroundColor: '#979BAA',
    opacity: 1,
    overflow: 'hidden',
  },
  dotStyle: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    // elevation: 2,
    // shadowRadius: 1,
    // shadowColor: colors.black,
    // shadowOpacity: 0.1,
    // shadowOffset: { width: 1, height: 1 },
    backgroundColor: '#DFE0E5',
    // opacity: 0.6,
  },
  swiperContainerStyle: { height: '100%', width: '100%' },
  triangleStyle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 5,
    borderRightWidth: 5,
    borderBottomWidth: 5,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#979797',
    transform: [{ rotate: '90deg' }],
    opacity: 0.6,
    margin: 2,
  },
  swiperContainer: {
    height: 1.1 * Utility.getScreenWidth(),
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  swiperContainerSquareImageWithTopInset: {
    height: 0.65 * Utility.getScreenWidth(),
    overflow: 'hidden',
    alignItems: 'center',
    marginTop: Utility.topInset,
  },
  swiperContainerSquareImageWithoutTopInset: {
    height: 0.65 * Utility.getScreenWidth(),
    overflow: 'hidden',
    alignItems: 'center',
    marginTop: 0,
  },
  swiperContainerVerticalImageWithTopInset: {
    height: Utility.getScreenWidth() / 1.8,
    overflow: 'hidden',
    alignItems: 'center',
    marginTop: Utility.topInset,
  },
  swiperContainerVerticalImageWithoutTopInset: {
    height: Utility.getScreenWidth() / 1.8,
    overflow: 'hidden',
    alignItems: 'center',
    marginTop: 0,
  },
  paginationStyle: {
    position: 'absolute',
    bottom: 8,
  },
  fullScreenVariantContainer: {
    width: Utility.getScreenWidth(),
    flex: 1,
    backgroundColor: colors.white,
    overflow: 'hidden',
  },
  navigationBackButtonContainer: {
    position: 'absolute',
    justifyContent: 'center',
    left: 16,
    bottom: 16,
  },
  navigationShareButtonContainer: {
    position: 'absolute',
    justifyContent: 'center',
    right: 90,
    bottom: 16,
  },
  fullScreenVariantShare: {
    position: 'absolute',
    justifyContent: 'center',
    right: 94,
    bottom: Utility.isIOS() ? 12 : 16,
  },
  likeButtonContainer: {
    position: 'absolute',
    right: 16,
    bottom: 16,
    justifyContent: 'center',
  },
  navbarIconDefaultStyle: {
    width: 20,
    height: 17.5,
    marginTop: 12,
    resizeMode: 'contain',
    tintColor: colors.foxyBlack,
  },
  navbarShareIconStyle: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  navigationHeader: {
    position: 'absolute',
    height: 50 + (Utility.isAndroid() ? 0 : Utility.topInset),
    width: Utility.getScreenWidth(),
    backgroundColor: '#fff',
    flexDirection: 'row',
    zIndex: 5,
  },
  productDetailsContainer: {
    height: 0.62 * Utility.getScreenWidth() + 80,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: 0,
    alignSelf: 'center',
  },
  shadesContainerView: {
    width: '100%',
    // flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 12,
    paddingRight: 6,
  },
  productImageStyle: {
    height: 96,
    width: 96,
    margin: 12,
    borderRadius: 4,
    overflow: 'hidden',
  },
  nameContainer: {
    // flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 80,
    marginLeft: 8,
  },
  brandNameStyle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.disabled,
    marginLeft: 4,
    marginTop: 12,
    marginRight: 12,
  },
  variantBrandNameStyle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.disabled,
    marginLeft: 4,
    marginRight: 12,
    marginTop: 8,
  },
  productNameStyle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 4,
    marginTop: 8,
    marginRight: 12,
  },
  variantNameStyle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 4,
    marginRight: 12,
    marginTop: 4,
  },
  selectedShadeContainer: {
    marginTop: 4,
    marginLeft: 16,
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  selectedShadeColor: {
    height: 18,
    width: 18,
    borderRadius: 3,
    overflow: 'hidden',
  },
  shadeText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.subtitle,
  },
  sectionListShadeText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.subtitle,
    marginLeft: 16,
  },
  shadeTextWithLeftMargin: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.subtitle,
    marginLeft: 12,
  },
  shadeTextWithMargin: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.subtitle,
    marginLeft: 12,
    marginTop: 24,
  },
  shadeTextWithBottomMargin: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.subtitle,
    marginLeft: 12,
    marginTop: 24,
    marginBottom: 12,
  },
  sizeHeadingContainer: {
    marginLeft: 12,
    marginTop: 6,
  },
  selectedShadeText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 8,
  },
  selectShadeText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
    alignSelf: 'flex-start',
    color: colors.foxyBlack,
    marginTop: 16,
    marginLeft: 16,
  },
  dockedShadeText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    alignSelf: 'center',
    marginLeft: 16,
    marginRight: 8,
  },
  shadeCardStyle: {
    marginTop: 8,
    marginBottom: 8,
    marginRight: 4,
    marginLeft: 4,
    height: 62,
    backgroundColor: 'white',
    borderRadius: 4,
    width: Utility.getDynamicWidth(2, 16),
    flexDirection: 'row',
    alignItems: 'center',
  },
  shadeCardStyleCart: {
    marginTop: 8,
    marginBottom: 8,
    marginRight: 4,
    marginLeft: 4,
    height: 62,
    backgroundColor: 'white',
    borderRadius: 4,
    width: isDesktop() ? '100%' : Utility.getDynamicWidth(1, 8),
    flexDirection: 'row',
    alignItems: 'center',
  },
  shadeImageBackgroundContainer: {
    height: 36,
    width: 36,
    borderRadius: 18,
    margin: 8,
    backgroundColor: 'white',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  variantListBottomPadding: {
    paddingBottom: 60, // added padding equivalent to the add to card button on the bottom of full screen modal.
  },
  productVariantImageBackground: {
    height: 36,
    width: 36,
    borderRadius: 18,
    margin: 8,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  shadeRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%',
  },
  shadeAndPriceContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  shadeColortext: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    alignSelf: 'center',
  },
  shadeColortextBold: {
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    alignSelf: 'center',
  },
  shadeColortextContainer: {
    overflow: 'hidden',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 24,
  },
  ratingRectangle: {
    position: 'absolute',
    flexDirection: 'row',
    width: 25,
    height: 18,
    borderRadius: 4,
    backgroundColor: colors.green,
    borderColor: colors.green,
    borderWidth: 1,
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    top: 4,
    right: 4,
  },
  ratingnumber: {
    fontSize: size.h5,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 11,
    marginTop: 2,
  },
  cardContainer: {
    backgroundColor: colors.background,
  },
  descriptionContainer: {
    backgroundColor: colors.background,
    marginBottom: 16,
  },
  recommendationContainer: {
    backgroundColor: colors.green,
  },
  cardContent: {
    margin: Utility.padding,
  },
  comparePriceCardContent: {
    marginLeft: Utility.padding,
    marginRight: Utility.padding,
    backgroundColor: colors.white,
    height: 60,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 12,
    marginTop: 12,
  },
  comparePriceSubheading: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.silver,
    marginTop: 4,
  },
  comparePriceTextContainer: {
    marginLeft: 12,
    marginRight: 8,
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  comparePriceHeadingContainer: { flexDirection: 'row', alignItems: 'center' },
  descriptionText: {
    margin: 8,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  descriptionHeaderText: {
    marginTop: 16,
    marginLeft: 8,
    marginRight: 8,
    fontSize: 16,
    lineHeight: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    alignContent: 'flex-start',
  },
  readMoreText: {
    color: colors.cta.lightBlue,
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginRight: 8,
    marginTop: 8,
  },
  priceContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  spStyle: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  mrpStyle: {
    color: colors.silver,
    fontSize: size.h4,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  discountRateStyle: {
    color: colors.foxyPink,
    fontSize: size.h4,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  recommendationBannerCard: {
    height: 32,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  recommendationBannerAvatar: {
    height: 28,
    width: 28,
    borderRadius: 14,
    borderWidth: 2,
    borderColor: colors.white,
    marginLeft: 12,
    overflow: 'hidden',
  },
  recommendationBannerAvatarShimmer: {
    height: 24,
    width: 24,
    borderRadius: 12,
    marginLeft: 12,
    overflow: 'hidden',
  },
  recommendationBannerTickContainer: {
    position: 'absolute',
    bottom: 4,
    left: 30,
    height: 12,
    width: 12,
    borderRadius: 6,
    borderWidth: 2,
    borderColor: colors.white,
    backgroundColor: colors.green,
    justifyContent: 'center',
    alignItems: 'center',
  },
  recommendationBannerTickImage: {
    height: 6,
    width: 6,
    resizeMode: 'contain',
  },
  recommendationTextContainer: {
    marginLeft: 16,
    marginRight: 0,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: Utility.getScreenWidth() - 2 * Utility.largePadding - 66,
  },
  recommendedTextHeader: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    color: colors.white,
  },
  recommendedBannerViewDetails: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.silver,
    marginTop: 8,
  },
  rightArrow: {
    position: 'absolute',
    tintColor: colors.white,
    alignSelf: 'center',
    right: 12,
  },
  variantScrollview: { flex: 1 },
  variantListContainer: {
    paddingBottom: Utility.isIOS()
      ? 88 + Utility.bottomInset
      : 60 + Utility.bottomInset,
  },
  variantDock: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  variantModal: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.translucent,
  },
  touchableContainer: {
    height: '100%',
    width: '100%',
  },
  variantModalContainer: {
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '100%',
    height: '88%',
    position: 'absolute',
    bottom: 0,
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
  },
  variantModalContainerForSize: {
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '100%',
    height: '77%',
    position: 'absolute',
    bottom: 0,
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
  },
  variantModalHeader: {
    height: 50,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  variantModalHeaderText: {
    lineHeight: 19,
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 12,
    color: colors.foxyBlack,
  },
  variantModalHeaderTextWithMargin: {
    lineHeight: 19,
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 12,
    color: colors.foxyBlack,
  },
  cancelButton: {
    position: 'absolute',
    top: 18,
    right: 16,
  },
  cancelStyle: {
    tintColor: 'black',
    height: 14,
    width: 14,
  },
  backButton: {
    tintColor: colors.black,
    height: 24,
    width: 24,
  },
  backButtonContainer: {
    position: 'absolute',
    top: 12,
    left: 16,
  },
  productTag: {
    position: 'absolute',
    left: 0,
    top: 20,
    maxWidth: 120,
    height: 64,
    flexDirection: 'row',
    alignItems: 'center',
  },
  tagIcon: {
    marginLeft: 4,
  },
  productTagText: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
    margin: 4,
  },
  moreInfoView: {
    backgroundColor: colors.white,
    padding: 12,
    paddingBottom: 0,
  },
  moreInfoText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
    marginLeft: 4,
  },
  expiryDateText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 4,
    marginTop: 6,
  },
  indicators: {
    backgroundColor: colors.foxyBlack,
  },
  tabLabels: {
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    fontSize: size.h4,
  },
  infoContainer: {
    padding: Utility.padding,
    paddingTop: 0,
    backgroundColor: colors.white,
  },
  deliveryAndReturnsContainer: {
    backgroundColor: colors.background,
  },
  productInfoCardHeaderText: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 12,
    marginTop: 28,
    marginBottom: 12,
  },
  deliveryInfoWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
  },
  deliveryInfoIcon: {
    height: 18,
    width: 18,
    tintColor: '#000',
    resizeMode: 'contain',
  },
  contactNameText: {
    lineHeight: 16,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 2,
    marginLeft: 8,
  },
  changePinStyle: {
    position: 'absolute',
    right: 15,
    alignSelf: 'center',
    height: 32,
    width: 90,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.border,
    flexDirection: 'row',
  },
  changePinStyleText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },

  chevronDown: {
    height: 13,
    width: 13,
    marginLeft: 2,
    marginTop: 10,
    tintColor: colors.cta.lightBlue,
  },
  imageSelectorRailList: {
    height: '100%',
    width: '100%',
    flexDirection: 'row',
  },
  dotsContainerOverImage: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12,
    marginLeft: 12,
    height: 8,
    bottom: 28,
  },
  dotsContainerBelowImage: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12,
    marginLeft: 12,
    height: 8,
    bottom: 0,
    marginVertical: 12,
  },
  dotsContentContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  dotsList: {
    height: 8,
    flexDirection: 'row',
    backgroundColor: 'blue',
  },
  imageSelectorRailListCoontentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 12,
    paddingLeft: 12,
  },
  fixedZoomableImage: {
    height: 0.62 * Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
  },
  zoomableImage: {
    height: '100%',
    width: Utility.getScreenWidth() - 16,
  },
  chosenBulletStyle: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    backgroundColor: 'white',
    opacity: 1,
    borderWidth: 0.5,
    borderColor: '#4E4E4E',
  },
  chosenTriangleStyle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderLeftWidth: 5,
    borderRightWidth: 5,
    borderBottomWidth: 5,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#979797',
    transform: [{ rotate: '90deg' }],
    margin: 2,
    backgroundColor: 'white',
    opacity: 1,
  },
  fullHeightWidth: {
    height: Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
  },

  carouselContainerVariantModal: {
    height: 0.6 * Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
  },

  productImagesRailContainer: {
    height: Utility.getScreenWidth() / 1.8,
    width: Utility.getScreenWidth(),
  },
  flatlistContentPadding: {
    paddingHorizontal: 8,
  },
  mediaControl: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignContent: 'center',
    justifyContent: 'center',
  },
  playerContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    height: 0.62 * Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
  },
  viewportContainer: {
    height: 0.62 * Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
  },
  addressEmptyStateContainerHeading: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 20,
  },
  addressEmptyStateText: {
    lineHeight: 16,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 2,
    marginLeft: 8,
    width: 0.6 * Utility.getScreenWidth(),
  },
  addressEmptyStateInfo: {
    lineHeight: 16,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginBottom: 2,
  },
  chooseVariantButton: {
    position: 'absolute',
    bottom: 16,
    left: 16,
    right: 16,
  },

  moreButtonContainer: {
    height: 30,
    backgroundColor: colors.white,
    width: Utility.getScreenWidth() - 30,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
  },

  moreButtonWrapper: {
    width: Utility.getScreenWidth() - 30,
    borderColor: colors.border,
    // borderTopWidth: 1
  },

  htmlContainer: {
    borderRadius: 4,
    // margin: 8,
    marginTop: 12,
    overflow: 'hidden',
    maxHeight: 240,
    width: '100%',
  },
  labelImage: {
    height: 20,
    width: 100,
    resizeMode: 'stretch',
    overflow: 'visible',
    left: -4,
  },
  labelContainer: {
    position: 'absolute',
    marginLeft: 8,
    top: 60,
    zIndex: 1,
    left: -4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelTextStyle: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3_5,
    color: colors.white,
    letterSpacing: 0,
    marginLeft: 6,
    zIndex: 1,
    position: 'absolute',
  },
  labelTextFlashDealStyle: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3_5,
    color: colors.black,
    letterSpacing: 0,
    marginLeft: 16,
    zIndex: 1,
    position: 'absolute',
  },
  labelShimmer: {
    height: 20,
    width: 100,
    position: 'absolute',
    alignSelf: 'center',
    alignItems: 'center',
    top: 0,
    left: -4,
  },
  scrollViewBottomPadding: {
    paddingBottom: 48 + 24,
  },
  variantHeaderContainer: {
    height: '100%',
    backgroundColor: colors.white,
  },
  scrollViewStyle: {
    height: '100%',
  },
  quantityContainer: {
    marginLeft: 22,
  },
  unselectedSizeVariantPillText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.foxyBlack,
  },
  disabledSizeVariantPillText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.subtitle,
  },
  selectedSizeVariantPillText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.white,
  },
  unselectedSizeVariantPillContainer: {
    backgroundColor: colors.salon_payment_status.order_border,
    height: sizeVariantPillHeight,
    paddingHorizontal: 12,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  selectedSizeVariantPillContainer: {
    backgroundColor: colors.foxyBlack,
    height: sizeVariantPillHeight,
    paddingHorizontal: 12,
    borderRadius: 16,
    borderColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderWidth: 0.5,
  },
  selectedSizePillBorder: {
    height: 40,
    minWidth: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.havelockBlue,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  unselectedSizePillBorder: {
    height: 40,
    minWidth: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.border,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  flexWrappedSelectedSizePillBorder: {
    height: 40,
    minWidth: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.havelockBlue,
    marginRight: 8,
    marginBottom: 8,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  flexWrappedUnselectedSizePillBorder: {
    height: 40,
    minWidth: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.border,
    marginRight: 8,
    marginBottom: 8,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  disabledSizePillLine: {
    width: 1,
    transform: [{ rotate: '45deg' }],
    height: 36,
    position: 'absolute',
    zIndex: 1,
    backgroundColor: colors.subtitle,
  },
  sectionListContainer: { marginLeft: -8 },
  secondaryVariantFlatlistStyle: {
    flexWrap: 'wrap',
    marginLeft: 10,
  },
  variantListContainerStyle: {
    flex: 1.5,
    height: '100%',
  },
  quantityMargin: { marginLeft: 10, marginTop: 24 },
  headerSection: {
    fontSize: 14,
    color: colors.foxyBlack,
    alignSelf: 'flex-start',
    marginLeft: 16,
    fontFamily: 'Roboto-Medium',
    marginTop: 12,
  },
});
